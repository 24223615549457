import React, { FormEvent, useState } from 'react';
import { DynamicUpstreamClientFieldSet } from 'common/components/deprecated/form/DynamicUpstreamClientFieldSet';
import styled from 'styled-components';
import { colors } from 'theme/variables';
import { RESPONSE, UpstreamWebsiteClientInstance } from '../models/organisationModels';
import { PaddedSection, SubmitButton } from 'common/components';
import { Button, Form } from 'antd';
import { Notification, showNotification } from 'common/helpers/notifications';
import { updateUpstreamClientSettingsRequest } from '../actions/organisationActions';

interface EditClientUpstreamProps {
    upstreamClientSettings: UpstreamWebsiteClientInstance;
    dispatch: Function;
    status: string;
}

const SelectLabel = styled.h2`
    color: ${colors.primary};
`;

const EvidenceContentWrapper = styled.div`
    width: 100%;
    padding: 1rem;
    border: 1px solid ${colors.antdBorder};
    border-radius: 4px;
    margin-top: 1.5rem;
`;

export const EditClientUpstream: React.FC<EditClientUpstreamProps> = ({
    upstreamClientSettings,
    dispatch,
    status,
}: EditClientUpstreamProps) => {
    const [formState, setFormState] = useState({
        id: upstreamClientSettings.id,
        allowedRedirectUrls: upstreamClientSettings.allowedRedirectUrls,
        allowedWebOrigins: upstreamClientSettings.allowedWebOrigins,
        isValidRedirectUrls: true,
        isValidWebOrigins: true,
    });
    const extractTitles = (formValue: any) => {
        const allowedRedirectTitles: string[] = [];
        const allowedWebOriginsTitles: string[] = [];

        for (const field in formValue) {
            if (Object.hasOwnProperty.call(formValue, field)) {
                const fieldData = formValue[field];
                for (const key in fieldData) {
                    if (Object.hasOwnProperty.call(fieldData, key)) {
                        const element = fieldData[key];
                        if (element.value || element.title) {
                            if (field === 'allowedRedirectUrls') {
                                allowedRedirectTitles.push(element.value || element.title);
                            } else if (field === 'allowedWebOrigins') {
                                allowedWebOriginsTitles.push(element.value || element.title);
                            }
                        }
                    }
                }
            }
        }

        return {
            allowedRedirectUrls: allowedRedirectTitles,
            allowedWebOrigins: allowedWebOriginsTitles,
        };
    };

    const handleFieldSetChange = (updatedFields: any, field: any) => {
        setFormState((prevState) => ({
            ...prevState,
            [field]: updatedFields,
        }));
    };

    const showNotificationIfSuccess = () =>
        showNotification({
            type: Notification.Success,
            text: 'Upstream Client Was Successfully Updated',
        });
    const showNotificationIfError = () =>
        showNotification({
            type: Notification.Warning,
            text: 'Oops, something went wrong. Please try again.',
        });
    const handleSubmit = (e: FormEvent) => {
        try {
            e.preventDefault();
            dispatch(
                updateUpstreamClientSettingsRequest({
                    ...formState,
                    allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                    allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                }),
            );

            if (status === RESPONSE.UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_SUCCEED) {
                showNotificationIfSuccess();
                return;
            }
            showNotificationIfError();
        } catch (error) {
            console.error('Form validation failed:', error);
        }
    };
    const isValidUrl = (url: string) => {
        try {
            if (url === '*') {
                return true;
            }
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };

    const getValidationStatus = (values: any, field: string) =>
        !values[field].every(isValidUrl) ? 'error' : '';
    const getHelpMessage = (values: any, field: string) => {
        if (!values[field].every(isValidUrl)) {
            return 'Enter a valid URL';
        }
        return '';
    };

    return (
        <>
            <PaddedSection>
                <SelectLabel>Edit Upstream Client Settings</SelectLabel>
                <Form.Item
                    validateStatus={getValidationStatus(
                        {
                            ...formState,
                            allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                            allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                        },
                        'allowedRedirectUrls',
                    )}
                    help={getHelpMessage(
                        {
                            ...formState,
                            allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                            allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                        },
                        'allowedRedirectUrls',
                    )}
                >
                    <DynamicUpstreamClientFieldSet
                        label="Allowed Redirect URLs"
                        fieldSetId={'5'}
                        fieldType={'upStreamClientsUrls'}
                        placeholder="Type or paste URL"
                        name={'allowedRedirectUrls'}
                        buttonLabel="allowed url"
                        values={formState.allowedRedirectUrls}
                        onFieldsChanged={(updatedFields) => {
                            handleFieldSetChange(updatedFields, 'allowedRedirectUrls');
                        }}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={getValidationStatus(
                        {
                            ...formState,
                            allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                            allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                        },
                        'allowedWebOrigins',
                    )}
                    help={getHelpMessage(
                        {
                            ...formState,
                            allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                            allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                        },
                        'allowedWebOrigins',
                    )}
                >
                    <DynamicUpstreamClientFieldSet
                        label="Allowed Web Origins"
                        fieldSetId={'7'}
                        name={'allowedWebOrigins'}
                        fieldType={'upStreamClientsUrls'}
                        placeholder="Type or paste URL"
                        buttonLabel="allowed url"
                        values={formState.allowedWebOrigins}
                        onFieldsChanged={(updatedFields) => {
                            handleFieldSetChange(updatedFields, 'allowedWebOrigins');
                        }}
                    />
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="button"
                    disabled={
                        getValidationStatus(
                            {
                                ...formState,
                                allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                                allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                            },
                            'allowedRedirectUrls',
                        ) === 'error' ||
                        getValidationStatus(
                            {
                                ...formState,
                                allowedRedirectUrls: extractTitles(formState).allowedRedirectUrls,
                                allowedWebOrigins: extractTitles(formState).allowedWebOrigins,
                            },
                            'allowedWebOrigins',
                        ) === 'error'
                    }
                    onClick={handleSubmit}
                    style={{ width: '100%' }}
                >
                    Update Upstream Client
                </Button>
            </PaddedSection>
        </>
    );
};
