import { FormEvent, useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    List,
    Modal,
    notification,
    Radio,
    Row,
    Space,
} from 'antd';
import styled from 'styled-components';

import { PaddedSection } from 'common/components';
import { colors, fontSizes } from 'theme/variables';

import {
    PaymentType,
    StatusColor,
    Subscription,
    SubscriptionPurchaseData,
} from '../models/userModel';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionsTiersRequest, PurchaseSubscriptionRequest } from '../actions/userActions';
import { UserState } from '../reducers/userReducer';
import { dateFormat } from 'config/config';
import { isAdmin } from '../selectors/userSelectors';
import { isWithinOneMonthBeforeExpiry } from 'common/helpers/utils';
import { openModal } from 'common/helpers/modal';
import moment from 'moment';

const SubscriptionCard = styled(Card)<{ isCurrent: number; expirationDate: string | undefined }>(
    ({ isCurrent, expirationDate }) => `
    border-color: ${
        isCurrent ? getStatusColor(expirationDate).color : colors.antdBorder
    };    border-width: 2px;
    height:100%;
`,
);

const SubscriptionCardButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const TierButton = styled(Button)`
    display: block;
    float: right;
    margin: 15px 0 5px 0;
`;

type Props = {
    profile: UserState;
    userSubscription?: Subscription;
};
interface DateTimeDifference {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const calculateDateTimeDifference = (date1: Date, date2: Date): DateTimeDifference => {
    const timeDifference = date2.getTime() - date1.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};

const getStatusColor = (expiresAt: string | undefined): StatusColor => {
    const currentDate = new Date();
    const expirationDate = expiresAt ? new Date(expiresAt) : undefined;
    if (!expirationDate) {
        return { status: 'ACTIVE', color: 'green' }; // Default to green if expiration date is undefined
    }

    const currentDateUtc = new Date(currentDate.toISOString()); // Convert current date to UTC
    const expirationDateUtc = new Date(expirationDate.toISOString()); // Convert expiration date to UTC

    const { days, hours, minutes, seconds } = calculateDateTimeDifference(
        currentDateUtc,
        expirationDateUtc,
    );

    if (days < 0 || (days === 0 && hours < 0)) {
        return { status: 'EXPIRED', color: 'red' };
    } else if (days === 0 && hours <= 24 && minutes <= 0 && seconds <= 0) {
        return { status: 'EXPIRED', color: 'red' };
    } else if (days === 0 && hours <= 240) {
        return { status: 'DUE TO BE RENEWED', color: 'orange' };
    } else if (days <= 10) {
        return { status: 'DUE TO BE RENEWED', color: 'orange' };
    } else {
        return { status: 'ACTIVE', color: 'green' };
    }
};

export const SubscriptionCards = ({ profile, userSubscription }: Props) => {
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [selectedPackageTier, setSelectedPackageTier] = useState<number | undefined>();
    const [modalForm] = useForm<SubscriptionPurchaseData>();
    const dispatch = useDispatch();
    const isUserAdmin = useSelector(isAdmin);
    const { subscriptionTiers } = profile;

    useEffect(() => {
        if (isUserAdmin) {
            dispatch(getSubscriptionsTiersRequest());
        }
    }, [dispatch, isUserAdmin]);

    const handleButtonClick = (tier: number) => () => {
        if (!userSubscription) {
            setSelectedPackageTier(tier);
            setIsPaymentModalOpen(true);
        } else {
            showNotification(tier);
        }
    };

    const handleSubscribe = async () => {
        try {
            await modalForm.validateFields();
            dispatch(
                PurchaseSubscriptionRequest(
                    parseSubscriptionPurchaseData(modalForm.getFieldsValue()),
                ),
            );
            handleModalClose();
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    };

    const parseSubscriptionPurchaseData = (data?: SubscriptionPurchaseData) => ({
        ...data,
        starting_on: data?.starting_on.format(dateFormat),
        item: selectedPackageTier ? selectedPackageTier : 1,
        type: data?.type.toUpperCase() as string,
    });

    const handleModalClose = () => {
        setSelectedPackageTier(undefined);
        setIsPaymentModalOpen(false);
    };

    const showNotification = (tier: number) => {
        const description =
            tier > userSubscription!.tierIndex
                ? 'In order to upgrade your Cenefits subscription, cancel the current subscription first.'
                : 'In order to downgrade your Cenefits subscription, cancel the current subscription first.';

        const args = {
            message: 'Notification',
            description: `${description}`,
            duration: 5,
        };
        notification.info(args);
    };
    const openCancelSubscriptionModal = (e: FormEvent) => {
        e.preventDefault();
        const { id } = userSubscription;
        return openModal({
            okText: 'Cancel Subscription',
            cancelText: 'Cancel',
            callback: () => {
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_REQUEST',
                    payload: id,
                });
            },
            title: 'Warning: This action will cancel subscription, do you want to proceed?',
        });
    };
    const renewSubscription = () => {
        const { tierIndex, type, paymentPeriods, expiresAt } = userSubscription!;
        dispatch(
            PurchaseSubscriptionRequest({
                item: tierIndex,
                payment_periods: paymentPeriods ?? null,
                starting_on: moment(expiresAt).format('YYYY-MM-DD'),
                type: type.toUpperCase() as string,
            }),
        );
    };

    const renderTierButton = (tier: number) => {
        if (!userSubscription) {
            return <TierButton onClick={handleButtonClick(tier)}>Subscribe</TierButton>;
        }

        if (tier < userSubscription.tierIndex + 1) {
            return <TierButton onClick={handleButtonClick(tier)}>Downgrade</TierButton>;
        } else if (tier > userSubscription.tierIndex + 1) {
            return <TierButton onClick={handleButtonClick(tier)}>Upgrade</TierButton>;
        }
        return (
            <>
                <SubscriptionCardButtonWrapper>
                    <TierButton
                        danger
                        onClick={(e: FormEvent) => {
                            openCancelSubscriptionModal(e);
                        }}
                    >
                        Cancel
                    </TierButton>
                    {isWithinOneMonthBeforeExpiry(userSubscription.expiresAt) && (
                        <TierButton onClick={renewSubscription}>Renew</TierButton>
                    )}
                </SubscriptionCardButtonWrapper>
            </>
        );
    };

    return (
        <>
            <PaddedSection header="Buy/Upgrade Subscription">
                <Row gutter={16}>
                    {subscriptionTiers
                        .sort((a, b) => a.price - b.price)
                        .map(
                            ({
                                name,
                                title,
                                maxAvailableNumberOfUsers,
                                price,
                                description,
                                pk,
                            }) => (
                                <Col key={title} span={6}>
                                    <SubscriptionCard
                                        size="small"
                                        title={name}
                                        isCurrent={userSubscription?.tierIndex + 1 === pk ? 1 : 0}
                                        expirationDate={userSubscription?.expiresAt}
                                    >
                                        <List>
                                            <List.Item>
                                                Max users: {maxAvailableNumberOfUsers}
                                                <List.Item>Description: {description}</List.Item>
                                                <List.Item>Price: {price}</List.Item>
                                            </List.Item>
                                        </List>
                                        {renderTierButton(pk)}
                                    </SubscriptionCard>
                                </Col>
                            ),
                        )}
                </Row>
            </PaddedSection>
            <Modal
                visible={isPaymentModalOpen}
                onCancel={handleModalClose}
                onOk={handleSubscribe}
                okText={userSubscription ? 'Upgrade' : 'Subscribe'}
            >
                Select type of payment:
                <Form form={modalForm}>
                    <Form.Item name="type" initialValue={PaymentType.Monthly}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={PaymentType.Monthly}>{PaymentType.Monthly}</Radio>
                                <Radio value={PaymentType.Annual}>{PaymentType.Annual}</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    Enter number of payment periods
                    <Form.Item
                        name="payment_periods"
                        rules={[
                            { required: true, message: 'Number of payment periods is required' },
                        ]}
                    >
                        <Input type="number"></Input>
                    </Form.Item>
                    Select Subscription Start Date
                    <Form.Item
                        name="starting_on"
                        rules={[{ required: true, message: 'Subscription start date is required' }]}
                    >
                        <DatePicker></DatePicker>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
