import { Antd3Form, Antd3Icon, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useSelector } from 'react-redux';

import { generateColumns } from 'common/helpers/utils';
import { getOrganisationSubscriptions } from '../selectors/userSelectors';
import { PaddedSection, ResetButton, SubmitButton } from 'common/components';
import { Subscription } from '../models/userModel';
import { UserState } from '../reducers/userReducer';
import { InvoiceList } from './InvoiceList';
import { RedirectButton } from './shared/Button';

type Props = {
    isUserAdmin: boolean;
    profile?: UserState;
};

export const SubscriptionsHistory = ({ isUserAdmin, profile }: Props) => {
    const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);
    const [userSubscription, setUserSubscription] = useState<Subscription>();
    const subscriptions = useSelector(getOrganisationSubscriptions);
    const subscriptionsColumns = [
        {
            title: 'Id',
            key: 'id',
        },
        {
            title: 'Type',
            key: 'itemTitle',
        },
        {
            title: 'Payment Type',
            key: 'type',
        },
        {
            title: 'Number of Payment Periods',
            key: 'paymentPeriods',
        },
        {
            title: 'Created At',
            key: 'createdAt',
        },
        {
            title: 'Expired At',
            key: 'expiresAt',
        },
        {
            title: 'Invoices',
            key: 'id',
            render: (_: string, userSubscription: Subscription) => (
                <Button
                    size="small"
                    onClick={() => {
                        setIsInvoiceVisible(true);
                        setUserSubscription(userSubscription);
                    }}
                    icon={<Antd3Icon type="folder-open" />} // Remplacez "download" par "folder-open"
                >
                    Open
                </Button>
            ),
        },
    ];
    useEffect(() => () => setIsInvoiceVisible(false), []);

    return isInvoiceVisible ? (
        <InvoiceList
            isUserAdmin={isUserAdmin}
            userSubscription={userSubscription}
            element={
                <RedirectButton
                    onReset={() => setIsInvoiceVisible(false)}
                    value="Back to Subscriptions"
                />
            }
        />
    ) : (
        <PaddedSection>
            <StyledAntd3Form header="Subscription History" bigHeader>
                <Antd3Form.Item>
                    <Table
                        columns={generateColumns(subscriptionsColumns)}
                        dataSource={subscriptions}
                    />
                </Antd3Form.Item>
            </StyledAntd3Form>
        </PaddedSection>
    );
};
