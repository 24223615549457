import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FullPageSpinner } from 'common/components';
import { getBenefitCategoriesRequest } from 'features/benefit/actions';
import { getFetchingBenefitsStatus } from 'features/benefit/selectors/benefitSelectors';

import { CreateBenefitTemplateForm } from '../components/CreateBenefitTemplateForm/CreateBenefitTemplateForm';

export const CreateBenefitTemplateContainer = () => {
    const isFetching = useSelector(getFetchingBenefitsStatus);

    return isFetching ? <FullPageSpinner /> : <CreateBenefitTemplateForm />;
};
