import * as React from 'react';
import { connect } from 'react-redux';

import {
    RouteParams,
    getBenefitId,
    getRouteId,
    routeParams,
} from 'common/selectors/routeSelectors';
import { AppState } from 'common/appState';
import { Profile } from 'features/user/models/userModel';
import { AutocompleteField, AutocompleteData } from 'features/pagination/models/paginationModel';

import {
    BenefitRole,
    BenefitOrganisationRole,
    Benefit,
    BenefitTemplate,
    EditBenefitPriorityRequest,
    AddBenefitPriorityRequest,
    InvitePendingIndividualExecutivesRequest,
    BenefitPriority,
} from 'features/benefit/models/benefitModels';
import {
    editBenefitRequest,
    getBenefitsRolesRequest,
    getContractBenefitDataRequest,
    addBenefitRoleRequest,
    deleteBenefitRoleRequest,
    getBenefitPrioritiesRequest,
    editBenefitPriorityRequest,
    addBenefitPriorityRequest,
    deleteBenefitPriorityRequest,
} from 'features/benefit/actions';
import {
    getFetchingBenefitsStatus,
    getBenefitAssessors,
    getBenefitSuppliers,
    getBenefitPartners,
    getBenefitByRouteId,
    getFetchingRolesStatus,
    getBenefitIndividualSuppliers,
    getBenefitIndividualPartners,
    getSelectedBenefitTemplateId,
    getBenefitTemplates,
} from 'features/benefit/selectors/benefitSelectors';
import {
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest,
    clearSelectedBenefitTemplate,
    getBenefitTemplateRequest,
} from 'features/benefit/actions/benefitTemplateActions';
import {
    getContractRolesRequest,
    getContractRequest,
} from 'features/contract/actions/contractActions';
import { Organisation } from 'features/organisation/models/organisationModels';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import {
    getEmployees,
    getUserOrganisationId,
    isAdmin,
} from 'features/user/selectors/userSelectors';
import { getExecutives } from 'features/organisation/selectors/organisationSelectors';
import { autocompleteRequest } from 'features/pagination/actions/paginationActions';
import { getAutocompleteResults } from 'features/pagination/selectors/paginationSelectors';
import {
    deletePendingRoleInviteRequest,
    getRolesPendingInvitationsRequest,
    editBenefitInviteEmployeeWithRoleRequest,
    assignInvitedIndividualExecutiveRequest,
} from 'features/invite/actions/inviteActions';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { redirectToUrl } from 'common/actions/navigationAction';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { getRolesPendingInvitationsWithBenefit } from 'features/invite/selectors/inviteSelectors';
import { isContractManager } from 'features/contract/selectors/contractSelectors';

import { EditBenefitForm } from '../components/EditBenefit';

export interface EditBenefitContainerStateProps {
    contractId: number;
    contract: number;
    benefitId: number;
    benefit: Benefit;
    label: string;
    isFetching: boolean;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
    benefitIndividualSuppliers: BenefitOrganisationRole[];
    benefitIndividualPartners: BenefitOrganisationRole[];
    rolesPendingInvitations: RoleInvitation[];
    employees: Profile[];
    executives: Organisation[];
    organisationId: number;
    isAdmin: boolean;
    benefitCategories: AutocompleteData[];
    priorities: BenefitPriority[];
    isFetchingPriorities: boolean;
    selectedBenefitTemplate: BenefitTemplate[];
    benefitTemplates: BenefitTemplate[];
    isContractManager: boolean;
}

export interface EditBenefitContainerDispatchProps {
    editBenefitRequest: typeof editBenefitRequest;
    getBenefitsRolesRequest: typeof getBenefitsRolesRequest;
    addBenefitRoleRequest: typeof addBenefitRoleRequest;
    deleteBenefitRoleRequest: typeof deleteBenefitRoleRequest;
    getContractBenefitDataRequest: typeof getContractBenefitDataRequest;
    getEmployeesRequest: typeof getEmployeesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
    getContractRolesRequest: typeof getContractRolesRequest;
    autocompleteRequest: typeof autocompleteRequest;
    editBenefitInviteEmployeeWithRoleRequest: typeof editBenefitInviteEmployeeWithRoleRequest;
    getBenefitPrioritiesRequest: typeof getBenefitPrioritiesRequest;
    editBenefitPriorityRequest: typeof editBenefitPriorityRequest;
    addBenefitPriorityRequest: typeof addBenefitPriorityRequest;
    deleteBenefitPriorityRequest: typeof deleteBenefitPriorityRequest;
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest: typeof getBenefitTemplateWhenCreatingTemplatedBenefitRequest;
    clearSelectedBenefitTemplate: typeof clearSelectedBenefitTemplate;
    redirectToUrl: typeof redirectToUrl;
    getRolesPendingInvitationsRequest: typeof getRolesPendingInvitationsRequest;
    deletePendingRoleInviteRequest: typeof deletePendingRoleInviteRequest;
    assignInvitedIndividualExecutiveRequest: typeof assignInvitedIndividualExecutiveRequest;
    getContractRequest: typeof getContractRequest;
    getBenefitTemplateRequest: typeof getBenefitTemplateRequest;
}

export type EditBenefitContainerProps = EditBenefitContainerStateProps &
    EditBenefitContainerDispatchProps &
    RouteParams;

class EditBenefit extends React.Component<EditBenefitContainerProps> {
    public componentDidMount(): void {
        this.props.getContractBenefitDataRequest({
            benefitId: this.props.benefitId,
            contractId: this.props.contract,
        });

        this.props.getEmployeesRequest();
        this.props.getRolesPendingInvitationsRequest(this.props.contract);
        this.props.getExecutivesRequest();
        this.props.getBenefitsRolesRequest({
            benefitIds: [this.props.benefitId],
            contractId: this.props.contract,
        });

        this.props.getContractRolesRequest(this.props.contract);
        this.props.getContractRequest({ contractId: this.props.contractId });
        // this.props.benefit &&
        //     this.props.getBenefitTemplateRequest(this.props.benefit.sharedBenefit);
    }

    private updatePriorities = () => {
        this.props.getBenefitPrioritiesRequest({
            contractId: this.props.contract,
            benefitId: this.props.benefitId,
        });
    };

    private addRole = (benefitRole: BenefitRole, organisationId: number, userId?: number) =>
        this.addRoleRequest(benefitRole, organisationId, userId);

    private addPriority = (data: AddBenefitPriorityRequest) =>
        this.props.addBenefitPriorityRequest(data);

    private editPriority = (data: EditBenefitPriorityRequest) =>
        this.props.editBenefitPriorityRequest(data);

    private deletePriority = (data: EditBenefitPriorityRequest) =>
        this.props.deleteBenefitPriorityRequest(data);

    private addRoleRequest = (role: BenefitRole, organisationId: number, userId?: number) => {
        this.props.addBenefitRoleRequest({
            role,
            organisationId,
            userId,
            contractId: this.props.contract,
            benefitId: this.props.benefitId,
        });
    };

    private inviteNewEmployee = (employeeData: InviteByEmailWithRole) => {
        this.props.editBenefitInviteEmployeeWithRoleRequest({
            ...employeeData,
            benefit: this.props.benefitId,
            contract: this.props.contract,
        });
    };

    private deleteRole = (roleId: number, organisationId?: number): void => {
        this.props.deleteBenefitRoleRequest({
            contractId: this.props.contract,
            benefitId: this.props.benefitId,
            roleId,
            organisationId,
        });
    };

    private deletePendingRole = (roleId: string, contractId: number, benefitId: number): void => {
        this.props.deletePendingRoleInviteRequest({
            invitationId: roleId,
            contractId,
            benefitId,
        });
    };

    private assignInvitedIndividualExecutive = (
        data: InvitePendingIndividualExecutivesRequest,
    ): void => {
        this.props.assignInvitedIndividualExecutiveRequest(data);
    };

    public componentWillUnmount(): void {
        this.props.clearSelectedBenefitTemplate();
    }

    public render(): JSX.Element {
        return (
            <EditBenefitForm
                inviteNewEmployee={this.inviteNewEmployee}
                addRole={this.addRole}
                deleteRole={this.deleteRole}
                deletePendingRole={this.deletePendingRole}
                editPriority={this.editPriority}
                addPriority={this.addPriority}
                deletePriority={this.deletePriority}
                updatePriorities={this.updatePriorities}
                assignInvitedIndividualExecutive={this.assignInvitedIndividualExecutive}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): EditBenefitContainerStateProps => ({
    contractId: getRouteId(ownProps),
    benefitId: getBenefitId(ownProps),
    benefit: getBenefitByRouteId(state, routeParams(ownProps))!,
    contract: getRouteId(ownProps),
    isFetching: getFetchingBenefitsStatus(state) || getFetchingRolesStatus(state),
    benefitAssessors: getBenefitAssessors(state),
    benefitSuppliers: getBenefitSuppliers(state),
    benefitPartners: getBenefitPartners(state),
    benefitIndividualSuppliers: getBenefitIndividualSuppliers(state),
    benefitIndividualPartners: getBenefitIndividualPartners(state),
    rolesPendingInvitations: getRolesPendingInvitationsWithBenefit(state),
    employees: getEmployees(state),
    organisationId: getUserOrganisationId(state),
    executives: getExecutives(state),
    isAdmin: isAdmin(state),
    benefitCategories: getAutocompleteResults(state, AutocompleteField.BenefitTemplate),
    isFetchingPriorities: state.benefit.isFetchingPriorities,
    selectedBenefitTemplate: getSelectedBenefitTemplateId(state),
    benefitTemplates: getBenefitTemplates(state),
    isContractManager: isContractManager(state),
});

export const EditBenefitContainer = connect(mapStateToProps, {
    editBenefitRequest,
    getBenefitsRolesRequest,
    getContractBenefitDataRequest,
    addBenefitRoleRequest,
    deleteBenefitRoleRequest,
    getEmployeesRequest,
    getExecutivesRequest,
    getContractRolesRequest,
    autocompleteRequest,
    editBenefitInviteEmployeeWithRoleRequest,
    getBenefitPrioritiesRequest,
    editBenefitPriorityRequest,
    addBenefitPriorityRequest,
    deleteBenefitPriorityRequest,
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest,
    clearSelectedBenefitTemplate,
    redirectToUrl,
    getRolesPendingInvitationsRequest,
    deletePendingRoleInviteRequest,
    assignInvitedIndividualExecutiveRequest,
    getContractRequest,
    getBenefitTemplateRequest,
})(EditBenefit);
