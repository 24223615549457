import { Antd3InputProps } from '../antd3';
import { Input } from 'common/components/deprecated/form';
import { isPhoneNumberValid } from 'common/helpers/phoneValidator';

const checkPhone = (
    _: any,
    value: string | undefined | null,
    callback: (message?: string) => void,
) => {
    if (value && isPhoneNumberValid(value)) {
        callback();
    } else {
        callback(
            'Invalid phone number format. Please use a valid international or local phone number format',
        );
    }
};

interface Props extends Antd3InputProps {
    initialValue?: string;
}

export const PhoneInput = ({ form, id, placeholder, label, initialValue, required }: Props) => (
    <Input
        form={form}
        id={id}
        label={label}
        size="large"
        placeholder={placeholder}
        initialValue={initialValue}
        required={required}
        phoneValidator={checkPhone}
    />
);
