import * as React from 'react';
import { compact } from 'lodash';
import { connect } from 'react-redux';

import { FullPageSpinner } from 'common/components';
import { extractRouteBenefitId, extractRouteContractId } from 'common/selectors/utilitySelectors';
import {
    Benefit,
    DeliveryDate,
    BenefitOrganisationRole,
    FetchedBenefitPriority,
} from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import {
    getContractRequest,
    getContractRolesRequest,
} from 'features/contract/actions/contractActions';
import {
    getFetchingFiltersStatus,
    getContractByBenefitRouteId,
    isContractManager,
} from 'features/contract/selectors/contractSelectors';
import {
    getFetchingBenefitsStatus,
    getBenefitByRouteId,
    getBenefitAssessors,
    getBenefitIndividualSuppliers,
    getBenefitIndividualPartners,
    getBenefitPriorities,
} from 'features/benefit/selectors/benefitSelectors';
import {
    getContractBenefitDataRequest,
    getBenefitsRolesRequest,
    getBenefitPrioritiesRequest,
} from 'features/benefit/actions/benefitActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { AppState } from 'common/appState';
import { RouteParams, routeParams } from 'common/selectors/routeSelectors';
import { getEvidences, getChangingApprovalStatus } from 'features/upload/selectors/uploadSelectors';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { Profile } from 'features/user/models/userModel';
import { getEmployees, isAdmin } from 'features/user/selectors/userSelectors';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import { Evidence } from 'features/upload/models/uploadModels';
import {
    approveEvidenceRequest,
    declineEvidenceRequest,
    getEvidencesListRequest,
} from 'features/upload/actions/evidenceActions';

import { BenefitEvidence as BenefitEvidenceComponent } from '../components/BenefitEvidence';

interface BenefitEvidenceState {
    deliveryDates: DeliveryDate[];
}

export interface BenefitEvidenceStateProps {
    benefitId: number;
    contractId: number;
    benefit: Benefit | undefined;
    priorities: FetchedBenefitPriority[];
    contract: Contract | undefined;
    employees: Profile[];
    evidences: Evidence[];
    isFetchingBenefits: boolean;
    isFetchingContracts: boolean;
    isChangingApprovalStatus: boolean;
    isContractManager: boolean;
    isAdmin: boolean;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
}

interface BenefitEvidenceContainerDispatchProps {
    approveEvidenceRequest: typeof approveEvidenceRequest;
    declineEvidenceRequest: typeof declineEvidenceRequest;
    getContractBenefitDataRequest: typeof getContractBenefitDataRequest;
    getBenefitPrioritiesRequest: typeof getBenefitPrioritiesRequest;
    getContractRequest: typeof getContractRequest;
    getEmployeesRequest: typeof getEmployeesRequest;
    getEvidencesListRequest: typeof getEvidencesListRequest;
    getBenefitsRolesRequest: typeof getBenefitsRolesRequest;
    redirectToUrl: typeof redirectToUrl;
    getContractRolesRequest: typeof getContractRolesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
}

export type BenefitEvidenceProps = BenefitEvidenceStateProps &
    BenefitEvidenceContainerDispatchProps &
    RouteParams;

class BenefitEvidence extends React.Component<BenefitEvidenceProps, BenefitEvidenceState> {
    public state: BenefitEvidenceState = {
        deliveryDates: [],
    };

    public componentDidMount(): void {
        const { benefitId, contractId } = this.props;

        this.props.getContractBenefitDataRequest({ benefitId, contractId });
        this.props.getContractRequest({ contractId });
    }

    public static getDerivedStateFromProps(
        props: BenefitEvidenceProps,
        { deliveryDates }: BenefitEvidenceState,
    ): BenefitEvidenceState | null {
        if (
            !props.benefit ||
            !props.contract ||
            (props.benefit.deliveryDates &&
                deliveryDates &&
                props.benefit.deliveryDates?.length === deliveryDates?.length)
        ) {
            return null;
        }

        const organisationId = props.benefit.organisation;

        const request = props.benefit.deliveryDates.map(({ benefit, id }) => ({
            benefitId: benefit,
            contractId: props.contract!.id,
            deliveryDateId: id,
            organisationId,
        }));

        props.getEvidencesListRequest(compact(request));

        return { deliveryDates: props.benefit.deliveryDates };
    }

    public render(): JSX.Element {
        return this.props.isFetchingContracts || this.props.isFetchingBenefits ? (
            <FullPageSpinner />
        ) : (
            <BenefitEvidenceComponent {...this.props} />
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): BenefitEvidenceStateProps => ({
    benefitId: extractRouteBenefitId(state, routeParams(ownProps)),
    contractId: extractRouteContractId(state, routeParams(ownProps)),
    benefit: getBenefitByRouteId(state, routeParams(ownProps)),
    priorities: getBenefitPriorities(state),
    contract: getContractByBenefitRouteId(state, routeParams(ownProps)),
    employees: getEmployees(state),
    evidences: getEvidences(state),
    isFetchingBenefits: getFetchingBenefitsStatus(state),
    isFetchingContracts: getFetchingFiltersStatus(state),
    isChangingApprovalStatus: getChangingApprovalStatus(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
    benefitAssessors: getBenefitAssessors(state),
    benefitSuppliers: getBenefitIndividualSuppliers(state),
    benefitPartners: getBenefitIndividualPartners(state),
});

export const BenefitEvidenceContainer = connect(mapStateToProps, {
    approveEvidenceRequest,
    declineEvidenceRequest,
    getContractBenefitDataRequest,
    getBenefitPrioritiesRequest,
    getContractRequest,
    getEmployeesRequest,
    getEvidencesListRequest,
    getBenefitsRolesRequest,
    redirectToUrl,
    getContractRolesRequest,
    getExecutivesRequest,
})(BenefitEvidence);
