import { SmallTabs } from 'common/components/styled';
import { Props } from '../containers/SubscriptionContainer';
import { PaymentType, Subscription, SubscriptionItemTitle, UserPaths } from '../models/userModel';
import { InvoiceForm } from './InvoiceForm';
import { InvoiceList } from './InvoiceList';
import { SubscriptionCards } from './SubscriptionCards';
import { SubscriptionUserInfo } from './SubscriptionUserInfo';
import { Tabs } from 'antd';
import { SubscriptionsHistory } from './SubscriptionsHistory';
import { UpstreamDetailsContainer } from 'features/organisation/containers/UpstreamDetailsContainer';

const TabPanetitles = {
    SubscriptionDetails: 'Subscription Details',
    subscriptionHistory: 'Subscription History',
    upstreamClient: 'Upstream Client',
};

export const SubscriptionDetails = ({
    userSubscription,
    profile,
    isUserAdmin,
    isUserTenant,
}: Props) => (
    <>
        <SmallTabs>
            <Tabs.TabPane
                key={TabPanetitles.SubscriptionDetails}
                tab={TabPanetitles.SubscriptionDetails}
            >
                <SubscriptionCards profile={profile} userSubscription={userSubscription} />
                {userSubscription && isUserAdmin && (
                    <>
                        <SubscriptionUserInfo
                            profile={profile}
                            userSubscription={userSubscription}
                            isUserAdmin={isUserAdmin}
                        />

                        <InvoiceList
                            userSubscription={userSubscription}
                            profile={profile}
                            isUserAdmin={isUserAdmin}
                        />
                    </>
                )}
                <InvoiceForm />
            </Tabs.TabPane>
            <Tabs.TabPane
                key={TabPanetitles.subscriptionHistory}
                tab={TabPanetitles.subscriptionHistory}
            >
                <SubscriptionsHistory isUserAdmin={isUserAdmin} userSubscription={profile} />
            </Tabs.TabPane>
            {isUserAdmin && isUserTenant && (
                <Tabs.TabPane key={TabPanetitles.upstreamClient} tab={TabPanetitles.upstreamClient}>
                    <UpstreamDetailsContainer />
                </Tabs.TabPane>
            )}
        </SmallTabs>
    </>
);
