export const contractTooltips = {
    ProjectAwardDate: `The date on which the Project is awarded.`,
    ContractTitle: `The title or purpose of the Project.`,
    ContractReferenceNumber: `A unique alpha-numeric reference number to help you find the Project.`,
    ContractDescription: `A more detailed description of what is expected and how it is to be delivered.`,
    ContractType: `A filter which allows you to specify the type of Project.`,
    ContractCategory: `A filter which you can define.`,
    Portfolio: `A nested filter which you can define. It allows you to represent the structure your organisation uses to organise its projects in relation to one another.`,
    Department: `A filter which you can define. Some customers use this for their own internal departments or service areas, others also include their "ALEOs".`,
    ContractLocation: `A filter which you can define. We suggest you use the first part of the post codes in your area.`,
    BudgetSource: `A filter which allows you to select the type of funding used to pay for the Project.`,
    RouteToMarket: `A filter which allows you to select the route to market used when defining the Project.`,
    FrameworkContract: `A filter which allows you to relate the current project to another Project that has already been created. This is typically used when you have a Framework
                    Project and Initiatives or Call Offs that sit underneath it.`,
    ContractValue: `The total monetary value associated with the Project.`,
    TargetBenefitValue: `The target monetary value of Benefits associated with the Project.`,
    TargetBenefitPoints: `The target numer of benefit points associated with the Project`,
    TargetBenefitEmissionSavings: `The target emission savings of benefits associated with the Project`,
    TargetBenefitDeliveryNumber: `The target number of delivered benefits associated with the Project`,
    ContractPriorities: `A filter which you can define. You can use them to record values like the number of "SMEs", "Living Wage Accredited" or "Fair Trade Accredited" 
                    suppliers assigned to your Projects. More than one of these can be applied to each Projects, and each of them can also have a quantity.`,
    StartDate: `The date that the Project is due to start.`,
    EndDate: `The date that the Project is due to end.`,
    ContractManagers: `The people in your organisation who you want to manage individual projects and their benefits, including being responsible for approving and 
                    rejecting the Evidence and Feedback submitted by the Contributors, Suppliers and Delivery Partners. Most customers prefer the people who have overall strategic 
                    responsibility for the project to fulfil this role.`,
    Suppliers: `The organisations you want to associate with this Project as Suppliers. This is just for information.`,
    ContractStatus: `A filter which allows you to select the current status of the Project. The Contributors should keep this up to date as the Project progresses to make sure 
                    that reports remain accurate.`,
    Published: `A flag which determines who can see the Projects and its Benefits. If you select Unpublished, it will remain private to Admins and Project Managers. If you select 
                    Published, Contributors, Suppliers and Delivery Partners will be able to see the Project and the Benefits they are associated with. They will also receive an 
                    email encouraging them to check the evidence they need to submit.`,
};
