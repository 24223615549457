import React, { FunctionComponent, FormEvent, useState, useEffect } from 'react';
import { Collapse, Form } from 'antd';
import { CollapseWrapper } from 'common/components/styled';
import { SubmitButton, Input } from 'common/components';
import * as S from './shared/styles';
import { UpstreamWebsiteClientInstance } from 'features/organisation/models/organisationModels';
import { openModal } from 'common/helpers/modal';
import { regenerateUpstreamclientsecretcodeRequest } from 'features/organisation/actions/organisationActions';
import { useDispatch } from 'react-redux';

type Props = {
    upstreamClientSettings: UpstreamWebsiteClientInstance;
};

export const UpstreamClientSecretCode: FunctionComponent<Props> = ({ upstreamClientSettings }) => {
    const secretCode = upstreamClientSettings?.secret || '';
    const dispatch = useDispatch();
    const openBenefitStatusModal = (e: FormEvent) => {
        e.preventDefault();
        return openModal({
            okText: 'Reset Client Secret Key',
            cancelText: 'Cancel',
            callback: () => {
                dispatch(
                    regenerateUpstreamclientsecretcodeRequest({
                        id: upstreamClientSettings.id,
                        organizationId: upstreamClientSettings.organizationId,
                    }),
                );
            },
            title: 'Warning: This action will reset client secret key, do you want to proceed?',
        });
    };
    return (
        <CollapseWrapper>
            <Collapse>
                <Collapse.Panel header="Client secret" key="0">
                    <S.SuggestionWrapper>
                        <Form.Item>
                            <Input
                                name="secretCode"
                                value={secretCode === '' ? 'No secret exists' : secretCode}
                                label="Upstream Client Secret"
                                placeholder={secretCode === '' ? 'No secret exists' : secretCode}
                                type="text"
                                readOnly={true}
                                noBottomMargin={false}
                            />
                        </Form.Item>
                        <SubmitButton
                            value={'Regenerate Client Secret'}
                            onClick={(e: FormEvent) => {
                                openBenefitStatusModal(e);
                            }}
                        />
                    </S.SuggestionWrapper>
                </Collapse.Panel>
            </Collapse>
        </CollapseWrapper>
    );
};
