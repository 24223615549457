import { Action } from 'common/actions/appAction';

export const REDIRECT_TO_URL = 'REDIRECT_TO_URL';
export type RedirectToUrlAction = Action<typeof REDIRECT_TO_URL, string>;
export const redirectToUrl = (path: string): RedirectToUrlAction => ({
    type: REDIRECT_TO_URL,
    payload: path,
});
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';
export type SetPreviousPathAction = Action<typeof SET_PREVIOUS_PATH, string>;

export const setPreviousLink = (path: string): SetPreviousPathAction => ({
    type: SET_PREVIOUS_PATH,
    payload: path,
});
