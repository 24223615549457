import * as React from 'react';
import { Link } from 'react-router-dom';
import { startCase, camelCase } from 'lodash';

import {
    generateColumns,
    addReferenceSuffixToBenfitOutcome,
    getStatusLabel,
} from 'common/helpers/utils';
import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { FilterData } from 'common/helpers/url';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { UploadScoreProps as UploadScoreContainerProps } from '../containers/UploadScoreContainer';
import { UploadBenefit } from '../models/uploadModels';
import { getScorableBenefitsSuccess } from '../actions/scoreActions';
import { generateUploadBenefitScorePath } from '../config/uploadRouting';

export const uploadScoreTableColumns = [
    {
        title: 'Benefit',
        key: 'outcome',
        render: (outcome: string, { organisation, contract, id }: UploadBenefit) => (
            <Link to={generateUploadBenefitScorePath(organisation, contract, id)}>{outcome}</Link>
        ),
    },
    {
        title: 'Benefit Label',
        key: 'label',
        render: (label: string, { organisation, contract, id }: UploadBenefit) => (
            <Link to={generateUploadBenefitScorePath(organisation, contract, id)}>{label}</Link>
        ),
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: (quantity: string) => quantity,
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'finalDeliveryDate',
        render: (finalDeliveryDate: string) => finalDeliveryDate,
    },
    {
        title: 'Project',
        key: 'contractTitle',
        render: (contractTitle: string, { contractReferenceNumber }: UploadBenefit) => (
            <span>{`${contractTitle} | ${contractReferenceNumber}`}</span>
        ),
    },
];

type UploadScoreProps = Antd3FormProps & UploadScoreContainerProps;

class UploadScoreForm extends React.Component<UploadScoreProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.UploadScore,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getScorableBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadScore,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadScore,
            values: {
                contract: contractId,
            },
        });

    private saveBuyerOrganisationFilter = (buyer: string | undefined) => {
        this.props.saveFilters({
            paginatedView: PaginationView.UploadEvidence,
            values: { tenant: buyer },
        });
    };

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByBuyerOrganisation = (buyer: string) => {
        this.saveBuyerOrganisationFilter(buyer);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveBuyerOrganisationAutocomplete = () => {
        this.saveBuyerOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.UploadScore) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { form, filter, scorableBenefits, isTenant } = this.props;

        return (
            <PaddedSection header="Submit Feedback">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByBuyerOrganisation={this.searchByBuyerOrganisation}
                    onRemoveBuyerOrganisationAutocomplete={
                        this.onRemoveBuyerOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.ScorableBenefits}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    view={PaginationView.UploadScore}
                    paginationSuccessCallback={getScorableBenefitsSuccess}
                    data={addReferenceSuffixToBenfitOutcome(scorableBenefits)}
                    columns={generateColumns(uploadScoreTableColumns)}
                    emptyText="No benefits have been assigned to you"
                />
            </PaddedSection>
        );
    }
}

export const UploadScore = Antd3Form.create({})(UploadScoreForm);
