import * as React from 'react';

import { SubmitButton } from 'common/components';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { Props } from '../containers/ChangeEmailContainer';
import { Input } from 'common/components/deprecated/form';

type ChangeUserEmailProps = Props & Antd3FormProps;

class ChangeUserEmailForm extends React.Component<ChangeUserEmailProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }
            const { email, password } = values;
            if (!email || !password) {
                return;
            }
            this.props.updateUserEmailRequest({
                id: this.props.profile.user.id,
                email: email.toLowerCase(),
                password,
            });
        });
    };

    public render(): JSX.Element {
        const { form, profile } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} autoComplete="off" header="Change Email">
                <Input
                    form={form}
                    id="email"
                    label="New Email Address"
                    initialValue={profile.user.email}
                    type="email"
                />
                <Input
                    form={form}
                    id="password"
                    placeholder="Password"
                    isPassword
                    autoComplete="new-password"
                />
                <SubmitButton value="Change Email" />
            </StyledAntd3Form>
        );
    }
}

export const ChangeUserEmail = Antd3Form.create({})(ChangeUserEmailForm);
