import { InvoiceData } from 'features/user/models/userModel';
import * as actions from '../actions/organisationActions';
import {
    Organisation,
    RESPONSE,
    UpstreamWebsiteClientInstance,
} from '../models/organisationModels';

export interface OrganisationState {
    executives: Organisation[];
    potentialExecutives: Organisation[];
    organisations: Organisation[];
    invitedOrganisationExistsStatus?: string;
    isFetching: boolean;
    invoiceData?: InvoiceData;
    upstreamClient: UpstreamWebsiteClientInstance;
    upstreamClientExistStatus: string;
}

const defaultOrganisationState = {
    executives: [],
    potentialExecutives: [],
    organisations: [],
    isFetching: false,
    invitedOrganisationExistsStatus: '',
    upstreamClient: {
        id: 0,
        clientId: '',
        type: '',
        rootUrl: '',
        allowedRedirectUrls: [''],
        allowedWebOrigins: [''],
        clientSecret: {
            secret: '',
        },
    },
    upstreamClientExistStatus: '',
};

export const organisationReducer = (
    state: OrganisationState = defaultOrganisationState,
    action: actions.OrganisationAction,
) => {
    switch (action.type) {
        case actions.GET_EXECUTIVES_REQUESTED:
        case actions.GET_ALL_EXECUTIVES_REQUESTED:
        case actions.GET_POTENTIAL_EXECUTIVES_REQUESTED:
        case actions.GET_ORGANISATION_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.GET_EXECUTIVES_SUCCEED:
        case actions.GET_ALL_EXECUTIVES_SUCCEED:
        case actions.GET_POTENTIAL_EXECUTIVES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case actions.GET_ORGANISATION_SUCCEED:
            return {
                ...state,
                organisations: [
                    action.payload,
                    ...state.organisations.filter(({ id }) => id !== action.payload!.id),
                ],
                isFetching: false,
            };
        case actions.GET_EXECUTIVES_FAILED:
        case actions.GET_ALL_EXECUTIVES_FAILED:
        case actions.GET_POTENTIAL_EXECUTIVES_FAILED:
        case actions.GET_ORGANISATION_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case actions.CHECK_ORGANISATION_EXISTS_SUCCEED:
            return {
                ...state,
                invitedOrganisationExistsStatus: action.payload,
            };
        case actions.CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED:
            return {
                ...state,
                upstreamClient: action.payload,
                isFetching: false,
                upstreamClientExistStatus: RESPONSE.CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED,
            };
        case actions.CREATE_UPSTREAM_CLIENT_REQUEST:
            return {
                ...state,
                upstreamClient: action.payload,
                isFetching: true,
            };
        case actions.CREATE_UPSTREAM_CLIENT_REQUEST_FAILED:
            return {
                ...state,
                upstreamClientExistStatus: RESPONSE.CREATE_UPSTREAM_CLIENT_REQUEST_FAILED,
                isFetching: false,
            };
        case actions.GET_UPSTREAM_CLIENT_REQUEST:
            return {
                ...state,
                isFetching: true,
            };

        case actions.GET_UPSTREAM_CLIENT_REQUEST_SUCCEED:
            return {
                ...state,
                upstreamClient: action.payload,
                upstreamClientExistStatus: RESPONSE.UPSTREAM_CLIENT_FOUND,
                isFetching: false,
            };
        case actions.GET_UPSTREAM_CLIENT_REQUEST_FAILED:
            return {
                ...state,
                upstreamClientExistStatus: RESPONSE.UPSTREAM_CLIENT_NOT_FOUND,
                isFetching: false,
            };
        case actions.UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case actions.UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_SUCCEED:
            return {
                ...state,
                upstreamClient: {
                    ...state.upstreamClient,
                    ...action.payload,
                },
                isFetching: false,
            };

        case actions.UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_FAILED:
            return {
                ...state,
                UpstreamClientExistStatus: RESPONSE.UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_FAILED,
                isFetching: false,
            };
        case actions.GET_UPSTREAM_CLIENT_SECRET_CODE_REQUEST:
        case actions.GET_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED:
            return {
                ...state,
                upstreamClient: {
                    ...state.upstreamClient,
                    clientSecret: action.payload,
                },
                UpstreamClientExistStatus: RESPONSE.SECRET_CODE_FOUND,
            };
        case actions.GET_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_FAILED:
            return {
                ...state,
                UpstreamClientExistStatus: RESPONSE.SECRET_CODE_NOT_FOUND,
            };
        case actions.REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED:
            return {
                ...state,
                clientSecret: action.payload,
                UpstreamClientExistStatus:
                    RESPONSE.REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED,
            };
        case actions.REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_FAILED:
            return {
                ...state,
                UpstreamClientExistStatus:
                    RESPONSE.REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_FAILED,
            };
        default:
            return state;
    }
};
