import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';

import {
    ClientSecret,
    Organisation,
    UpstreamWebsiteClientInstance,
} from '../models/organisationModels';

@injectable()
export class OrganisationService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public createUpstreamClient({
        organizationId,
        type,
        allowedRedirectUrls,
        allowedWebOrigins,
    }: Partial<UpstreamWebsiteClientInstance>): Promise<UpstreamWebsiteClientInstance> {
        return this.http.POST<UpstreamWebsiteClientInstance>(
            `${config.routeConfig.organisations}${organizationId}/upstream-clients/`,
            {
                type,
                allowedRedirectUrls,
                allowedWebOrigins,
            },
        );
    }
    public getUpstreamClient(organisationId: number): Promise<UpstreamWebsiteClientInstance> {
        return this.http.GET<UpstreamWebsiteClientInstance>(
            `${config.routeConfig.organisations}${organisationId}/upstream-clients/get-instance/`,
        );
    }
    public getUpstreamClientSecretCode({
        organizationId,
        id,
    }: Partial<UpstreamWebsiteClientInstance>): Promise<ClientSecret> {
        return this.http.GET<ClientSecret>(
            `${config.routeConfig.organisations}${organizationId}/upstream-clients/${id}/get-secret/`,
        );
    }
    public regenerateUpstreamClientSecretCode({
        organizationId,
        id,
    }: Partial<UpstreamWebsiteClientInstance>): Promise<ClientSecret> {
        return this.http.POST<ClientSecret>(
            `${config.routeConfig.organisations}${organizationId}/upstream-clients/${id}/regenerate-secret/`,
        );
    }
    public updateUpstreamClientSettings(
        data: Partial<UpstreamWebsiteClientInstance>,
    ): Promise<Partial<UpstreamWebsiteClientInstance>> {
        const organisationId = data.organizationId;
        const { organizationId, id, type, clientSecret, clientId, ...restOfData } = data;
        return this.http.PATCH<Partial<UpstreamWebsiteClientInstance>>(
            `${config.routeConfig.organisations}${organisationId}/upstream-clients/${data.id}/`,
            restOfData,
        );
    }
    public getOrganisation(id: number): Promise<Organisation> {
        return this.http.GET<Organisation>(`${config.routeConfig.organisations}${id}/`);
    }

    public editOrganisation(data: Partial<Organisation>): Promise<Organisation> {
        return this.http.PATCH<Organisation>(
            `${config.routeConfig.organisations}${data.id}/`,
            data,
        );
    }

    public getExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(`${config.routeConfig.executives}`);
    }

    public getPotentialExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(config.routeConfig.potentialExecutives);
    }

    public getAllExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(config.routeConfig.executives);
    }

    public removeExecutive(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.executives}${id}/`);
    }

    public checkOrganisationExists(id: string): Promise<any> {
        return this.http.GET(
            `${
                config.routeConfig.organisations
            }reference-number-check/?reference_number=${id.toUpperCase()}`,
            undefined,
            undefined,
            true,
        );
    }
}
