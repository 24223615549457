import posthog from 'posthog-js';
import { SENTRY_ENVIRONMENT } from 'config/env';
import { Action } from 'common/actions/appAction';

export const IMPORT_BENEFITS_REQUESTED = 'IMPORT_BENEFITS_REQUESTED';
export type ImportBenefitsRequestAction = Action<typeof IMPORT_BENEFITS_REQUESTED, File>;
export const importBenefitsRequest = (file: File): ImportBenefitsRequestAction => ({
    type: IMPORT_BENEFITS_REQUESTED,
    payload: file,
});

export const IMPORT_BENEFITS_SUCCEED = 'IMPORT_BENEFITS_SUCCEED';
export type ImportBenefitsSuccessAction = Action<
    typeof IMPORT_BENEFITS_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const importBenefitsSuccess = (): ImportBenefitsSuccessAction => ({
    type: IMPORT_BENEFITS_SUCCEED,
    meta: 'Benefits imported',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Import Unique Benefits')
            : null,
});

export const IMPORT_BENEFITS_FAILED = 'IMPORT_BENEFITS_FAILED';
export type ImportBenefitsFailureAction = Action<
    typeof IMPORT_BENEFITS_FAILED,
    string[],
    null,
    posthog.CaptureResult | null
>;
export const importBenefitsFailure = (errors: string[]): ImportBenefitsFailureAction => ({
    type: IMPORT_BENEFITS_FAILED,
    payload: errors,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Import Unique Benefits Failure')
            : null,
});

export const IMPORT_CONTRACTS_REQUESTED = 'IMPORT_CONTRACTS_REQUESTED';
export type ImportContractsRequestAction = Action<typeof IMPORT_CONTRACTS_REQUESTED, File>;
export const importContractsRequest = (file: File): ImportContractsRequestAction => ({
    type: IMPORT_CONTRACTS_REQUESTED,
    payload: file,
});

export const IMPORT_CONTRACTS_SUCCEED = 'IMPORT_CONTRACTS_SUCCEED';
export type ImportContractsSuccessAction = Action<
    typeof IMPORT_CONTRACTS_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const importContractsSuccess = (): ImportContractsSuccessAction => ({
    type: IMPORT_CONTRACTS_SUCCEED,
    meta: 'Projects imported',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Import Contracts') : null,
});

export const IMPORT_CONTRACTS_FAILED = 'IMPORT_CONTRACTS_FAILED';
export type ImportContractsFailureAction = Action<
    typeof IMPORT_CONTRACTS_FAILED,
    string[],
    null,
    posthog.CaptureResult | null
>;
export const importContractsFailure = (errors: string[]): ImportContractsFailureAction => ({
    type: IMPORT_CONTRACTS_FAILED,
    payload: errors,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Import Contracts Failed')
            : null,
});

export const IMPORT_BENEFIT_TEMPLATES_REQUESTED = 'IMPORT_BENEFIT_TEMPLATES_REQUESTED';
export type ImportBenefitTemplatesRequestAction = Action<
    typeof IMPORT_BENEFIT_TEMPLATES_REQUESTED,
    File
>;
export const importBenefitTemplatesRequest = (file: File): ImportBenefitTemplatesRequestAction => ({
    type: IMPORT_BENEFIT_TEMPLATES_REQUESTED,
    payload: file,
});

export const IMPORT_BENEFIT_TEMPLATES_SUCCEED = 'IMPORT_BENEFIT_TEMPLATES_SUCCEED';
export type ImportBenefitTemplatesSuccessAction = Action<
    typeof IMPORT_BENEFIT_TEMPLATES_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const importBenefitTemplatesSuccess = (): ImportBenefitTemplatesSuccessAction => ({
    type: IMPORT_BENEFIT_TEMPLATES_SUCCEED,
    meta: 'Templated benefits imported',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Import Templated Benefits')
            : null,
});

export const IMPORT_BENEFIT_TEMPLATES_FAILED = 'IMPORT_BENEFIT_TEMPLATES_FAILED';
export type ImportBenefitTemplatesFailureAction = Action<
    typeof IMPORT_BENEFIT_TEMPLATES_FAILED,
    string[],
    null,
    posthog.CaptureResult | null
>;
export const importBenefitTemplatesFailure = (
    errors: string[],
): ImportBenefitTemplatesFailureAction => ({
    type: IMPORT_BENEFIT_TEMPLATES_FAILED,
    payload: errors,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Import Templated Benefits Failure')
            : null,
});

export type ImportActions =
    | ImportBenefitsRequestAction
    | ImportBenefitsSuccessAction
    | ImportBenefitsFailureAction
    | ImportContractsRequestAction
    | ImportContractsSuccessAction
    | ImportContractsFailureAction
    | ImportBenefitTemplatesRequestAction
    | ImportBenefitTemplatesSuccessAction
    | ImportBenefitTemplatesFailureAction;
