import * as _ from 'lodash';
import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config, organisationUrl } from 'config/config';
import { contractPath, evidencePath, scorePath } from 'common/helpers/utils';

import {
    Evidence,
    EvidenceApproval,
    EvidenceData,
    ScoreRequest,
    Score,
    ScoreApproval,
    GetMyEvidenceRequest,
    FileData,
    ScoresRequest,
    EvidenceDecline,
    ScoreDecline,
    DeleteScoreFileRequest,
    DeleteEvidenceFileRequest,
    UploadBenefit,
    ContractManagerDetail,
    UpdateEvidenceRequest,
    CreateEvidenceRequest,
    ContractPath,
} from '../models/uploadModels';
import { Contract } from 'features/contract/models/contractModels';

@injectable()
export class UploadService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public approveScore(score: ScoreApproval): Promise<Score> {
        const path = `${scorePath(score)}approve/`;

        return this.http.POST(path, {});
    }

    public approveEvidence({
        evidenceId,
        selfAssessment,
        ...rest
    }: EvidenceApproval): Promise<Evidence> {
        const path = `${evidencePath(rest)}${evidenceId}/approve/`;
        return this.http.POST(path, { selfAssessment });
    }

    public declineEvidence({
        evidenceId,
        declineMessage,
        ...rest
    }: EvidenceDecline): Promise<Evidence> {
        const path = `${evidencePath(rest)}${evidenceId}/decline/`;

        return this.http.POST(path, { declineMessage });
    }

    public declineScore({ declineMessage, ...score }: ScoreDecline): Promise<Score> {
        const path = `${scorePath(score)}decline/`;

        return this.http.POST(path, { declineMessage });
    }

    public getEvidences(evidenceRequest: EvidenceData): Promise<Evidence[]> {
        const path = evidencePath(evidenceRequest);

        return this.http.GET<Evidence[]>(path);
    }

    public getMyEvidences(myEvidenceRequest: GetMyEvidenceRequest): Promise<Evidence> {
        const path = evidencePath(myEvidenceRequest);

        return this.http.GET<Evidence>(`${path}my/`);
    }

    public getMyEvidenceFiles(evidence: EvidenceData): Promise<FileData[]> {
        const path = evidencePath(evidence);

        return this.http.GET<FileData[]>(`${path}${evidence.id}/files/`);
    }

    public getContractFiles(path: ContractPath): Promise<FileData[]> {
        const finalPath = contractPath(path);

        return this.http.GET<FileData[]>(`${finalPath}files/`);
    }

    public async getEvidencesList(evidencesRequest: EvidenceData[]): Promise<Evidence[]> {
        const request = (evidenceRequest: EvidenceData) =>
            this.http.GET<Evidence[]>(evidencePath(evidenceRequest));
        const evidences = await Promise.all(evidencesRequest.map(request));

        return _.flatten(evidences);
    }

    public getScores({ benefitId, contractId, organisationId }: ScoresRequest): Promise<Score[]> {
        const organisation = organisationId
            ? `${organisationUrl}${organisationId}/contracts/`
            : config.routeConfig.contract;

        const path = `${organisation}${contractId}/benefits/${benefitId}/scores/`;

        return this.http.GET<Score[]>(path);
    }

    public getMyScore({ benefitId, contractId, organisationId }: ScoresRequest): Promise<Score> {
        const organisation = organisationId
            ? `${organisationUrl}${organisationId}/contracts/`
            : config.routeConfig.contract;

        const path = `${organisation}${contractId}/benefits/${benefitId}/scores/my/`;

        return this.http.GET<Score>(path);
    }

    public submitForApproval({
        benefitId,
        contractId,
        deliveryDateId,
        evidenceId,
        organisationId,
    }: UpdateEvidenceRequest): Promise<void> {
        const evidence = evidencePath({
            benefitId,
            contractId,
            deliveryDateId,
            organisationId,
        });
        const path = `${evidence}${evidenceId}/ready-for-approval/`;

        return this.http.POST(path, {});
    }

    public submitScoreForApproval({
        benefitId,
        contractId,
        scoreId,
        organisationId,
    }: ScoreRequest): Promise<void> {
        const path = `${organisationUrl}${organisationId}/contracts/${contractId}/benefits/${benefitId}/scores/${scoreId}/ready-for-approval/`;

        return this.http.POST(path, {});
    }

    public createEvidence({
        benefitId,
        contractId,
        deliveryDateId,
        organisationId,
        agreedToGdpr,
        questionnaireAnswer,
        benefitChangeSuggestions,
        selfAssessment,
    }: CreateEvidenceRequest): Promise<EvidenceData> {
        const path = evidencePath({
            benefitId,
            contractId,
            deliveryDateId,
            organisationId,
        });

        return this.http.POST(path, {
            agreedToGdpr,
            questionnaireAnswer,
            benefitChangeSuggestions,
            selfAssessment,
        });
    }

    public updateEvidence({
        benefitId,
        contractId,
        deliveryDateId,
        evidenceId,
        organisationId,
        agreedToGdpr,
        questionnaireAnswer,
        benefitChangeSuggestions,
        selfAssessment,
    }: UpdateEvidenceRequest): Promise<EvidenceData> {
        const path = evidencePath({
            benefitId,
            contractId,
            deliveryDateId,
            organisationId,
            evidenceId,
        });

        return this.http.PATCH(`${path}${evidenceId}/`, {
            agreedToGdpr,
            questionnaireAnswer,
            benefitChangeSuggestions,
            selfAssessment,
        });
    }

    public createScore({
        organisationId,
        contractId,
        benefitId,
        agreedToGdpr,
        questionnaireAnswer,
        selfAssessment,
    }: ScoreRequest): Promise<Score> {
        const path = scorePath({ organisationId, contractId, benefitId });

        return this.http.POST(path, { agreedToGdpr, questionnaireAnswer, selfAssessment });
    }

    public updateScore({
        organisationId,
        contractId,
        benefitId,
        scoreId,
        agreedToGdpr,
        questionnaireAnswer,
        selfAssessment,
    }: ScoreRequest): Promise<Score> {
        const path = scorePath({ organisationId, contractId, benefitId, scoreId });

        return this.http.PATCH(path, { agreedToGdpr, questionnaireAnswer, selfAssessment });
    }

    public deleteScoreFile({
        benefitId,
        contractId,
        fileId,
        scoreId,
        organisationId,
    }: DeleteScoreFileRequest): Promise<DeleteScoreFileRequest> {
        const path = scorePath({ organisationId, contractId, benefitId, scoreId });

        return this.http.DELETE(`${path}files/${fileId}/`);
    }

    public deleteEvidenceFile({
        benefitId,
        contractId,
        fileId,
        evidenceId,
        deliveryDateId,
        organisationId,
    }: DeleteEvidenceFileRequest): Promise<DeleteEvidenceFileRequest> {
        const path = evidencePath({ benefitId, contractId, deliveryDateId, organisationId });

        return this.http.DELETE(`${path}${evidenceId}/files/${fileId}/`);
    }

    public getScorableBenefits(): Promise<UploadBenefit[]> {
        return this.http.GET(config.routeConfig.scorableBenefits);
    }

    public getReadyForEvidenceBenefits(): Promise<UploadBenefit[]> {
        return this.http.GET(config.routeConfig.readyForEvidenceBenefits);
    }

    public getReadyForPendingEvidenceBenefits(): Promise<UploadBenefit[]> {
        return this.http.GET(config.routeConfig.readyForPendingEvidenceBenefits);
    }

    public getContractManagerDetails(contractId: number): Promise<ContractManagerDetail[]> {
        const path = `${config.routeConfig.allContracts}${contractId}/contract-manager-details/`;

        return this.http.GET<ContractManagerDetail[]>(path);
    }
}
