import * as React from 'react';
import { Button, Radio } from 'antd';
import styled, { keyframes } from 'styled-components';
import { Profile } from 'features/user/models/userModel';
import { breakpoints } from 'theme/variables';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';
import { CenteringFormItem } from 'common/components/styled';
import { PaddedSection } from 'common/components';
import { AssignRoleActions } from '../models/editModel';
import { Organisation } from 'features/organisation/models/organisationModels';

const SubmitButtonsWrapper = styled(CenteringFormItem)`
    button {
        margin-right: 20px;
    }
    ${breakpoints.small} {
        button {
            margin: 0 20px;
        }
    }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const AvailableAdminMessage = styled.div`
    margin-left: 5px;
    color: red;
    /* Initial state is hidden */
    opacity: 0;
    transition: all 0.3s ease-out; /* Apply transition for smooth animations */

    &.fade-in {
        animation: ${fadeIn} 0.3s ease-out forwards;
    }

    &.fade-out {
        animation: ${fadeOut} 0.3s ease-out forwards;
    }
`;

export interface SubmitButtonsProps {
    showInviteButton?: boolean;
    showSaveButton?: boolean;
    isFetching?: boolean;
}

interface SupplierUserFormProps extends SubmitButtonsProps {
    profile: Profile;
    isFetching?: boolean;
    handleRoleChange: (isAdmin: boolean) => void;
    organisation: Organisation;
}

type Props = Antd3FormProps & SupplierUserFormProps;

export const SubmitButtons: React.FunctionComponent<SubmitButtonsProps> = ({
    showInviteButton,
    showSaveButton,
    isFetching,
}) => (
    <SubmitButtonsWrapper>
        {showInviteButton && (
            <Button type="primary" htmlType="submit" disabled={showSaveButton}>
                Invite
            </Button>
        )}
        {showSaveButton && (
            <Button
                disabled={!showSaveButton}
                type="primary"
                htmlType="submit"
                loading={isFetching}
            >
                Save
            </Button>
        )}
    </SubmitButtonsWrapper>
);

export const SupplierUserForm: React.FunctionComponent<Props> = ({
    profile,
    role,
    handleRoleChange: handleRoleChange,
    ...props
}) => {
    const { form, organisation } = props;
    const {
        telephone,
        user: { firstName, lastName },
    } = profile;
    React.useEffect(() => {
        handleRoleChange(false);
    }, []);
    const [isAdminSelected, setIsAdminSelected] = React.useState(false);
    const handleAssignChange = (e: any) => {
        const value = e.target.value;
        setIsAdminSelected(value);
        handleRoleChange(value);
    };
    const adminRolesLeft =
        organisation.availableAdministratorRoles === null
            ? 0
            : organisation.availableAdministratorRoles;
    const adminRolesLeftMessage =
        adminRolesLeft >= 1 && isAdminSelected
            ? `${adminRolesLeft - 1}/${adminRolesLeft} admin roles left`
            : adminRolesLeft === 0
            ? 'No admin roles left'
            : '';

    return (
        organisation !== undefined && (
            <>
                <PaddedSection>
                    <ResetButton form={form} />
                    <Input form={form} id="firstName" label="First name" initialValue={firstName} />
                    <Input form={form} id="lastName" label="Last Name" initialValue={lastName} />
                    <PhoneInput
                        form={form}
                        id="telephone"
                        placeholder="Telephone Number"
                        initialValue={telephone}
                    />
                    <Antd3Form.Item>
                        <Radio.Group
                            id="assign"
                            name="assign"
                            defaultValue={false}
                            onChange={handleAssignChange}
                        >
                            <Radio value={true}>{AssignRoleActions.Admin}</Radio>
                            <Radio value={false}>{AssignRoleActions.User}</Radio>
                        </Radio.Group>
                        {adminRolesLeft > 0 && (
                            <AvailableAdminMessage
                                className={!isAdminSelected ? 'fade-out' : 'fade-in'}
                            >
                                {adminRolesLeftMessage}
                            </AvailableAdminMessage>
                        )}
                        {adminRolesLeft === 0 && (
                            <AvailableAdminMessage
                                className={!isAdminSelected ? 'fade-out' : 'fade-in'}
                                style={{ color: 'red' }}
                            >
                                {adminRolesLeftMessage}
                            </AvailableAdminMessage>
                        )}
                    </Antd3Form.Item>
                    <SubmitButtons {...props} />
                </PaddedSection>
            </>
        )
    );
};
