import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { StyledAntd3Form } from 'common/components/deprecated/antd3';
import { Input } from 'common/components/form/Input';
import {
    DynamicUpstreamClientFieldSet,
    DynamicUpstreamClientFieldSetField,
} from 'common/components/deprecated/form/DynamicUpstreamClientFieldSet';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import { AuthType, RESPONSE } from '../models/organisationModels';
import { SubmitButton } from 'common/components';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus } from '../selectors/organisationSelectors';
import { Notification, showNotification } from 'common/helpers/notifications';
import { getUserOrganisationId } from 'features/user/selectors/userSelectors';
import { createUpstreamClientRequest } from '../actions/organisationActions';

interface CreateClientUpstreamProps {}
interface FormState {
    authType: AuthType;
    redirectUrls: string[];
    webOrigins: string[];
    isValidWebOrigins: boolean;
    isValidRedirectUrls: boolean;
}
export const CreateClientUpstream: React.FC<CreateClientUpstreamProps> = () => {
    const [formState, setFormState] = useState<FormState>({
        authType: AuthType.OIDC,
        redirectUrls: [],
        webOrigins: [],
        isValidWebOrigins: true,
        isValidRedirectUrls: true,
    });

    const dispatch = useDispatch();
    const status = useSelector(getStatus);
    const organisationId = useSelector(getUserOrganisationId);

    const handleAuthTypeChange = (e: ChangeEvent<HTMLInputElement>) =>
        setFormState({ ...formState, authType: e.target.value as AuthType });
    const areUrlsDifferent = (newUrls: string[], existingUrls: string[]): boolean =>
        newUrls.join(',') !== existingUrls.join(',');
    const getUrls = (
        userTypedUrlsRecord: Record<string, DynamicUpstreamClientFieldSetField>,
    ): string[] => Object.values(userTypedUrlsRecord).map((url) => url.title);

    const handleFieldSetChange = (
        userTypedUrls: Record<string, DynamicUpstreamClientFieldSetField>,
        field: string,
    ) => {
        if (typeof userTypedUrls === 'object' && userTypedUrls !== null) {
            const updatedUrls: string[] = getUrls(userTypedUrls);
            if (field === 'redirectUrls' && areUrlsDifferent(updatedUrls, formState.redirectUrls)) {
                setFormState({ ...formState, redirectUrls: updatedUrls });
            } else if (
                field === 'webOrigins' &&
                areUrlsDifferent(updatedUrls, formState.webOrigins)
            ) {
                setFormState({ ...formState, webOrigins: updatedUrls });
            }
        } else {
            console.error('Invalid format for values:', userTypedUrls);
        }
    };

    const isValidDomain = (url: string): boolean => {
        try {
            if (url === '*') {
                return true;
            }
            const urlRegex = /^(https?:\/\/)?([\w.-]+)(:\d+)?(\/[\w/.-]*)*$/;
            return urlRegex.test(url);
        } catch (error) {
            return false;
        }
    };

    const areValuesUnique = (values: string[]): boolean => {
        const uniqueValues = new Set(values);
        return uniqueValues.size === values.length;
    };
    const userTypedUrlsDoesntContainEmptyUrl = (urls: string[]): boolean => !urls.includes('');
    const validateFields = (): boolean => {
        const areWebOriginsValid =
            formState.webOrigins.length > 0 &&
            formState.webOrigins.every((url) => isValidDomain(url)) &&
            areValuesUnique(formState.webOrigins) &&
            userTypedUrlsDoesntContainEmptyUrl(formState.webOrigins);

        const areRedirectUrlsValid =
            formState.redirectUrls.length > 0 &&
            formState.redirectUrls.every((url) => isValidDomain(url)) &&
            areValuesUnique(formState.redirectUrls) &&
            userTypedUrlsDoesntContainEmptyUrl(formState.redirectUrls);

        if (areWebOriginsValid !== formState.isValidWebOrigins) {
            setFormState({ ...formState, isValidWebOrigins: areWebOriginsValid });
        }

        if (areRedirectUrlsValid !== formState.isValidRedirectUrls) {
            setFormState({ ...formState, isValidRedirectUrls: areRedirectUrlsValid });
        }

        return areWebOriginsValid && areRedirectUrlsValid;
    };

    const showNotificationIfSuccess = () => {
        showNotification({
            type: Notification.Success,
            text: 'Upstream Client Was Successfully Created',
        });
    };

    const showNotificationIfError = () => {
        showNotification({
            type: Notification.Error,
            text: 'Oops, something went wrong. Please try again',
        });
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!validateFields()) {
            console.error('Error: Please fix the validation errors.');
            return;
        }

        const newUpstreamClient = {
            organizationId: organisationId,
            type: 'OIDC',
            allowedWebOrigins: formState.webOrigins,
            allowedRedirectUrls: formState.redirectUrls,
        };

        dispatch(createUpstreamClientRequest(newUpstreamClient));

        if (status === RESPONSE.CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED) {
            showNotificationIfSuccess();
            return;
        }
        showNotificationIfError();
    };

    return (
        <StyledAntd3Form onSubmit={handleSubmit} header="Create Upstream Website Client" bigHeader>
            <>
                {/* EXPLANATION: this is a hidden input because the upstream client type for now is OIDC */}
                <Form.Item>
                    <Input
                        type="text"
                        id="label"
                        label={'Type'}
                        placeholder={AuthType.OIDC}
                        name={'clientSecret'}
                        tooltip={templatedBenefitTooltips.BenefitLabel}
                        disabled={true}
                        readOnly
                        value={formState.authType}
                        onChange={handleAuthTypeChange}
                        hidden={true}
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={!formState.isValidRedirectUrls ? 'error' : ''}
                    help={
                        formState.redirectUrls.length === 0 ||
                        !userTypedUrlsDoesntContainEmptyUrl(formState.redirectUrls)
                            ? 'Redirect URLs are required'
                            : !areValuesUnique(formState.redirectUrls)
                            ? 'Redirect URLs must be unique'
                            : !formState.isValidRedirectUrls
                            ? 'Enter a valid URL'
                            : ''
                    }
                >
                    <DynamicUpstreamClientFieldSet
                        fieldSetId={'5'}
                        fieldType={'upStreamClientsUrls'}
                        placeholder={'(i.e.https://app-name.com/authentication/cenefits-auth/)'}
                        label={'Allowed Redirect URLs'}
                        name={'upStreamClientsUrls'}
                        buttonLabel="allowed url"
                        onFieldsChanged={(updatedFields) =>
                            handleFieldSetChange(updatedFields, 'redirectUrls')
                        }
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={
                        !formState.isValidWebOrigins || !areValuesUnique(formState.webOrigins)
                            ? 'error'
                            : ''
                    }
                    help={
                        formState.webOrigins.length === 0 ||
                        !userTypedUrlsDoesntContainEmptyUrl(formState.webOrigins)
                            ? 'Web Origins URLs are required.'
                            : !areValuesUnique(formState.webOrigins)
                            ? 'Web Origins URLs must be unique'
                            : !formState.isValidWebOrigins
                            ? 'Enter a valid URL'
                            : ''
                    }
                >
                    <DynamicUpstreamClientFieldSet
                        fieldSetId={'7'}
                        name={'upStreamClientsUrls'}
                        placeholder=" (i.e. 'https://backend.app-name.com/')"
                        fieldType={'upStreamClientsUrls'}
                        label={'Allowed Web Origins'}
                        buttonLabel="allowed url"
                        onFieldsChanged={(updatedFields) =>
                            handleFieldSetChange(updatedFields, 'webOrigins')
                        }
                    />
                </Form.Item>
                <SubmitButton
                    value="Create Upstream Client"
                    onClick={handleSubmit}
                    disabled={
                        !validateFields() ||
                        status === RESPONSE.CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED
                    }
                />
            </>
        </StyledAntd3Form>
    );
};
