import React from 'react';
import ReactDOM from 'react-dom/client';
import { init } from '@sentry/browser';
import 'whatwg-fetch';

import { Root } from 'common/containers/Root';
import { ENV, NODE_ENV, SENTRY_ENVIRONMENT, SENTRY_JS } from 'config/env';

import './index.less';

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <Root />
        </React.StrictMode>,
    );
}

const isDevelopment = ENV === 'development' || NODE_ENV === 'development';

if (!isDevelopment && SENTRY_JS) {
    init({
        dsn: SENTRY_JS,
        environment: SENTRY_ENVIRONMENT,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
        ],
    });
}
