import { AppState } from 'common/appState';

export const getEvidences = (state: AppState) => state.upload.evidences;
export const getMyEvidence = (state: AppState) => state.upload.myEvidence;
export const getScores = (state: AppState) => state.upload.scores;
export const getMyScore = (state: AppState) => state.upload.myScore;
export const getUploadingStatus = (state: AppState) => state.upload.isUploading;
export const getFetchingStatus = (state: AppState) => state.upload.fetchingStatus;
export const getChangingApprovalStatus = (state: AppState) => state.upload.isChangingApprovalStatus;
export const getContractManagerDetails = (state: AppState) => state.upload.contractManagerDetails;
export const getScorableBenefits = (state: AppState) => state.upload.scorableBenefits;
export const getReadyForEvidenceBenefits = (state: AppState) =>
    state.upload.readyForEvidenceBenefits;
export const getReadyForPendingEvidenceBenefits = (state: AppState) =>
    state.upload.readyForPendingEvidenceBenefits;
export const getFetchingScorableStatus = (state: AppState) => state.upload.isFetchingScorable;
export const getFetchingReadyForEvidenceStatus = (state: AppState) =>
    state.upload.isFetchingReadyForEvidence;
export const getPreviousPath = (state: AppState) => state.upload.previousPath;
