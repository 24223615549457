import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { StaticContext } from 'react-router';

import { setAfterLoginRoutes } from 'config/config';
import { CustomRouteProps } from 'common/models/navigationModel';
import { UserPaths } from 'features/user/models/userModel';

import { FullPageSpinner } from './FullPageSpinner';
import { ContentMenu } from './ContentMenu';
import { userRoutes } from 'features/user/config/userRouting';
import { EditPaths } from 'features/edit/models/editModel';

export type RouteProps = RouteComponentProps<
    {
        [x: string]: string | undefined;
    },
    StaticContext,
    unknown
>;

export const CustomRoute = ({
    component: Component,
    isAuthenticated,
    isTokenChecked,
    redirect = '/sign-in',
    secured = false,
    path,
    activeOrganisation,
    subscriptionStatus,
    adminMenuLinks,
    contractManagerMenuLinks,
    menuLinks,
    exact,
    location,
    hideNavigation,
    SubNavigation,
    isUserAdmin,
    isUserTenant,
}: CustomRouteProps) => {
    if (activeOrganisation) {
        setAfterLoginRoutes(activeOrganisation.organisation.id);
    }
    const isSubscriptionExpired = subscriptionStatus === 'EXPIRED';
    const renderRouteContent = (routeProps: RouteProps) => {
        if (!Component) {
            return;
        }

        if (!isTokenChecked) {
            return <FullPageSpinner />;
        }

        if (isSubscriptionExpired) {
            const allowedRoutes = [
                '/user/update-user-data',
                '/user/change-password',
                '/user/change-email',
                '/sign-in',
            ];

            isUserAdmin && isUserTenant
                ? allowedRoutes.push('/edit/subscriptions', '/edit/employees/:pageNumber?')
                : isUserAdmin
                ? allowedRoutes.push('/edit//:pageNumber?', '/upload/')
                : !isUserAdmin && !isUserTenant
                ? allowedRoutes.push('/upload/')
                : '';
            if (allowedRoutes.some((allowedRoute) => path.startsWith(allowedRoute))) {
                return (
                    <>
                        {!hideNavigation && (
                            <ContentMenu
                                adminMenuLinks={adminMenuLinks}
                                contractManagerMenuLinks={contractManagerMenuLinks}
                                menuLinks={menuLinks}
                            />
                        )}
                        {SubNavigation && <SubNavigation {...routeProps} />}
                        <Component {...routeProps} />
                    </>
                );
            }

            // Redirect to a specific page for users with expired subscriptions
            return (
                <Redirect
                    to={{
                        pathname:
                            isUserAdmin && isUserTenant
                                ? '/edit/employees/:pageNumber?'
                                : isUserAdmin
                                ? '/edit//:pageNumber?'
                                : '/user/update-user-data',
                        state: { referrer: location },
                    }}
                />
            );
        }

        if (isAuthenticated === secured) {
            return isAuthenticated && isUndefined(activeOrganisation) ? (
                <FullPageSpinner />
            ) : (
                <>
                    {!hideNavigation && (
                        <ContentMenu
                            adminMenuLinks={adminMenuLinks}
                            contractManagerMenuLinks={contractManagerMenuLinks}
                            menuLinks={menuLinks}
                        />
                    )}
                    {SubNavigation && <SubNavigation {...routeProps} />}
                    <Component {...routeProps} />
                </>
            );
        }

        if (path == UserPaths.VerifyNewAccount || path == UserPaths.VerifyNewExecutive) {
            return <Component {...routeProps} />;
        }

        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: { state: 'Access denied', referrer: location },
                }}
            />
        );
    };

    return <Route path={path} exact={exact} render={renderRouteContent} />;
};
