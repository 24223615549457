import { AppAction } from 'common/actions/appAction';
import { VIEW_SUPPLIER_SUBSCRIPTION_VIEW } from '../actions/editActions';

export interface EditState {
    viewSupplierSubscriptionView: boolean;
}

const defaultEditState: EditState = {
    viewSupplierSubscriptionView: false,
};

export const editReducer = (state: EditState = defaultEditState, action: AppAction) => {
    switch (action.type) {
        case VIEW_SUPPLIER_SUBSCRIPTION_VIEW:
            return {
                ...state,
                viewSupplierSubscriptionView: action.payload,
            };
        default:
            return state;
    }
};
