import { AppState } from 'common/appState';
import { createSelector } from 'reselect';

import { Organisation } from '../models/organisationModels';
import { getUserOrganisationId } from 'features/user/selectors/userSelectors';

export const getStatus = (state: AppState) => state.organisation.upstreamClientExistStatus;
export const getUpstreamClient = (state: AppState) => state.organisation.upstreamClient;
export const getExecutives = (state: AppState) => state.organisation.executives;
export const getPotentialExecutives = (state: AppState) => state.organisation.potentialExecutives;
export const getOrganisations = (state: AppState) => state.organisation.organisations;
export const getFetchingOrganisationsStatus = (state: AppState) => state.organisation.isFetching;
export const getOrganisation = (state: AppState, organisationId: number) =>
    state.organisation.organisations.find(({ id }) => id == organisationId);

export const getNames = (data: Organisation[]) => data.map(({ name }) => name);

export const getMyOrganisationById = (organisations: Organisation[], organisationId: number) =>
    organisations.find(({ id }) => id === organisationId)!;
export const state = (state: AppState) => state;
export const getOrganisationById = (state: AppState, organisationId: number) =>
    state.organisation.organisations.find(({ id }) => id === organisationId);

export const getMyOrganisation = createSelector(
    getOrganisations,
    getUserOrganisationId,
    getMyOrganisationById,
);

export const getInvitedOrganisationExists = (state: AppState) =>
    state.organisation.invitedOrganisationExistsStatus;
