import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { saveFilters } from 'features/pagination/actions/paginationActions';

import { PaginationView } from '../models/paginationModel';
import { FilterData } from 'common/helpers/url';

type Props = {
    paginatedView: PaginationView;
    path?: string;
    filterName?: string;
    refreshTable: (filters?: FilterData) => void;
};

export const PaginationValueFilter = ({
    paginatedView,
    path,
    filterName = 'name',
    refreshTable,
}: Props) => {
    const dispatch = useDispatch();

    const handleNameFilterChange = debounce((e) => {
        dispatch(
            saveFilters({
                paginatedView,
                values: { [filterName]: e.target.value },
                path,
            }),
        );
        if (refreshTable) {
            refreshTable();
        }
    }, 300);

    return <Input onChange={handleNameFilterChange} placeholder="Filter name..." allowClear />;
};
