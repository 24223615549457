import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Benefit, BenefitPathParams } from 'features/benefit/models/benefitModels';
import {
    getMyBenefitsSuccess,
    deleteBenefitRequest,
    toggleMultipleEmployeesRoleRequest,
    toggleMultipleInvitePendingExecutiveUsersRoleRequest,
} from 'features/benefit/actions';
import {
    getMyBenefits,
    getFilteringBenefitsStatus,
} from 'features/benefit/selectors/benefitSelectors';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { isTenant, isAdmin } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';

import { EditMyBenefits as EditMyBenefitsComponent } from '../components/EditMyBenefits';

export interface EditMyBenefitsStateProps {
    myBenefits: Benefit[];
    isFiltered: boolean;
    isUserAdmin: boolean;
    tenant: boolean;
    filter?: PaginationFilters;
    pageNumber?: number;
}

interface EditMyBenefitsDispatchProps {
    deleteBenefitRequest: typeof deleteBenefitRequest;
    getMyBenefitsSuccess: typeof getMyBenefitsSuccess;
    redirectToUrl: typeof redirectToUrl;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
    toggleMultipleEmployeesRoleRequest: typeof toggleMultipleEmployeesRoleRequest;
    toggleMultipleInvitePendingExecutiveUsersRoleRequest: typeof toggleMultipleInvitePendingExecutiveUsersRoleRequest;
    test: typeof test;
}

export type EditMyBenefitsProps = EditMyBenefitsDispatchProps &
    EditMyBenefitsStateProps &
    RouteParams;

export class EditMyBenefits extends React.Component<EditMyBenefitsProps> {
    public deleteBenefit = (benefitId: number, contractId: number) => {
        const deleteBenefitData: BenefitPathParams = {
            benefitId,
            contractId,
        };

        this.props.deleteBenefitRequest(deleteBenefitData);
    };

    public render(): JSX.Element {
        return <EditMyBenefitsComponent deleteBenefit={this.deleteBenefit} {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): EditMyBenefitsStateProps => ({
    myBenefits: getMyBenefits(state),
    isFiltered: getFilteringBenefitsStatus(state),
    isUserAdmin: isAdmin(state),
    tenant: isTenant(state),
    filter: getFilterParams(state, PaginationView.MyBenefits),
    pageNumber: getPageNumber(ownProps),
});

export const EditMyBenefitsContainer = connect(mapStateToProps, {
    deleteBenefitRequest,
    getMyBenefitsSuccess,
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
    toggleMultipleEmployeesRoleRequest,
    toggleMultipleInvitePendingExecutiveUsersRoleRequest,
})(EditMyBenefits);
