import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { ArchivedFilterOptions } from 'common/helpers/utils';
import { saveFilters } from 'features/pagination/actions/paginationActions';

import { PaginationView } from '../models/paginationModel';
import { FilterData } from 'common/helpers/url';

type Props = {
    paginatedView: PaginationView;
    path?: string;
    refreshTable: (filters?: FilterData) => void;
};

export const PaginationArchivedFilter = ({ paginatedView, path, refreshTable }: Props) => {
    const dispatch = useDispatch();
    const [archivedFilter, setArchivedFilter] = useState(ArchivedFilterOptions.Unarchived);

    useEffect(() => {
        handleOnArchivedFilterChange(ArchivedFilterOptions.Unarchived);
    }, []);

    const handleOnArchivedFilterChange = (value: ArchivedFilterOptions) => {
        setArchivedFilter(value);

        let archived;
        switch (value) {
            case ArchivedFilterOptions.Archived:
                archived = true;
                break;
            case ArchivedFilterOptions.Unarchived:
                archived = false;
                break;
            case ArchivedFilterOptions.All:
            default:
                break;
        }

        dispatch(
            saveFilters({
                paginatedView,
                values: { archived },
                path,
            }),
        );
        if (refreshTable) {
            refreshTable();
        }
    };

    return (
        <Select
            value={archivedFilter}
            onChange={handleOnArchivedFilterChange}
            allowClear
            dropdownMatchSelectWidth={false}
        >
            {(Object.keys(ArchivedFilterOptions) as (keyof typeof ArchivedFilterOptions)[]).map(
                (name) => (
                    <Select.Option key={name} value={ArchivedFilterOptions[name]}>
                        {name}
                    </Select.Option>
                ),
            )}
        </Select>
    );
};
