import { useEffect, useState } from 'react';
import { Button, Divider, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { DownloadOutlined } from '@ant-design/icons';

import { Checkbox, Form, FullPageSpinner, PaddedSection } from 'common/components';
import { BenefitDetails } from 'common/components/BenefitDetails';
import { isDebug } from 'config/env';
import { notificationText, showNotification } from 'common/helpers/notifications';
import {
    Benefit,
    DeliveryDate,
    EditBenefitRequest,
    FetchedBenefitPriority,
} from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import {
    formatBenefitChangeSuggestionsForApi,
    formatQuestionnaireAnswerForApi,
    getQuestionnaireAnswerInitialFormValues,
} from 'features/benefit/benefitHelpers';
import { QuestionnaireAnswers } from 'features/benefit/components/Questionnaire/QuestionnaireAnswers';
import { colors } from 'theme/variables';
import { QuestionnaireAnswer } from 'features/benefit/models/questionnaireModels';
import { Questionnaire } from 'features/benefit/components/Questionnaire/Questionnaire';
import { isFormValid } from 'common/helpers/formHelpers';
import { UploadMultiFiles } from 'common/components/deprecated/form';
import { evidencePath } from 'common/helpers/utils';
import { FileList } from 'features/check/components/FileList';
import { CustomEmpty } from 'common/components/CustomEmpty';
import { getBenefitPriorities } from 'features/benefit/selectors/benefitSelectors';
import { getUser, getUserRole, isAdmin } from 'features/user/selectors/userSelectors';
import { EditBenefitStatus } from 'features/check/components/EditBenefitStatus';

import { ApprovalStatus } from './ApprovalStatus';
import { GdprTerms } from './GdprTerms';
import { AssignedContractManagers } from './AssignedContractManagers';
import * as uploadSelectors from '../selectors/uploadSelectors';
import * as evidenceActions from '../actions/evidenceActions';
import { clearUploadData } from '../actions/uploadActions';
import {
    BenefitChangeSuggestionsFormItem,
    BenefitChangeSuggestionsFormValues,
} from './BenefitChangeSuggestionsFormItem/BenefitChangeSuggestionsFormItem';
import {
    BenefitChangeSuggestionFormItemLocation,
    BenefitChangeSuggestionFormItemValue,
    Evidence,
    EvidenceApproval,
} from '../models/uploadModels';
import {
    formatDeliveryDatesToSelectOptions,
    getBenefitChangeSuggestionsInitialFormValues,
} from '../helpers';
import { editBenefitRequest } from 'features/benefit/actions';

const SelectLabel = styled.h2`
    color: ${colors.primary};
`;

const DeliveryDateSelect = styled(Select)`
    width: 100%;
    border-radius: 4px;

    && .ant-select-selector {
        height: 42px;
        padding: 4px 11px;
    }
`;

const EvidenceContentWrapper = styled.div`
    width: 100%;
    padding: 1rem;
    border: 1px solid ${colors.antdBorder};
    border-radius: 4px;
    margin-top: 1.5rem;
`;

const ButtonsWrapper = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

const FilesWrapper = styled.div`
    width: 100%;
    padding: 1.5rem;
`;

type Props = {
    contractId: number;
    contract: Contract;
    benefitId: number;
    benefit: Benefit;
    hideSuggestions?: boolean;
};

type BenefitEvidenceFormValues = {
    agreedToGdpr?: boolean;
    questionnaireAnswer?: QuestionnaireAnswer[];
    benefitChangeSuggestions: BenefitChangeSuggestionsFormValues;
    benefitValue?: number;
    benefitLabel?: string;
};

const generateInitialFormValues = (
    benefit: Benefit,
    priorities: FetchedBenefitPriority[],
    evidence?: Evidence,
): BenefitEvidenceFormValues => ({
    agreedToGdpr: evidence?.agreedToGdpr,
    questionnaireAnswer: getQuestionnaireAnswerInitialFormValues(
        benefit.evidenceQuestionnaire,
        evidence?.questionnaireAnswer,
    ),
    benefitChangeSuggestions: getBenefitChangeSuggestionsInitialFormValues(
        benefit.deliveryDates,
        priorities,
        benefit.location,
    ),
    benefitValue: evidence?.benefitChangeSuggestions?.benefitValue,
    benefitLabel: evidence?.benefitChangeSuggestions?.benefitLabel,
});

export const BenefitEvidence = ({
    contractId,
    contract,
    benefitId,
    benefit,
    hideSuggestions,
}: Props) => {
    const [form] = useForm<BenefitEvidenceFormValues>();
    const dispatch = useDispatch();

    const [deliveryDateId, setDeliveryDateId] = useState<number | undefined>();
    const myEvidence = useSelector(uploadSelectors.getMyEvidence);
    const priorities = useSelector(getBenefitPriorities);
    const fetchingStatus = useSelector(uploadSelectors.getFetchingStatus);
    const isUploading = useSelector(uploadSelectors.getUploadingStatus);
    const contractManagerDetails = useSelector(uploadSelectors.getContractManagerDetails);
    const isUserAdmin = useSelector(isAdmin);
    const user = useSelector(getUser);
    useEffect(() => {
        dispatch(clearUploadData());

        const initialDeliveryDateId = (
            benefit.deliveryDates.find((date) => date.needsUserAction) || benefit.deliveryDates[0]
        ).id;

        if (initialDeliveryDateId) {
            setDeliveryDateId(initialDeliveryDateId);
        }
    }, []);

    useEffect(() => {
        if (deliveryDateId) {
            form.resetFields();
            dispatch(
                evidenceActions.getMyEvidenceRequest({
                    contractId,
                    benefitId,
                    deliveryDateId,
                    organisationId: contract.organisation,
                }),
            );
        }
    }, [deliveryDateId]);

    useEffect(() => {
        // form.resetFields();
    }, [myEvidence]);

    const changeDeliveryDate = (newDeliveryDateId: number) => {
        if (contract) {
            setDeliveryDateId(newDeliveryDateId);
            evidenceActions.getMyEvidenceRequest({
                contractId,
                benefitId,
                deliveryDateId: newDeliveryDateId,
                organisationId: contract.organisation,
            });
        }
    };

    const handleSubmit = (submitAction: any, selfAssessment?: boolean) => () => {
        if (!isFormValid(form)) {
            showNotification({ text: notificationText.Error });
            return;
        }
        const assessmentFlag = selfAssessment ?? false;
        dispatch(
            submitAction({
                benefitId,
                contractId: contract.id,
                deliveryDateId,
                evidenceId: myEvidence?.id,
                organisationId: contract.organisation,
                ...form.getFieldsValue(),
                questionnaireAnswer: formatQuestionnaireAnswerForApi(
                    benefit.evidenceQuestionnaire,
                    form.getFieldsValue().questionnaireAnswer,
                ),
                benefitChangeSuggestions: formatBenefitChangeSuggestionsForApi(
                    initialValues.benefitChangeSuggestions,
                    form.getFieldsValue().benefitChangeSuggestions,
                    form.getFieldsValue().benefitValue,
                    form.getFieldsValue().benefitLabel,
                ),
                selfAssessment: assessmentFlag,
            }),
        );
        // ApproveSuggestionsForAdminUser(form.getFieldsValue(), benefit);
        if (selfAssessment) {
            handleApproveEvidence(myEvidence, selfAssessment);
        }
    };

    const handleApproveEvidence = (evidence: EvidenceApproval, selfAssessment?: boolean) => {
        dispatch(
            evidenceActions.approveEvidenceRequest({
                ...evidence,
                selfAssessment,
                evidenceId: evidence.id,
            }),
        );
    };

    const deleteFile = (fileId: number) => {
        if (deliveryDateId) {
            dispatch(
                evidenceActions.deleteEvidenceFileRequest({
                    benefitId,
                    contractId,
                    evidenceId: myEvidence?.id,
                    deliveryDateId,
                    organisationId: contract.organisation,
                    fileId,
                }),
            );
        }
    };

    const initialValues = benefit && generateInitialFormValues(benefit, priorities, myEvidence);
    const isEditBlocked =
        myEvidence?.approved &&
        ![myEvidence?.author, myEvidence?.approvedBy].every(
            (fullName) => fullName === `${user.firstName} ${user.lastName}`,
        );

    return (
        <>
            <PaddedSection grayBg bottomBorder>
                <BenefitDetails
                    key={benefit.id}
                    benefit={benefit}
                    contract={contract}
                    showFinalDeliveryDate={false}
                />
                <AssignedContractManagers contractManagerDetails={contractManagerDetails} />
                {myEvidence && benefit.selfAssessmentAllowed && (
                    <EditBenefitStatus
                        key="editBenefitStatus"
                        benefit={benefit}
                        contract={contract}
                    />
                )}
            </PaddedSection>
            {fetchingStatus === 'idle' || !deliveryDateId ? (
                <FullPageSpinner />
            ) : (
                <PaddedSection>
                    <SelectLabel>Choose Date for Evidence</SelectLabel>
                    <DeliveryDateSelect value={deliveryDateId} onChange={changeDeliveryDate}>
                        {formatDeliveryDatesToSelectOptions(benefit.deliveryDates)}
                    </DeliveryDateSelect>
                    <EvidenceContentWrapper>
                        <Form
                            form={form}
                            initialValues={generateInitialFormValues(
                                benefit,
                                priorities,
                                myEvidence,
                            )}
                            hideSubmitButton
                        >
                            <ApprovalStatus
                                approved={myEvidence?.approved}
                                declined={myEvidence?.declined}
                                approvedAt={myEvidence?.approvedAt}
                                approvedBy={myEvidence?.approvedBy}
                                declinedBy={myEvidence?.declinedBy}
                                declineMessage={myEvidence?.declineMessage}
                                bottomMargin
                            />
                            {isEditBlocked ? (
                                <QuestionnaireAnswers
                                    questions={myEvidence.evidenceQuestionnaire}
                                    answers={myEvidence.questionnaireAnswer}
                                />
                            ) : (
                                <>
                                    {benefit.evidenceQuestionnaire && (
                                        <Questionnaire questions={benefit.evidenceQuestionnaire} />
                                    )}
                                    {!myEvidence?.approved && (
                                        <BenefitChangeSuggestionsFormItem
                                            evidenceDateId={deliveryDateId}
                                            benefitId={benefitId}
                                            contractId={contractId}
                                            benefitSharedUrl={benefit.sharedBenefit}
                                            isUserAdmin={isUserAdmin}
                                        />
                                    )}

                                    <Checkbox name="agreedToGdpr">
                                        <GdprTerms />
                                    </Checkbox>
                                    <ButtonsWrapper>
                                        {benefit.evidenceFileTemplate && (
                                            <Button
                                                icon={<DownloadOutlined />}
                                                href={
                                                    isDebug()
                                                        ? benefit.evidenceFileTemplate
                                                        : `https://${benefit.evidenceFileTemplate}`
                                                }
                                                target="blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download Evidence Template
                                            </Button>
                                        )}
                                        <Button
                                            type="primary"
                                            onClick={handleSubmit(
                                                myEvidence
                                                    ? evidenceActions.updateEvidenceRequest
                                                    : evidenceActions.createEvidenceRequest,
                                            )}
                                            size="large"
                                            disabled={isUploading || !deliveryDateId}
                                            loading={isUploading}
                                        >
                                            {`${myEvidence ? 'Update' : 'Create'} Evidence`}
                                        </Button>
                                        {isUserAdmin && (
                                            <Button
                                                type="primary"
                                                onClick={handleSubmit(
                                                    myEvidence
                                                        ? evidenceActions.updateEvidenceRequest
                                                        : evidenceActions.createEvidenceRequest,
                                                    true,
                                                )}
                                                size="large"
                                                disabled={isUploading || !deliveryDateId}
                                                loading={isUploading}
                                            >
                                                {`${
                                                    myEvidence ? 'Approve' : 'Create & Approve'
                                                } Evidence`}
                                            </Button>
                                        )}
                                        <Button
                                            type="primary"
                                            onClick={handleSubmit(
                                                evidenceActions.submitForApprovalRequest,
                                            )}
                                            size="large"
                                            disabled={
                                                isUploading ||
                                                myEvidence?.approved ||
                                                !myEvidence?.questionnaireAnswer
                                            }
                                        >
                                            Submit for Approval
                                        </Button>
                                    </ButtonsWrapper>
                                </>
                            )}
                        </Form>
                        <Divider />
                        <FilesWrapper>
                            {myEvidence && deliveryDateId && !isEditBlocked ? (
                                <UploadMultiFiles
                                    uploadUrl={`${evidencePath({
                                        organisationId: benefit.organisation,
                                        contractId: benefit.contract,
                                        benefitId: benefit.id,
                                        deliveryDateId,
                                    })}${myEvidence.id}/files/`}
                                    successAction={evidenceActions.uploadEvidenceFileSuccess}
                                    failureAction={evidenceActions.uploadEvidenceFileFailure}
                                />
                            ) : (
                                !myEvidence?.approved && (
                                    <CustomEmpty description="Please create Evidence prior to file upload." />
                                )
                            )}
                            {Boolean(myEvidence?.files?.length) && (
                                <FileList
                                    loading={isUploading}
                                    files={myEvidence?.files}
                                    onDelete={!myEvidence?.approved && deleteFile}
                                />
                            )}
                        </FilesWrapper>
                    </EvidenceContentWrapper>
                </PaddedSection>
            )}
        </>
    );
};
