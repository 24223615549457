import * as React from 'react';
import posthog from 'posthog-js';
import { Link } from 'react-router-dom';
import { startCase, camelCase } from 'lodash';
import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';

import {
    generateColumns,
    addReferenceSuffixToBenfitOutcome,
    getBenefitOutcomeStyle,
    getContractIdFromQuery,
    getStatusLabel,
} from 'common/helpers/utils';
import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { UploadEvidenceContainerProps } from '../containers/UploadEvidenceContainer';
import { UploadBenefit } from '../models/uploadModels';
import { getReadyForEvidenceBenefitsSuccess } from '../actions/evidenceActions';
import { generateUploadBenefitEvidencePath } from '../config/uploadRouting';

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://app.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

export const uploadEvidenceTableColumns = [
    {
        title: 'Benefit',
        key: 'outcome',

        render: (outcome: string, { organisation, contract, id, deliveryDates }: UploadBenefit) => (
            <Link
                style={getBenefitOutcomeStyle(deliveryDates)}
                to={generateUploadBenefitEvidencePath(organisation, contract, id)}
            >
                {outcome}
            </Link>
        ),
    },
    {
        title: 'Benefit Label',
        key: 'label',
        render: (label: string, { organisation, contract, id }: UploadBenefit) => (
            <Link to={generateUploadBenefitEvidencePath(organisation, contract, id)}>{label}</Link>
        ),
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: (quantity: string) => quantity,
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'finalDeliveryDate',
        render: (finalDeliveryDate: string) => finalDeliveryDate,
    },
    {
        title: 'Project',
        key: 'contractTitle',
        render: (contractTitle: string, { contractReferenceNumber }: UploadBenefit) => (
            <span>{`${contractTitle} | ${contractReferenceNumber}`}</span>
        ),
    },
];

type UploadEvidenceProps = Antd3FormProps & UploadEvidenceContainerProps;

class UploadEvidenceForm extends React.Component<UploadEvidenceProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.UploadEvidence,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getReadyForEvidenceBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadEvidence,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadEvidence,
            values: {
                contract: contractId,
            },
        });

    private saveBuyerOrganisationFilter = (tenant: string | undefined) => {
        this.props.saveFilters({
            paginatedView: PaginationView.UploadEvidence,
            values: { tenant },
        });
    };

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByBuyerOrganisation = (buyer: string) => {
        this.saveBuyerOrganisationFilter(buyer);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveBuyerOrganisationAutocomplete = () => {
        this.saveBuyerOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.UploadEvidence) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    private setFiltersFromQueryParams = () => {
        const contractId = getContractIdFromQuery();

        if (contractId) {
            this.searchByContracts(contractId);
        }
    };

    public render(): JSX.Element {
        const { form, filter, readyForEvidenceBenefits, isTenant } = this.props;

        return (
            <PaddedSection header="Submit Evidence (Active Benefits)">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByBuyerOrganisation={this.searchByBuyerOrganisation}
                    onRemoveBuyerOrganisationAutocomplete={
                        this.onRemoveBuyerOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.ReadyForEvidenceBenefits}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    view={PaginationView.UploadEvidence}
                    data={addReferenceSuffixToBenfitOutcome(readyForEvidenceBenefits)}
                    columns={generateColumns(uploadEvidenceTableColumns)}
                    emptyText="No benefits have been assigned to you"
                    paginationSuccessCallback={getReadyForEvidenceBenefitsSuccess}
                    setFiltersFromQueryParams={this.setFiltersFromQueryParams}
                />
            </PaddedSection>
        );
    }
}

export const UploadEvidence = Antd3Form.create({})(UploadEvidenceForm);
