import { BenefitsFilterContainer } from 'common/containers/BenefitsFilterContainer';
import { CustomRouteProps } from 'common/models/navigationModel';

import { BenefitDetailsContainer } from 'features/benefit/containers/BenefitDetailsContainer';
import { BenefitNotesContainer } from 'features/benefit/containers/BenefitNotesContainer';
import { EditBenefitTemplateFormContainer } from 'features/benefit/containers/EditBenefitTemplateFormContainer';
import { ContractBenefitsContainer } from 'features/contract/containers/ContractBenefitsContainer';
import { ContractDetailsContainer } from 'features/contract/containers/ContractDetailsContainer';
import { ContractInvitationsContainer } from 'features/contract/containers/ContractInvitationsContainer';
import { ContractNotesContainer } from 'features/contract/containers/ContractNotesContainer';
import { EditBenefitContainer } from 'features/benefit/containers/EditBenefitContainer';
import { EditOrganisationQuestionnairesContainer } from 'features/organisation/containers/EditOrganisationQuestionnairesContainer';
import { OrganisationDetailsContainer } from 'features/organisation/containers/OrganisationDetailsContainer';
import { PortfolioFiltersTableContainer } from 'features/portfolioFilter/containers/PortfolioFilterTableContainer';
import { EditContractPaths } from 'features/contract/models/contractModels';
import { EditBenefitPaths } from 'features/benefit/models/benefitModels';
import { EditContractNavigation } from 'features/contract/components/EditContractNavigation';
import { EditBenefitNavigation } from 'features/benefit/components/EditBenefitNavigation';

import { EditBenefitContainer as EditAllBenefitContainer } from '../containers/EditBenefitContainer';
import { EditBenefitsFilterContainer } from '../containers/EditBenefitsFilterContainer';
import { EditBenefitTemplateContainer } from '../containers/EditBenefitTemplateContainer';
import { EditContractContainer } from '../containers/EditContractContainer';
import { EditContractFilterContainer } from '../containers/EditContractFilterContainer';
import { EditContractFormContainer } from '../containers/EditContractFormContainer';
import { EditEmployeeContainer } from '../containers/EditEmployeeContainer';
import { EditFilterContainer } from '../containers/EditFilterContainer';
import { EditMyBenefitsContainer } from '../containers/EditMyBenefitsContainer';
import { EditMyContractsContainer } from '../containers/EditMyContractsContainer';
import { EditOrganisationContainer } from '../containers/EditOrganisationsContainer';
import { SupplierEditUsersContainer } from '../containers/SupplierEditUsersContainer';
import { EditPageTitles, EditPaths, SupplierEditPaths } from '../models/editModel';
import { SubscriptionContainer } from 'features/user/containers/SubscriptionContainer';

const menuLinks = [
    {
        key: EditPaths.MyContracts,
        label: EditPageTitles.Contracts,
        additionalActiveKeys: [
            EditPaths.Contracts,
            EditContractPaths.ContractDetails,
            EditContractPaths.ContractForm,
            EditContractPaths.Benefits,
            EditContractPaths.Invitations,
            EditContractPaths.ExpiredInvitations,
            EditContractPaths.ContractFilter,
            EditContractPaths.Notes,
        ],
    },
    {
        key: EditPaths.MyBenefits,
        label: EditPageTitles.Benefits,
        additionalActiveKeys: [
            EditPaths.Benefits,
            EditBenefitPaths.Details,
            EditBenefitPaths.BenefitForm,
            EditBenefitPaths.Notes,
            EditBenefitPaths.Filter,
        ],
    },
];

const adminMenuLinks = [
    ...menuLinks,
    { key: EditPaths.BenefitTemplates, label: EditPageTitles.BenefitTemplates },
    { key: EditPaths.Questionnaires, label: EditPageTitles.Questionnaires },
    { key: EditPaths.Organisations, label: EditPageTitles.Organisations },
    { key: EditPaths.Employees, label: EditPageTitles.Employees },
    {
        key: EditPaths.FiltersContractCategories,
        label: EditPageTitles.Filters,
        additionalActiveKeys: [EditContractPaths.Filters],
    },
    { key: EditPaths.FiltersPortfolio, label: EditPageTitles.PortfolioFilters },
    { key: EditPaths.Subscriptions, label: EditPageTitles.Subscriptions },
];

const editBaseRoutes: CustomRouteProps[] = [
    {
        path: `${EditPaths.Contracts}/:pageNumber?`,
        component: EditContractContainer,
        exact: true,
    },
    {
        path: `${EditPaths.MyContracts}/:pageNumber?`,
        component: EditMyContractsContainer,
        exact: true,
    },
    {
        path: `${EditPaths.Benefits}/:pageNumber?`,
        component: EditAllBenefitContainer,
        exact: true,
    },
    {
        path: `${EditPaths.MyBenefits}/:pageNumber?`,
        component: EditMyBenefitsContainer,
        exact: true,
    },

    {
        path: `${EditPaths.Employees}/:pageNumber?`,
        component: EditEmployeeContainer,
        exact: true,
    },
    {
        path: `${EditPaths.Organisations}/:pageNumber?`,
        component: EditOrganisationContainer,
        exact: true,
    },
    {
        path: `${EditPaths.Organisations}/:id/details`,
        component: OrganisationDetailsContainer,
    },
    {
        path: `${EditPaths.MyBenefits}${EditBenefitPaths.Filter}/:filterId`,
        component: BenefitsFilterContainer,
    },
    {
        path: EditPaths.BenefitTemplates,
        component: EditBenefitTemplateContainer,
        exact: true,
    },
    {
        path: `${EditPaths.BenefitTemplates}/:benefitId`,
        component: EditBenefitTemplateFormContainer,
    },
    {
        path: EditPaths.Questionnaires,
        component: EditOrganisationQuestionnairesContainer,
    },
    {
        path: EditPaths.ContractFilter,
        component: EditContractFilterContainer,
    },
    {
        path: `${EditPaths.FiltersPortfolio}/:pageNumber?`,
        component: PortfolioFiltersTableContainer,
    },
    { path: EditPaths.Subscriptions, component: SubscriptionContainer },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
}));

const supplierEditRoutes = [
    {
        path: `${SupplierEditPaths.Users}/:pageNumber?`,
        component: SupplierEditUsersContainer,
    },
];

const editContractRoutes = [
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.ContractForm}`,
        component: EditContractFormContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.ContractDetails}`,
        component: ContractDetailsContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.Benefits}/:pageNumber?`,
        component: ContractBenefitsContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.BenefitsFilter}`,
        component: EditBenefitsFilterContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.Invitations}/:pageNumber?`,
        component: ContractInvitationsContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.ExpiredInvitations}/:pageNumber?`,
        component: ContractInvitationsContainer,
    },
    {
        path: `${EditPaths.Contract}/:id${EditContractPaths.Notes}`,
        component: ContractNotesContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
    hideNavigation: true,
    SubNavigation: EditContractNavigation,
}));

const editBenefitRoutes = [
    {
        path: `${EditPaths.Contract}/:id/benefits/:benefitId${EditBenefitPaths.Details}`,
        component: BenefitDetailsContainer,
        exact: true,
    },
    {
        path: `${EditPaths.Contract}/:id/benefits/:benefitId${EditBenefitPaths.BenefitForm}`,
        component: EditBenefitContainer,
    },
    {
        path: `${EditPaths.Contract}/:id/benefits/:benefitId${EditBenefitPaths.Notes}`,
        component: BenefitNotesContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
    hideNavigation: true,
    SubNavigation: EditBenefitNavigation,
}));

const editFiltersRoutes = [
    {
        path: `${EditPaths.FiltersContractCategories}/:pageNumber?`,
        component: EditFilterContainer,
    },
    {
        path: `${EditPaths.FiltersContractPriorities}/:pageNumber?`,
        component: EditFilterContainer,
    },
    {
        path: `${EditPaths.FiltersBenefitCategories}/:pageNumber?`,
        component: EditFilterContainer,
    },
    {
        path: `${EditPaths.FiltersBenefitPriorities}/:pageNumber?`,
        component: EditFilterContainer,
    },
    {
        path: `${EditPaths.FiltersDepartments}/:pageNumber?`,
        component: EditFilterContainer,
    },
    {
        path: `${EditPaths.FiltersLocations}/:pageNumber?`,
        component: EditFilterContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
}));

export const editRoutes = [
    ...editBaseRoutes,
    ...supplierEditRoutes,
    ...editContractRoutes,
    ...editBenefitRoutes,
    ...editFiltersRoutes,
];
