import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { iconSizes, colors } from 'theme/variables';
import { IdValueObject } from 'common/models/defaultsModel';
import { InputLabelWithTooltip } from 'common/components/form/components/InputLabelWithTooltip';
import { Antd3Icon } from '../antd3';
import { UpstreamsUrlsInput } from './UpstreamsUrlsInput';

const DynamicFieldSetWrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldSetElementWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 7px;
`;

export const FieldSetIcon = styled(Antd3Icon)`
    color: ${colors.red};
    display: flex;
    align-items: center;
    margin: 0 20px 20px 20px;
    font-size: ${iconSizes.medium};
`;

export const FieldSetButton = styled(Button)`
    margin: 5px 5px 5px 0;
    display: block;
`;

interface DynamicFieldSetProps {
    fieldSetId: string;
    fieldType: DynamicFieldSetFieldType;
    initialFields?: DynamicUpstreamClientFieldSetField[] | null;
    label: string;
    values?: string[];
    placeholder?: string;
    buttonLabel: string;
    tooltip?: string;
    orderByObjectsIndexes?: boolean;
    onFieldsChanged?: (value: Record<string, DynamicUpstreamClientFieldSetField>) => void;
}

export type DynamicFieldSetItemProps = {
    fullId: string;
};

export type DynamicUpstreamClientFieldSetField = IdValueObject;
export type DynamicFieldSetFieldType = 'upStreamClientsUrls';

export const DynamicUpstreamClientFieldSet: React.FC<DynamicFieldSetProps> = (props) => {
    const [fields, setFields] = useState<Record<string, DynamicUpstreamClientFieldSetField>>(
        props.initialFields?.reduce(
            (acc, field) => ({
                ...acc,
                [uuid()]: field,
            }),
            {},
        ) || ({} as any),
    );

    const [inputValues, setInputValues] = useState<Record<string, Record<string, string>>>({});

    useEffect(() => {
        if (props.onFieldsChanged) {
            props.onFieldsChanged(fields);
        }
    }, [fields]);

    const updateFormItemInput = (fieldId: string, inputName: string, value: string): void => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [fieldId]: {
                ...prevInputValues[fieldId],
                [inputName]: value,
            },
        }));
        setFields((prevFields) => ({
            ...prevFields,
            [fieldId]: {
                ...prevFields[fieldId],
                [inputName]: value,
            },
        }));
    };

    const addFormItem = () => {
        const newId = uuid();
        setFields((prevFields) => ({
            ...prevFields,
            [newId]: {
                id: newId,
                value: '',
            },
        }));
    };

    const removeFormItem = (id: string) => {
        const updatedFields = { ...fields };
        delete updatedFields[id];
        setFields(updatedFields);

        const updatedInputValues = { ...inputValues };
        delete updatedInputValues[id];
        setInputValues(updatedInputValues);
    };
    useEffect(() => {
        if (props.values !== undefined && props.values.length > 0) {
            props.values.forEach((value) => {
                if (value != undefined) {
                    const newFieldId = uuid();
                    setFields((prevFields) => ({
                        ...prevFields,
                        [newFieldId]: {
                            id: newFieldId,
                            title: value,
                            value: '',
                        },
                    }));
                }
            });
        }
    }, [props.values]);

    const getFieldComponent = (id: string): JSX.Element => {
        const field = fields[id];
        const fieldId = field?.id || id;

        const fieldSetProps = {
            id: fieldId,
            fullId: `${props.fieldSetId}[${fieldId}]`,
            isTemporaryId: !field?.id,
            placeholder: `${props.placeholder}`,
            onInputChange: updateFormItemInput,
        };
        return <UpstreamsUrlsInput {...field} {...fieldSetProps} />;
    };

    return (
        <DynamicFieldSetWrapper>
            <InputLabelWithTooltip label={props.label} tooltip={props.tooltip} />
            {Object.keys(fields).map((fieldId) => (
                <FieldSetElementWrapper key={fieldId}>
                    {getFieldComponent(fieldId)}
                    <FieldSetIcon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => removeFormItem(fieldId)}
                    />
                </FieldSetElementWrapper>
            ))}
            <FieldSetButton onClick={addFormItem}>
                <Antd3Icon type="plus" />
                {`Add ${props.buttonLabel}`}
            </FieldSetButton>
        </DynamicFieldSetWrapper>
    );
};
