import { ofType, combineEpics } from 'redux-observable';
import { switchMap, pluck, tap, ignoreElements } from 'rxjs/operators';
import posthog from 'posthog-js';
import { AppEpic } from 'common/epics/appEpic';
import { CreateAccountCredentials } from 'features/user/models/userModel';
import { Organisation } from 'features/organisation/models/organisationModels';
import { POSTHOG_COHORT_KEY, POSTHOG_COHORT_API_URL, SENTRY_ENVIRONMENT } from 'config/env';
import * as actions from '../actions/authActions';
import { AuthService } from '../services/authService';
import { OrganisationService } from '../../organisation/services/organisationService';
import { Credentials } from '../models/credentialsModel';
import { UserService } from '../../user/services/userService';
import { of } from 'rxjs/observable/of';

export const logInEpicFactory = (
    authService: AuthService,
    userService: UserService,
    organisationService: OrganisationService,
): AppEpic => {
    const activateAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ACTIVATE_ACCOUNT_REQUESTED),
            pluck('payload'),
            switchMap((credentials: CreateAccountCredentials) =>
                authService
                    .activateUser(credentials)
                    .then(actions.activateAccountSuccess)
                    .catch(actions.activateAccountFailure),
            ),
        );

    const activateExecutiveAdminAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED),
            pluck('payload'),
            switchMap((credentials: CreateAccountCredentials) =>
                authService
                    .activateNewExecutiveAdmin(credentials)
                    .then(actions.activateExecutiveAdminAccountSuccess)
                    .catch(actions.activateExecutiveAdminAccountFailure),
            ),
        );

    const activateExistingExecutiveAdminAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_EXISTING_EXECUTIVE_SUCCEED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .activateExistingExecutiveAdmin(token)
                    .then(actions.activateExistingExecutiveAdminSuccess)
                    .catch(actions.activateExistingExecutiveAdminFailure),
            ),
        );

    const logInEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_IN_REQUESTED),
            pluck('payload'),
            switchMap((credentials: Credentials) =>
                authService
                    .logIn(credentials)
                    .then(actions.logInSuccess)
                    .catch(actions.logInFailure),
            ),
        );

    const handleCohort = async (org: Organisation): Promise<void> => {
        await fetch(POSTHOG_COHORT_API_URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${POSTHOG_COHORT_KEY}`,
            },
        })
            .then((response) => response.json())
            .catch((err) => {
                posthog.capture('Handle Cohort failed_1', { error: err });
            })
            .then((value) => {
                if (!value.results.map(({ name }: { name: any }) => name).includes(org.name)) {
                    const content = {
                        id: org.id,
                        name: org.name,
                        groups: [
                            {
                                properties: [
                                    {
                                        key: 'organisation',
                                        value: [org.name],
                                        operator: 'exact',
                                        type: 'person',
                                    },
                                ],
                            },
                        ],
                        is_static: false,
                    };

                    fetch('POSTHOG_COHORT_API_URL', {
                        method: 'POST',
                        body: JSON.stringify(content),
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${POSTHOG_COHORT_KEY}`,
                        },
                    }).catch((err) => {
                        posthog.capture('Handle Cohort failed_2', { error: err });
                    });
                }
            })
            .catch((err) => posthog.capture('Handle Cohort failed_3', { error: err }));
    };

    const identifyEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_IN_SUCCEED),
            switchMap(() => of(actions.logInComplete())),
        );

    const logOutEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_OUT_REQUESTED),
            tap(() => localStorage.clear()),
            ignoreElements(),
        );

    const verifyNewAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_NEW_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyNewUserToken(token)
                    .then(actions.verifyNewEmployeeSuccess)
                    .catch(actions.verifyNewEmployeeFailure),
            ),
        );

    const verifyNewExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_NEW_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyNewExecutiveToken(token)
                    .then(actions.verifyNewExecutiveSuccess)
                    .catch(actions.verifyNewExecutiveFailure),
            ),
        );

    const verifyExistingExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_EXISTING_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyExistingExecutiveToken(token)
                    .then(() => actions.verifyExistingExecutiveSuccess(token))
                    .catch(actions.verifyExistingExecutiveFailure),
            ),
        );

    return combineEpics(
        activateAccountEpic,
        activateExecutiveAdminAccountEpic,
        activateExistingExecutiveAdminAccountEpic,
        logInEpic,
        identifyEpic,
        logOutEpic,
        verifyNewAccountEpic,
        verifyNewExecutiveEpic,
        verifyExistingExecutiveEpic,
    );
};
