import * as React from 'react';

import { SubmitButton } from 'common/components';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { EmployeeRole } from 'features/edit/components/EmployeeRole';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { notArchivedFilterParam } from 'config/config';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';

import { InviteByEmail } from '../models/inviteModel';
import { PendingInvitationsList } from './PendingInvitationsList';

export interface InviteEmployeeProps {
    isFetching: boolean;
    inviteEmployeeRequest(employee: InviteByEmail): void;
    redirectToUrl(path: string): void;
}

type Props = InviteEmployeeProps & Antd3FormProps;

class InviteEmployeeComponent extends React.Component<Props> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { email, ...values }) =>
            error
                ? showNotification({ text: notificationText.Error })
                : this.props.inviteEmployeeRequest({ email: email.toLowerCase(), ...values }),
        );
    };

    public render(): JSX.Element {
        const { form, isFetching } = this.props;

        return (
            <>
                <StyledAntd3Form onSubmit={this.handleSubmit} header="Invite Employee" bigHeader>
                    <ResetButton form={form} />
                    <Input form={form} id="firstName" placeholder="First Name" required />
                    <Input form={form} id="lastName" placeholder="Last Name" required />
                    <Input
                        form={form}
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        required={true}
                    />
                    <PhoneInput
                        form={form}
                        id="telephone"
                        placeholder="Telephone Number"
                        required={true}
                    />
                    <AutocompleteSelect
                        autocompleteField={AutocompleteField.Department}
                        form={form}
                        id="department"
                        placeholder="Department"
                        notFoundContent="No departments found"
                        searchParams={notArchivedFilterParam}
                        required
                    />
                    <EmployeeRole form={form} />
                    <SubmitButton isFetching={isFetching} value="Invite Employee" />
                </StyledAntd3Form>
                <PendingInvitationsList {...this.props} />
            </>
        );
    }
}

export const InviteEmployee = Antd3Form.create({})(InviteEmployeeComponent);
