import React from 'react';
import styled from 'styled-components';
import { Antd3Input, Antd3Form, Antd3InputProps } from '../antd3';

const InterimDateWrapper = styled.div`
    width: 100%;
`;

type Props = {
    id: string;
    value: string;
    onInputChange?: (fieldId: string, inputName: string, value: string) => void;
    placeholder?: string;
};

export const UpstreamsUrlsInput: React.FC<Props> = ({ id, title, onInputChange, placeholder }) => {
    const handleInputUpdate = (fieldName: string, value: string) => {
        if (onInputChange) {
            onInputChange(id, fieldName, value);
        }
    };

    return (
        <InterimDateWrapper>
            <Antd3Form.Item required={false}>
                <Antd3Input
                    id={title}
                    defaultValue={title}
                    size="large"
                    placeholder={placeholder}
                    onChange={(event) => handleInputUpdate('title', event.target.value)}
                />
            </Antd3Form.Item>
        </InterimDateWrapper>
    );
};
