import { createSelector } from 'reselect';

import { AppState } from 'common/appState';
import { Profile, UserAccountAgreements } from 'features/user/models/userModel';
import { extractId } from 'common/selectors/utilitySelectors';

export const employees = (state: AppState) => state.user.employees;
export const getOrganisations = (state: AppState) => state.user.organisations;
export const getActiveOrganisation = (state: AppState) => state.user.activeOrganisation;
export const getProfile = (state: AppState) => state.user;
export const getUser = (state: AppState) => state.user.user;
export const getUserId = (state: AppState) => state.user.user.id;
export const getSubscriptionItems = (state: AppState) => state.user.subscriptionTiers;

export const getOrganisationActiveSubscriptionId = (state: AppState) =>
    state.user.activeOrganisation
        ? state.user?.user?.organisationData?.activeSubscription
        : undefined;

export const getOrganisationPendingSubscriptionId = (state: AppState) =>
    state.user.activeOrganisation
        ? state.user?.user?.organisationData?.pendingSubscription
        : undefined;

export const getOrganisationSubscriptions = (state: AppState) =>
    state.user.activeOrganisation?.organisation.subscriptions;

export const getOrganisationCurrentSubscription = createSelector(
    getOrganisationActiveSubscriptionId,
    getOrganisationPendingSubscriptionId,
    getOrganisationSubscriptions,
    (activeId, pendingId, subscriptions) =>
        subscriptions?.find((item) => (item.id === activeId ? activeId : pendingId)),
);

export const getInvoiceData = (state: AppState) => state.user.invoiceData;

export const getSubscriptionInvoices = (state: AppState) => state.user.subscriptionInvoices;

export const getXeroId = (state: AppState) => state.user.invoiceData?.xeroId;

export const getIsUserLoggedIn = (state: AppState) => state.user.user?.id !== -1;

export const getUserOrganisationId = (state: AppState) =>
    state.user.activeOrganisation
        ? state.user.activeOrganisation.organisation.id
        : state.user.organisation;

export const getUserActiveOrganisation = createSelector(
    getUserOrganisationId,
    getOrganisations,
    (activeOrganisationId, organisations) =>
        organisations.find((organisation) => organisation.organisation.id === activeOrganisationId)
            ?.organisation,
);

export const isLoadingRole = (state: AppState) => state.user.isLoadingRole;
export const getUserRole = (state: AppState) => state.user.role;
export const isAdmin = (state: AppState) =>
    state.user.activeOrganisation
        ? state.user.activeOrganisation.isAdministrator
        : state.user.isAdministrator;
export const isTenant = (state: AppState) =>
    state.user.activeOrganisation
        ? state.user.activeOrganisation.organisation.isTenant
        : state.user.isTenant;

export const getFetchingUserStatus = (state: AppState) => state.user.isFetching;
export const getVerifyingTokenStatus = (state: AppState) => state.user.isVerifyingToken;
export const getPasswordTokenStatus = (state: AppState) => state.user.passwordTokenVerified;

const filterArchived = <T extends Profile>(data: T[]) => data.filter(({ archived }) => !archived);

export const getEmployee = (state: AppState, props: { id: string }) =>
    state.user.employees.find(({ id }) => id === parseInt(props.id, 10));

export const getEmployeeById = (allEmployees: Profile[], id: number) =>
    allEmployees.find((employee) => employee.id === id);

export const getEmployees = createSelector(employees, filterArchived);
export const getAllEmployees = createSelector(employees, (allEmployees) => allEmployees);

export const getProfileById = createSelector(employees, extractId, getEmployeeById);

export const getEmployeeFormVisibility = (state: AppState) => state.user.formVisible;

export const getUserFunctionalEmailsConsent = (state: AppState) =>
    state.user[UserAccountAgreements.FunctionalEmails];
export const getUserNonFunctionalEmailsConsent = (state: AppState) =>
    state.user[UserAccountAgreements.NonFunctionalEmails];
export const getUserTermsOfServiceEmailsConsent = (state: AppState) =>
    state.user[UserAccountAgreements.TermsOfService];
export const getCurrentUserOrganisation = (state: AppState) => state.user.user.organisationData;
