import * as React from 'react';
import { Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';

import { notArchivedFilterParam } from 'config/config';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns, getStatusLabel } from 'common/helpers/utils';
import { SubmitButton } from 'common/components';
import { Contract, EditContractPaths } from 'features/contract/models/contractModels';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { getContractsSuccess } from 'features/contract/actions/contractActions';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AssignActions } from 'features/benefit/models/benefitModels';
import { showNotification, notificationText } from 'common/helpers/notifications';
import {
    Antd3Form,
    Antd3Icon,
    Antd3FormProps,
    StyledAntd3Form,
} from 'common/components/deprecated/antd3';

import { EditPaths } from '../models/editModel';
import { EditContractProps as EditContractContainerProps } from '../containers/EditContractContainer';
import { EditContractsTableFiltersBar } from './EditContractsTableFiltersBar';

interface EditContractsState {
    assign: AssignActions;
    selectedContractIds: string[];
}

const editContractColumns = [
    {
        title: 'Project',
        key: 'title',
        render: (title: string, { id, referenceNumber }: Contract) => (
            <Link to={`${EditPaths.Contract}/${id}${EditContractPaths.ContractDetails}`}>
                {`${title} | ${referenceNumber}`}
            </Link>
        ),
    },
    {
        title: 'Department',
        key: 'departmentName',
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'endDate',
        render: (_endDate: string) => _endDate,
    },
    {
        title: '',
        key: 'id',
        render: (_: string, { id }: Contract) => (
            <Link to={`${EditPaths.Contract}/${id}${EditContractPaths.ContractForm}`}>
                <Button size="small" icon={<Antd3Icon type="edit" />}>
                    Edit
                </Button>
            </Link>
        ),
        width: 70,
    },
];

type EditContractProps = EditContractContainerProps & Antd3FormProps;

class EditContractForm extends React.Component<EditContractProps, EditContractsState> {
    public state: EditContractsState = {
        assign: AssignActions.Assign,
        selectedContractIds: [],
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { userIds }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
            return this.props.toggleMultipleEmployeesContractRoleRequest({
                contractIds: this.state.selectedContractIds.map((id) => parseInt(id, 10)),
                userIds: userIds.map((id: string) => parseInt(id, 10)),
                action: this.state.assign,
            });
        });
    };

    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.Contracts,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getContractsSuccess,
        });

    private saveContractFilters = (contractTitle: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.Contracts,
            values: {
                title: contractTitle,
            },
        });

    private searchByContracts = (contractTitle: string) =>
        this.saveContractFilters(contractTitle) && this.refreshTable();

    private onSelectChange = (selectedContractIds: string[]) =>
        this.setState({ selectedContractIds });

    private handleAssignChange = (event: RadioChangeEvent) =>
        this.setState({ assign: event.target.value });

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.Contracts) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            title: '',
        });

    public render(): JSX.Element {
        const { contracts, isUserAdmin, form } = this.props;
        const isContractUnselected = !this.state.selectedContractIds.length;
        const userIds = form.getFieldValue('userIds');
        const isEmployeeUnselected = !(userIds && userIds.length);

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Projects" bigHeader>
                <EditContractsTableFiltersBar
                    searchByContractTitle={this.searchByContracts}
                    onRemoveContractTitleAutocomplete={this.onRemoveContractAutocomplete}
                    clearFilters={this.clearFilters}
                    form={this.props.form}
                    filter={this.props.filter}
                />
                <Antd3Form.Item>
                    <PaginatedTable
                        view={PaginationView.Contracts}
                        columns={generateColumns(editContractColumns)}
                        data={contracts}
                        paginationSuccessCallback={getContractsSuccess}
                        emptyText="No projects found"
                        pageNumber={this.props.pageNumber}
                        pathUrl={EditPaths.Contracts}
                        rowSelection={
                            isUserAdmin
                                ? {
                                      selectedRowKeys: this.state.selectedContractIds,
                                      onChange: this.onSelectChange,
                                  }
                                : undefined
                        }
                        withPaginationHistory
                    />
                </Antd3Form.Item>

                {isUserAdmin && (
                    <>
                        <AutocompleteSelect
                            label="Select employees"
                            id="userIds"
                            form={form}
                            autocompleteField={AutocompleteField.Employee}
                            notFoundContent="No employees found"
                            multiple
                            required
                            requiredMessage="Please choose at least one employee"
                            searchParams={notArchivedFilterParam}
                        />
                        <Antd3Form.Item>
                            <Radio.Group
                                name="assign"
                                defaultValue={AssignActions.Assign}
                                onChange={this.handleAssignChange}
                            >
                                <Radio value={AssignActions.Assign}>Assign</Radio>
                                <Radio value={AssignActions.Revoke}>Unassign</Radio>
                            </Radio.Group>
                        </Antd3Form.Item>
                        <SubmitButton
                            value={
                                this.state.assign === AssignActions.Assign ? 'Assign' : 'Unassign'
                            }
                            disabled={isContractUnselected || isEmployeeUnselected}
                        />
                    </>
                )}
            </StyledAntd3Form>
        );
    }
}

export const EditContract = Antd3Form.create({})(EditContractForm);
