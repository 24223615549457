import { CheckBenefit } from 'features/check/models/checkModel';
import {
    ContractBenefit,
    Benefit,
    BenefitTemplateWithRag,
    BenefitPriorityDashboardItem,
    BenefitTemplate,
    BenefitCategory,
} from 'features/benefit/models/benefitModels';
import { UploadBenefit } from 'features/upload/models/uploadModels';
import { Organisation } from 'features/organisation/models/organisationModels';
import {
    Contract,
    Filters as ContractFilters,
    RoleInvitation,
    ContractWithRAG,
    ContractPriorityDashboardItem,
} from 'features/contract/models/contractModels';
import { AppAction } from 'common/actions/appAction';
import { FilterData } from 'common/helpers/url';
import { Invoice, Profile, User } from 'features/user/models/userModel';
import { EmployeeInvitation, OrganisationInvitation } from 'features/invite/models/inviteModel';

export type PaginationResources =
    | UploadBenefit
    | CheckBenefit
    | Contract
    | ContractWithRAG
    | ContractPriorityDashboardItem
    | BenefitPriorityDashboardItem
    | Benefit
    | BenefitTemplate
    | ContractBenefit
    | BenefitTemplateWithRag
    | Organisation
    | Profile
    | ContractFilters
    | EmployeeInvitation
    | OrganisationInvitation
    | RoleInvitation
    | Invoice;

export enum PaginationView {
    UploadEvidence,
    UploadPendingEvidence,
    ApprovedUploadEvidence,
    UploadScore,
    ApprovedUploadScores,
    CheckEvidence,
    CheckPendingEvidence,
    CheckScores,
    ApprovedCheckEvidence,
    ApprovedCheckScores,
    DownloadContracts,
    DownloadBenefits,
    BenefitTemplates,
    Contracts,
    Benefits,
    MyContracts,
    MyBenefits,
    ContractBenefits,
    AddOrganisation,
    EditOrganisations,
    EditEmployees,
    ContractCategories,
    Departments,
    Locations,
    BenefitCategories,
    AssignEmployees,
    OrganisationEmployees,
    SupplierEditContract,
    EmployeePendingInvitations,
    EmployeeExpiredInvitations,
    BenefitRolePendingInvitations,
    BenefitRoleExpiredInvitations,
    ExistingOrganisationPendingInvitations,
    NewOrganisationPendingInvitations,
    ContractPriorities,
    BenefitPriorities,
    ContractRAGDashboard,
    BenefitTemplateRAGDashboard,
    ContractPrioritiesDashboard,
    BenefitPrioritiesDashboard,
    SubscriptionInvoices,
}

export type Row = PaginationResources;

export interface Column<T = Row> {
    title: string | JSX.Element;
    key: string;
    render?(data: T, title?: string): JSX.Element;
    width?: string | number;
    fixed?: boolean | 'left' | 'right';
}

export interface PaginationQueryParams {
    limit: number;
    offset: number;
}

export interface PaginationSuccessCallback {
    paginationSuccessCallback: (data: PaginationResources[]) => AppAction;
}

export interface PaginationParams {
    total?: number;
    current: number;
    pageSize?: number;
}

export interface PaginationRequest extends PaginationSuccessCallback {
    pagination: PaginationParams;
    view: PaginationView;
    requestParams?: RequestParams;
    filters?: FilterData;
}

interface PaginationResults {
    results: PaginationResources[];
}

export interface PaginationResponse extends PaginationResults {
    count: number;
    next: string;
    previous: string;
}

export type PaginationSuccess = PaginationRequest & PaginationResponse;

export interface PaginationFailure extends PaginationRequest {
    error: string;
}

export interface RequestParams {
    contractId?: number;
    organisationId?: number;
}

export interface Filters {
    [key: string]: string | number | boolean | undefined | PaginationParams;
}

export enum AutocompleteField {
    Location,
    Department,
    BenefitCategory,
    ContractCategory,
    ContractPriorities,
    BenefitPriorities,
    ContractPortfolioFilter,

    ContractManager,
    Assessor,

    SupplierOrganisation,
    ContractSupplierOrganisation,
    DeliveryPartnerOrganisation,

    TenantOrganisation,
    ExecutiveOrganisation,

    Employee,
    ExecutiveSupplierUser,
    ExecutiveContractSupplierUser,
    ExecutiveDeliveryPartnerUser,
    BenefitRoleExecutives,
    ExecutiveAdminSearchContractsAssignBenefit,
    BenefitRoleInvitedExecutives,

    MassAssignBenefitRoleExecutives,
    MassAssignBenefitRoleInvitedExecutives,

    Contract,
    ContractExecutive,
    BenefitTemplate,
    Benefit,
    ContractBenefits,

    MyContract,
    MyBenefit,

    ReadyForEvidenceBenefits,
    ReadyForPendingEvidenceBenefits,
    ApprovedUploadEvidences,
    ScorableBenefits,
    ApprovedUploadScores,

    CheckEvidence,
    CheckPendingEvidence,
    CheckScores,
    ApprovedCheckEvidence,
    ApprovedCheckScores,

    DownloadBenefits,
    DownloadContracts,
}

export interface AutocompleteProperties {
    name?: string;
    categoryName?: string;
    category?: number;
    title?: string;
    outcome?: string;
    fullname?: string;
    referenceNumber?: string;
    organisation?: number;
    organisationObject?: Organisation;
    user?: User;
    contract?: number;
    rootNodes?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    archived?: boolean;
    userId?: number;
}

export interface AutocompleteData extends AutocompleteProperties {
    id: number;
    autocompleteField?: AutocompleteField;
}

export interface Field {
    autocompleteField: AutocompleteField;
}

export interface AutocompleteRequest extends Field {
    searchTerm: string;
    searchParams?: Filters;
    requestParams?: RequestParams;
}

export interface AutocompleteResponse {
    results: AutocompleteData[];
}

export type AutocompleteSuccess = Field & AutocompleteResponse;

export interface AutocompleteFailed extends Field {
    message: string;
}

export interface InitialValueDataRequest extends Field {
    id: number;
}

export interface InitialValueDataSuccess extends Field {
    initialValueData: AutocompleteData;
}

export interface InitialValueDataFailed extends Field {
    message: string;
}
