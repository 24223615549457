import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';

import {
    BenefitChangeSuggestionsFormItemDeliveryDate,
    BenefitChangeSuggestionsDeliveryDate,
    BenefitChangeSuggestionsFormItemBenefitPriority,
    BenefitChangeSuggestionFormItemLocation,
    BenefitChangeSuggestionFormItemValue,
} from 'features/upload/models/uploadModels';
import { AutocompleteSelect } from 'common/components';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { notArchivedFilterParam } from 'config/config';

import { BenefitPrioritySuggestionCard } from './components/BenefitPrioritySuggestionCard';
import { DeliveryDateSuggestionCard } from './components/DeliveryDateSuggestionCard';
import { BenefitLocationSuggestionCard } from './components/BenefitLocationSuggestionCard';
import { BenefitValueSuggestionCard } from './components/BenefitValueSuggestionCard';
import { BenefitLabelSuggestionCard } from './components/BenefitLabelSuggestionCard';

const DELIVERY_DATES_FORM_FIELD_NAME = ['benefitChangeSuggestions', 'deliveryDates'];
const PRIORITIES_FORM_FIELD_NAME = ['benefitChangeSuggestions', 'benefitPriorities'];
const LOCATION_FORM_FIELD_NAME = ['benefitChangeSuggestions', 'benefitLocation'];
const VALUE_FORM_FIELD_NAME = 'benefitValue';
const LABEL_FORM_FIELD_NAME = 'benefitLabel';
export type BenefitChangeSuggestionsFormValues = {
    deliveryDates: BenefitChangeSuggestionsFormItemDeliveryDate[];
    benefitPriorities?: BenefitChangeSuggestionsFormItemBenefitPriority[];
    benefitLocation?: BenefitChangeSuggestionFormItemLocation;
    benefitValue?: number;
    benefitLabel?: string;
};

type Props = {
    evidenceDateId: number;
    contractId: number;
    benefitId: number;
    benefitSharedUrl: number;
    isUserAdmin: boolean;
    Priorities: any;
};

export const BenefitChangeSuggestionsFormItem = ({
    evidenceDateId,
    contractId,
    benefitId,
    benefitSharedUrl,
    isUserAdmin,
    Priorities,
}: Props) => {
    const benefitPrioritiesSuggestions:
        | BenefitChangeSuggestionsFormItemBenefitPriority[]
        | undefined = Form.useWatch(['benefitChangeSuggestions', 'benefitPriorities']);
    const form = Form.useFormInstance();

    const { organisationId } = useParams<{ organisationId: string }>();

    const changeBenefitLocation = (location: number) => {
        const benefitLocation = form.getFieldValue(
            LOCATION_FORM_FIELD_NAME,
        ) as BenefitChangeSuggestionFormItemLocation;

        benefitLocation.location
            ? (benefitLocation.action = 'update')
            : (benefitLocation.action = 'create');
        benefitLocation.location = location;

        form.setFieldsValue({
            LOCATION_FORM_FIELD_NAME: benefitLocation,
        });
    };

    const removeBenefitLocation = () => {
        const benefitLocation = form.getFieldValue(
            LOCATION_FORM_FIELD_NAME,
        ) as BenefitChangeSuggestionFormItemLocation;

        benefitLocation.action = 'delete';
        benefitLocation.location = undefined;
        form.setFieldsValue({
            LOCATION_FORM_FIELD_NAME: benefitLocation,
        });
    };

    const addInterimDate = () => {
        const deliveryDates = form.getFieldValue(
            DELIVERY_DATES_FORM_FIELD_NAME,
        ) as BenefitChangeSuggestionsDeliveryDate[];
        deliveryDates.splice(deliveryDates.length - 1, 0, {
            isFinal: false,
            isStart: false,
            action: 'create',
        });

        form.setFieldsValue({ DELIVERY_DATES_FORM_FIELD_NAME: deliveryDates });
    };

    const removeInterimDate = (name: number) => () => {
        const deliveryDates = form.getFieldValue(
            DELIVERY_DATES_FORM_FIELD_NAME,
        ) as BenefitChangeSuggestionsDeliveryDate[];
        const date = deliveryDates[name];

        if (date.action === 'create') {
            deliveryDates.splice(name, 1);
        } else {
            deliveryDates[name].action =
                deliveryDates[name].action === 'delete' ? 'update' : 'delete';
        }

        form.setFieldsValue({ DELIVERY_DATES_FORM_FIELD_NAME: deliveryDates });
    };

    const addBenefitPriority =
        (
            addPriorityToFormValues: (
                priority: BenefitChangeSuggestionsFormItemBenefitPriority,
            ) => void,
        ) =>
        (id: number, name: string) =>
            addPriorityToFormValues({
                priority: id,
                name,
                value: 1,
                action: 'create',
            });

    const removeBenefitPriority =
        (removePriorityFromFromValues: (index: number) => void) => (name: number) => () =>
            removePriorityFromFromValues(name);

    return (
        <Form.Item>
            <Collapse>
                <Collapse.Panel header="Suggest Benefit Label" key="4">
                    <BenefitLabelSuggestionCard
                        name={LABEL_FORM_FIELD_NAME}
                        benefitId={benefitId}
                        contractId={contractId}
                        sharedBenefit={benefitSharedUrl}
                        isUserAdmin={isUserAdmin}
                    />
                </Collapse.Panel>
                <Collapse.Panel header="Suggest Benefit Delivery Dates" key="0">
                    <Form.List name={DELIVERY_DATES_FORM_FIELD_NAME}>
                        {(fields) => (
                            <>
                                {fields.map(({ name, key }) => (
                                    <DeliveryDateSuggestionCard
                                        key={key}
                                        name={name}
                                        onRemove={removeInterimDate}
                                        evidenceDateId={evidenceDateId}
                                    />
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={addInterimDate}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add delivery date
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Collapse.Panel>
                <Collapse.Panel header="Suggest Benefit Location" key="3">
                    <Form.List name={LOCATION_FORM_FIELD_NAME}>
                        {(fields) => (
                            <BenefitLocationSuggestionCard
                                onSelect={changeBenefitLocation}
                                onDelete={removeBenefitLocation}
                                name={LOCATION_FORM_FIELD_NAME}
                            />
                        )}
                    </Form.List>
                </Collapse.Panel>
                <Collapse.Panel header="Suggest Benefit Value" key="2">
                    <BenefitValueSuggestionCard name={VALUE_FORM_FIELD_NAME} />
                </Collapse.Panel>
                <Collapse.Panel header="Suggest Benefit Priorities" key="1">
                    <Form.List name={PRIORITIES_FORM_FIELD_NAME}>
                        {(fields, { add, remove }) => (
                            <>
                                <AutocompleteSelect
                                    autocompleteField={AutocompleteField.BenefitPriorities}
                                    label="Add Benefit Priority"
                                    searchParams={
                                        notArchivedFilterParam && {
                                            organisation: organisationId,
                                        }
                                    }
                                    initialValue={
                                        Priorities && Priorities.length > 0
                                            ? Priorities[0].priority
                                            : undefined
                                    }
                                    required={false}
                                    onSelect={addBenefitPriority(add)}
                                    filterOptionsIds={benefitPrioritiesSuggestions?.reduce(
                                        (acc, suggestion) =>
                                            suggestion.id ? [...acc, suggestion.id] : acc,
                                        [] as number[],
                                    )}
                                />
                                {fields.map(({ name, key }) => (
                                    <BenefitPrioritySuggestionCard
                                        key={key}
                                        name={name}
                                        onRemove={removeBenefitPriority(remove)}
                                    />
                                ))}
                            </>
                        )}
                    </Form.List>
                </Collapse.Panel>
            </Collapse>
        </Form.Item>
    );
};
