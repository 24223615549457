import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';

import { ButtonWrapper } from 'common/components/styled';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { EditEmployeeProfile } from 'features/user/models/userModel';
import { FormFieldIds, generateColumns } from 'common/helpers/utils';
import { AutocompleteField, PaginationView } from 'features/pagination/models/paginationModel';
import { getEmployeesSuccess } from 'features/user/actions/userActions';
import { DrawerContainer } from 'common/components/DrawerContainer';
import { showNotification, notificationText } from 'common/helpers/notifications';
import { openModal } from 'common/helpers/modal';
import { Antd3Form, Antd3Icon, Antd3FormProps } from 'common/components/deprecated/antd3';
import {
    AutocompleteSelect,
    PaddedSection,
    UploadAndCheckTableFiltersBar,
} from 'common/components';
import { InvitePaths } from 'features/invite/models/inviteModel';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { EditEmployeeProps } from '../containers/EditEmployeeContainer';
import { EditPaths } from '../models/editModel';
import { EditEmployeeForm } from './EditEmployeeForm';
import { PaginationValueFilter } from 'features/pagination/components/PaginationValueFilter';
import { PaginationArchivedFilter } from 'features/pagination/components/PaginationArchivedFilter';
import { PaginationDepartmentValueFilter } from 'features/pagination/components/PaginationDepartmentFilter';
import { FilterData } from 'common/helpers/url';

type Props = EditEmployeeProps & Antd3FormProps;

interface State {
    editingUserProfile?: EditEmployeeProfile;
}

const editEmployeesColumns = (
    openArchiveModal: (e: React.FormEvent, id: number, name: string, archived: boolean) => void,
    // openDeleteModal: (e: React.FormEvent, id: number, name: string) => void,
    openEditForm: (e: React.FormEvent, id: number) => void,
) => [
    {
        title: 'Employee',
        key: 'user.id',
        render: (_: string, { user: { firstName, lastName } }: EditEmployeeProfile) =>
            `${firstName} ${lastName}`,
    },
    {
        title: '',
        key: 'id',
        render: (_: string, profile: EditEmployeeProfile) => {
            const {
                id,
                archived,
                user: { firstName, lastName },
            } = profile;

            const archiveBtnText = archived ? 'Unarchive' : 'Archive';

            return (
                <ButtonWrapper>
                    <Button
                        icon={<Antd3Icon type="edit" />}
                        size="small"
                        onClick={(e: React.FormEvent) => openEditForm(e, id)}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={(e: React.FormEvent) =>
                            openArchiveModal(e, id, `${firstName} ${lastName}`, !archived)
                        }
                        type={archived ? 'default' : 'danger'}
                        icon={<Antd3Icon type={archived ? 'undo' : 'folder'} />}
                        size="small"
                    >
                        {archiveBtnText}
                    </Button>
                </ButtonWrapper>
            );
        },
        width: 300,
    },
];

class EditEmployeesForm extends Component<Props, State> {
    public state: State = {};

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields(
            (error, { departmentValue, isAdministrator, telephone, ...values }) => {
                if (!this.state.editingUserProfile || error) {
                    return showNotification({ text: notificationText.Error });
                }

                return this.props.editEmployeeRequest({
                    id: this.state.editingUserProfile.id,
                    department: departmentValue,
                    telephone,
                    user: {
                        ...values,
                    },
                    isAdministrator,
                });
            },
        );
    };

    private openForm = (e: React.FormEvent, id: number) => {
        e.preventDefault();

        this.setState({
            editingUserProfile: this.props.employees.find((employee) => employee.id === id),
        });

        this.props.openForm();
    };
    private archiveEmployee = (e: React.FormEvent, id: number, name: string, archived: boolean) => {
        e.preventDefault();

        openModal({
            title: `${name}: Are you sure you want to ${
                archived ? 'archive' : 'restore'
            } this employee?`,
            callback: () => this.props.archiveEmployeeRequest(id, archived),
        });
    };
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.EditEmployees,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getEmployeesSuccess,
        });
    public render(): JSX.Element {
        const { employees, form } = this.props;
        const { editingUserProfile } = this.state;
        const view = PaginationView.EditEmployees;

        return (
            <PaddedSection header="Edit Employees" bigHeader>
                <TableFiltersBar
                    leftButtons={[
                        <Link to={InvitePaths.Employee}>
                            <Button type="primary" icon={<SendOutlined />}>
                                Invite Employee
                            </Button>
                        </Link>,
                    ]}
                />
                <TableFiltersBar
                    filters={[
                        <PaginationValueFilter
                            paginatedView={view}
                            filterName={'fullName'}
                            refreshTable={this.refreshTable}
                        />,
                        <PaginationDepartmentValueFilter
                            paginatedView={view}
                            form={form}
                            filterName={'userDepartment'}
                            refreshTable={this.refreshTable}
                        />,
                        <PaginationArchivedFilter
                            paginatedView={view}
                            refreshTable={this.refreshTable}
                        />,
                    ]}
                />
                <Antd3Form.Item>
                    <PaginatedTable
                        view={PaginationView.EditEmployees}
                        data={employees}
                        columns={generateColumns(
                            editEmployeesColumns(this.archiveEmployee, this.openForm),
                        )}
                        paginationSuccessCallback={getEmployeesSuccess}
                        pageNumber={this.props.pageNumber}
                        pathUrl={EditPaths.Employees}
                        withPaginationHistory
                        refetchOnFiltersChange
                    />
                </Antd3Form.Item>
                {editingUserProfile && (
                    <DrawerContainer
                        title="Edit employee"
                        onClose={this.props.closeForm}
                        visible={this.props.formVisible}
                    >
                        <Antd3Form onSubmit={this.handleSubmit}>
                            <EditEmployeeForm
                                form={this.props.form}
                                profile={editingUserProfile}
                                isFetching={this.props.isFetching}
                            />
                        </Antd3Form>
                    </DrawerContainer>
                )}
            </PaddedSection>
        );
    }
}

export const EditEmployees = Antd3Form.create({})(EditEmployeesForm);
