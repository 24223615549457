import React, { FormEvent } from 'react';
import { Button, Input, Tabs } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';

import { Organisation } from 'features/organisation/models/organisationModels';
import { InvitePaths } from 'features/invite/models/inviteModel';
import { openModal } from 'common/helpers/modal';
import { generateColumns } from 'common/helpers/utils';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getAllExecutivesSuccess } from 'features/organisation/actions/organisationActions';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { EditOrganisationProps } from '../containers/EditOrganisationsContainer';
import { EditOrganisationTitles, EditPaths } from '../models/editModel';

const editOrganisationsColumns = (
    openDeleteModal: (e: FormEvent, id: number, name: string) => void,
) => [
    {
        title: 'Organisation',
        key: 'name',
        render: (name: string, { id }: Organisation) => (
            <Link to={`${EditPaths.Organisations}/${id}/details`}>{name}</Link>
        ),
    },
    {
        title: '',
        key: 'id',
        render: (_: string, { id, name }: Organisation) => (
            <Button
                onClick={(e: FormEvent) => openDeleteModal(e, id, name)}
                type="danger"
                icon={<Antd3Icon type="folder" />}
                size="small"
            >
                Remove
            </Button>
        ),
        width: 50,
    },
];

export class EditOrganisations extends React.Component<EditOrganisationProps> {
    private removeOrganisation = (event: FormEvent, id: number, name: string) => {
        event.preventDefault();
        openModal({
            title: `${name}: Are you sure you want to remove this organisation?`,
            callback: () => this.props.removeExecutiveRequest(id),
        });
    };

    private searchOrganisations = (event: FormEvent<HTMLFormElement>) => {
        this.props.saveFilters({
            paginatedView: PaginationView.EditOrganisations,
            values: {
                name: event.currentTarget.value,
            },
        });

        this.props.paginationRequest({
            view: PaginationView.EditOrganisations,
            pagination: {
                current: 1,
            },
            paginationSuccessCallback: getAllExecutivesSuccess,
        });
    };

    public render(): JSX.Element {
        return (
            <>
                <PaddedSection header="Edit Organisations" bigHeader>
                    <TableFiltersBar
                        leftButtons={[
                            <Link to={InvitePaths.Organisation}>
                                <Button type="primary" icon={<SendOutlined />}>
                                    Invite Organisation
                                </Button>
                            </Link>,
                        ]}
                        filters={[
                            <Input.Search
                                placeholder="Search organisations"
                                onChange={this.searchOrganisations}
                            />,
                        ]}
                    />
                    <PaginatedTable
                        view={PaginationView.EditOrganisations}
                        paginationSuccessCallback={getAllExecutivesSuccess}
                        data={this.props.executives}
                        columns={generateColumns(editOrganisationsColumns(this.removeOrganisation))}
                        pageNumber={this.props.pageNumber}
                        pathUrl={EditPaths.Organisations}
                        withPaginationHistory
                    />
                </PaddedSection>{' '}
            </>
        );
    }
}
