import * as React from 'react';
import { InputNumber, Input as AntdInput } from 'antd';
import styled from 'styled-components';

import { InputLabelWithTooltip } from '../../form/components/InputLabelWithTooltip';
import { Antd3Form, Antd3InputProps } from '../antd3';

const StyledNumberInput = styled(InputNumber)`
    && {
        width: 100%;
    }
`;

interface Props extends Antd3InputProps {
    isPassword?: boolean;
    type?: string;
    typeMessage?: string;
    initialValue?: string | number;
    validator?: (_rule: any, value: string, callback: (msg?: string) => void) => void;
    phoneValidator?: (_rule: any, value: string, callback: (msg?: string) => void) => void; // New phoneValidator prop
    autoComplete?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    hasFeedback?: boolean;
    validateStatus?: '' | 'error' | 'success' | 'warning' | 'validating';
    help?: string;
    value: string;
}

export const Input = ({
    form,
    id,
    isPassword,
    requiredMessage,
    placeholder,
    label,
    value,
    initialValue,
    type = 'string',
    required = true,
    disabled = false,
    hidden,
    validator,
    phoneValidator, // New phoneValidator prop
    autoComplete,
    tooltip,
    onChange,
    onInputChange,
    hasFeedback,
    validateStatus,
    help,
}: Props) => {
    const rulesByType: Record<string, object> = {
        email: { type, message: 'This is not a valid email address' },
    };

    const returnRequiredMessage = () =>
        (label && label.toLowerCase().includes('telephone number')) ||
        (placeholder && placeholder.toLowerCase().includes('telephone number'))
            ? ''
            : requiredMessage || `${label || placeholder} is required`;

    const validationRules = [{ required, message: returnRequiredMessage }, rulesByType[type]];

    if (validator) {
        validationRules.push(validator);
    }

    const InputComponent = type === 'number' ? StyledNumberInput : AntdInput;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onInputChange) {
            onInputChange(id, 'value', event.target.value);
        }
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Antd3Form.Item
            label={
                tooltip ? (
                    <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
                ) : (
                    label || placeholder
                )
            }
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            help={help}
        >
            {form.getFieldDecorator(id, {
                initialValue,
                rules: [
                    type === 'email'
                        ? { type, message: 'This is not a valid email address' }
                        : { type },
                    { required, message: returnRequiredMessage },
                    ...(validator ? [{ validator }] : []),
                    ...(phoneValidator ? [{ validator: phoneValidator }] : []),
                ],
            })(
                <InputComponent
                    type={isPassword ? 'password' : type}
                    size="large"
                    placeholder={placeholder || label}
                    disabled={disabled}
                    hidden={hidden}
                    autoComplete={autoComplete}
                    onChange={handleOnChange}
                    value={value}
                />,
            )}
        </Antd3Form.Item>
    );
};
