import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ContractWithRAG } from 'features/contract/models/contractModels';

import { Column, PaginationResources } from '../models/paginationModel';
import { Link } from 'react-router-dom';
import { getStatusLabel } from 'common/helpers/utils';

interface ExpandableRowProps {
    rowData: ContractWithRAG;
    columns: Column<PaginationResources>[];
}

const RowWrapper = styled.div`
    display: flex;
    margin-bottom: 1%;
    margin-left: 8%;
`;
const ColumnTitle = styled.p`
    margin-right: 10%;
    width: 18%;
`;
const ColumnContent = styled.p`
    margin: 0;
`;

export const ExpandableRow: React.FunctionComponent<ExpandableRowProps> = ({
    rowData,
    columns,
}) => (
    <>
        {columns
            .filter((column) => column.key !== 'id')
            .map(({ key, title, render }) => (
                <RowWrapper key={key}>
                    <ColumnTitle>{title}</ColumnTitle>
                    <ColumnContent>
                        {key === 'contractTitle' && rowData.contract != null ? (
                            <Link to={`/edit/project/${rowData.contract}/project-details`}>
                                {rowData[key]}
                            </Link>
                        ) : key === 'label' &&
                          rowData.deliveryDates != null &&
                          rowData.deliveryDates.length > 0 &&
                          rowData.deliveryDates[0]?.benefit != null &&
                          rowData.contract != null ? (
                            <Link
                                to={`/edit/project/${rowData.contract}/benefits/${rowData.deliveryDates[0].benefit}/benefit-details`}
                            >
                                {rowData[key]}
                            </Link>
                        ) : key === 'label' &&
                          rowData.deliveryDates != null &&
                          rowData.deliveryDates.length > 0 &&
                          rowData.deliveryDates[0]?.benefit != null &&
                          rowData.contract != null ? (
                            <Link
                                to={`/edit/project/${rowData.contract}/benefits/${rowData.deliveryDates[0].benefit}/benefit-details`}
                            >
                                {rowData[key]}
                            </Link>
                        ) : (
                            /* render ? (
                            render(rowData[key], title)
                        ) : */ getStatusLabel(rowData[key])
                        )}
                    </ColumnContent>
                </RowWrapper>
            ))}
    </>
);
