import * as React from 'react';
import { Button } from 'antd';

import { notArchivedFilterParam } from 'config/config';
import {
    DatePicker,
    Input,
    NumberInputsRange,
    RangePicker,
    ResetButton,
    Select,
} from 'common/components/deprecated/form';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { assessorScoreOptions, supplierScoreOptions } from 'features/upload/models/uploadModels';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { benefitStatuses } from 'features/benefit/models/benefitModels';
import { benefitMethod } from 'features/contract/models/contractModels';
import { FormFieldIds } from 'common/helpers/utils';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { AutocompleteSelectAll } from 'features/pagination/components/AutocompleteSelectAll';

import { EditPaths } from '../models/editModel';
import { EditBenefitsFilterContainerProps } from '../containers/EditBenefitsFilterContainer';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import moment from 'moment';
import { formatDate } from 'features/upload/helpers';

interface EditBenefitsState {
    priorities: string[];
    areAllPrioritiesSelected: boolean;
}

type EditBenefitsFilterProps = EditBenefitsFilterContainerProps & Antd3FormProps;

class EditBenefitsFilterComponent extends React.Component<
    EditBenefitsFilterProps,
    EditBenefitsState
> {
    public state: EditBenefitsState = {
        priorities: [],
        areAllPrioritiesSelected: false,
    };

    public componentDidMount(): void {
        const { formInitial } = this.props;
        const { values } = formInitial || { values: {} };

        if (values.priorities) {
            this.setState({
                priorities: values.priorities,
            });
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.props.form.validateFields((error, values) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            const { priorities } = this.state;
            const { min_reported_on, max_reported_on } = values;
            this.props.saveFilters({
                values: {
                    ...values,
                    priorities,
                    min_reported_on: min_reported_on && formatDate(min_reported_on),
                    max_reported_on: max_reported_on && formatDate(max_reported_on),
                },
                paginatedView: PaginationView.ContractBenefits,
                path: `${EditPaths.Contract}/${this.props.contractId}/benefits-list`,
            });
        });
    };

    private handleSelectAllPriorities = (priorities: string[]) => {
        this.state.areAllPrioritiesSelected
            ? this.setState((prevState) => ({
                  priorities: [],
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }))
            : this.setState((prevState) => ({
                  priorities,
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }));
    };

    private addPriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: [...prevState.priorities, selectedPriority],
        }));
    };

    private removePriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: prevState.priorities.filter((priority) => priority !== selectedPriority),
        }));
    };

    public render(): JSX.Element {
        const { form, formInitial, clearFilters, isTenant } = this.props;
        const { values } = formInitial || { values: {} };

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit}>
                <ResetButton
                    form={form}
                    clearFilters={clearFilters}
                    paginationView={PaginationView.ContractBenefits}
                />
                <Input form={form} id="outcome" placeholder="Benefit Outcome" required={false} />
                <Input
                    form={form}
                    id="label"
                    label="Benefit Label"
                    initialValue={values.label}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitCategory}
                    id="category"
                    placeholder="Benefit Category"
                    defaultValue={values.category}
                    notFoundContent="No benefit categories found"
                />
                <Select
                    form={form}
                    id="method"
                    placeholder="Benefit Model"
                    initialValue={values.method}
                    options={benefitMethod}
                    required={false}
                    allowClear
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Location}
                    id="location"
                    placeholder="Benefit Location"
                    defaultValue={values.location}
                    notFoundContent="No locations found"
                />
                <NumberInputsRange
                    form={form}
                    fromInput={{ id: 'pointsMin', placeholder: 'Minimum points' }}
                    toInput={{ id: 'pointsMax', placeholder: 'Maximum points' }}
                    initFrom={values['pointsMin']}
                    initTo={values['pointsMax']}
                    label="Benefit Points"
                />
                <NumberInputsRange
                    form={form}
                    fromInput={{ id: 'valueMin', placeholder: 'Minimum value' }}
                    toInput={{ id: 'valueMax', placeholder: 'Maximum value' }}
                    initFrom={values.valueMin}
                    initTo={values.valueMax}
                    label="Benefit Value"
                />
                <NumberInputsRange
                    form={form}
                    fromInput={{
                        id: FormFieldIds.EmissionSavingsMin,
                        placeholder: 'Minimum emission savings',
                    }}
                    toInput={{
                        id: FormFieldIds.EmissionSavingsMax,
                        placeholder: 'Maximum emission savings',
                    }}
                    label="Emissions Savings"
                    initFrom={values.emissionSavingsMin}
                    initTo={values.emissionSavingsMax}
                />
                {isTenant && (
                    <AutocompleteSelectAll
                        form={form}
                        autocompleteField={AutocompleteField.BenefitPriorities}
                        id={FormFieldIds.Priorities}
                        placeholder="Benefit Priorities"
                        notFoundContent="No categories found"
                        defaultValue={values.priorities}
                        handleSelectAllPriorities={this.handleSelectAllPriorities}
                        multiple
                        areAllPrioritiesSelected={this.state.areAllPrioritiesSelected}
                        selected={this.state.priorities}
                        addPriority={this.addPriority}
                        removePriority={this.removePriority}
                    />
                )}
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Assessor}
                    id="assessor"
                    placeholder="Contributor"
                    defaultValue={values.assessor}
                    notFoundContent="No contributor found"
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.SupplierOrganisation}
                    id={FormFieldIds.Supplier}
                    placeholder="Supplier"
                    defaultValue={values.supplier}
                    notFoundContent="No suppliers found"
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveSupplierUser}
                    id={FormFieldIds.SupplierExecutive}
                    placeholder="Supplier Executives"
                    notFoundContent="No executives with supplier role found"
                    defaultValue={true && values.supplierExecutive}
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.DeliveryPartnerOrganisation}
                    id="deliveryPartner"
                    placeholder="Delivery Partners"
                    defaultValue={values.deliveryPartner}
                    notFoundContent="No delivery partners found"
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveDeliveryPartnerUser}
                    id={FormFieldIds.DeliveryPartnerExecutive}
                    placeholder="Delivery Partner Executives"
                    notFoundContent="No executives with delivery partners role found"
                    defaultValue={values.deliveryPartnerExecutive}
                    searchParams={notArchivedFilterParam}
                />
                <RangePicker
                    form={form}
                    label="Benefit Start Date"
                    inputs={[
                        {
                            id: 'minStartDate',
                            placeholder: 'From',
                            initialValue: values.minStartDate,
                        },
                        {
                            id: 'maxStartDate',
                            placeholder: 'To',
                            initialValue: values.maxStartDate,
                        },
                    ]}
                />
                <RangePicker
                    form={form}
                    label="Benefit Completion Date"
                    inputs={[
                        {
                            id: 'minEndDate',
                            placeholder: 'From',
                            initialValue: values.minEndDate,
                        },
                        {
                            id: 'maxEndDate',
                            placeholder: 'To',
                            initialValue: values.maxEndDate,
                        },
                    ]}
                />
                <RangePicker
                    form={form}
                    label="Benefit Reporting Date"
                    inputs={[
                        {
                            id: FormFieldIds.min_reported_on,
                            placeholder: 'From',
                            initialValue: values.minStartDate,
                        },
                        {
                            id: FormFieldIds.max_reported_on,
                            placeholder: 'To',
                            initialValue: values.maxStartDate,
                        },
                    ]}
                />
                <Select
                    form={form}
                    id="status"
                    placeholder="Benefit Status"
                    initialValue={values.status}
                    options={benefitStatuses}
                    required={false}
                    allowClear
                />
                <DatePicker
                    form={form}
                    id="reportedOn"
                    label="Benefit Reporting Date"
                    tooltip={templatedBenefitTooltips.reportedOn}
                    required={false}
                    initialValue={values.reportedOn}
                />
                <Button type="primary" htmlType="submit">
                    Filter
                </Button>
            </StyledAntd3Form>
        );
    }
}

export const EditBenefitsFilter = Antd3Form.create({})(EditBenefitsFilterComponent);
