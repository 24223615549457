import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullPageSpinner } from 'common/components';
import { getStatus, getUpstreamClient } from '../selectors/organisationSelectors';
import { UpstreamDetails } from '../components/UpstreamDetails';
import { getUserOrganisationId } from 'features/user/selectors/userSelectors';
import { getUpstreamClientRequest } from '../actions/organisationActions';
import { CreateClientUpstream } from '../components/CreateClientUpstream';
import { RESPONSE } from '../models/organisationModels';

export const UpstreamDetailsContainer = () => {
    const dispatch = useDispatch();
    const activeUserOrganisationId = useSelector(getUserOrganisationId);
    const upstreamClientSettings = useSelector(getUpstreamClient);
    const upstreamClientResponse = useSelector(getStatus);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (
            upstreamClientResponse ===
                RESPONSE.REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED ||
            activeUserOrganisationId
        ) {
            dispatch(getUpstreamClientRequest(activeUserOrganisationId));
        }
        setLoading(false);
    }, [activeUserOrganisationId, upstreamClientResponse]);
    return loading ? (
        <FullPageSpinner />
    ) : upstreamClientSettings.id !== 0 &&
      upstreamClientResponse &&
      RESPONSE.UPSTREAM_CLIENT_FOUND === upstreamClientResponse ? (
        <UpstreamDetails
            upstreamClientSettings={upstreamClientSettings}
            status={upstreamClientResponse}
            dispatch={dispatch}
        />
    ) : (
        <CreateClientUpstream />
    );
};
