import * as React from 'react';
import posthog from 'posthog-js';
import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';

import { SubmitButton } from 'common/components';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';

import { UpdateUserDataProps } from '../containers/UpdateUserDataContainer';

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY!, {
            api_host: 'https://app.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

type UpdateUserDataFormProps = UpdateUserDataProps & Antd3FormProps;

class UpdateUserDataForm extends React.Component<UpdateUserDataFormProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.props.form.validateFields((error, ...values) => {
            if (error) {
                return;
            }
            this.props.updateUserDataRequest(...values);
        });
    };

    public render(): JSX.Element {
        const {
            form,
            profile: { user, telephone },
        } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Update Profile">
                <ResetButton form={form} />
                <Input
                    form={form}
                    id="firstName"
                    label="First Name"
                    initialValue={user.firstName}
                />
                <Input form={form} id="lastName" label="Last Name" initialValue={user.lastName} />
                <PhoneInput
                    form={form}
                    id="telephone"
                    placeholder="Telephone Number"
                    initialValue={telephone}
                />

                <SubmitButton value="Update Profile" />
            </StyledAntd3Form>
        );
    }
}

export const UpdateUserData = Antd3Form.create({})(UpdateUserDataForm);
