import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Redirect } from 'react-router-dom';

import {
    getProfile,
    isAdmin,
    getOrganisationCurrentSubscription,
    isTenant,
} from '../selectors/userSelectors';
import { SubscriptionDetails } from '../components/SubscriptionDetails';
import { UserState } from '../reducers/userReducer';
import { InvoiceData, Subscription } from '../models/userModel';

interface StateProps {
    profile: UserState;
    isUserAdmin: boolean;
    isUserTenant: boolean;
    userSubscription: Subscription;
    userInvoices?: InvoiceData[];
    element?: JSX.Element;
}

export type Props = StateProps;

const SubscriptionElement = (props: Props): JSX.Element =>
    props.isUserAdmin ? <SubscriptionDetails {...props} /> : <Redirect to="/edit/my-benefits/" />;

const mapStateToProps = createSelector(
    getProfile,
    isAdmin,
    isTenant,
    getOrganisationCurrentSubscription,
    (profile, isUserAdmin, isUserTenant, userSubscription): StateProps => ({
        profile,
        isUserAdmin,
        isUserTenant,
        userSubscription,
    }),
);

export const SubscriptionContainer = connect(mapStateToProps)(SubscriptionElement);
