import React from 'react';
import styled from 'styled-components';

import { ContractWithRAG } from 'features/contract/models/contractModels';
import { formatRAGStatus } from 'common/helpers/utils';
import { Antd3Icon } from 'common/components/deprecated/antd3';

interface ExpandableRowProps {
    rowData: ContractWithRAG;
}

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RAGIndicatorWrapper = styled.div`
    display: flex;
    width: 3rem;
    justify-content: space-between;
`;

const RAGIndicators = [
    {
        title: 'Benefits Delivered:',
        ragStatus: 'benefitDeliveredNumberRag',
        ragColor: 'deliveredBenefitCount',
    },
    {
        title: 'Points:',
        ragStatus: 'benefitPointsRag',
        ragColor: 'deliveredPoints',
    },
    {
        title: 'Value:',
        ragStatus: 'benefitValueRag',
        ragColor: 'deliveredValue',
    },
    {
        title: 'Emissions Saving:',
        ragStatus: 'benefitEmissionSavingsRag',
        ragColor: 'deliveredEmissionSavings',
    },
];

export const RAGExpandableRow: React.FunctionComponent<ExpandableRowProps> = ({ rowData }) => (
    <>
        <RowWrapper>
            <p>Ref. No.:</p>
            <p>{rowData.referenceNumber}</p>
        </RowWrapper>
        {RAGIndicators.map((RAGIndicator, index) => (
            <RowWrapper key={index}>
                <p>{RAGIndicator.title}</p>
                <RAGIndicatorWrapper>
                    <Antd3Icon
                        type="minus-circle"
                        theme="filled"
                        style={{ color: formatRAGStatus(rowData[RAGIndicator.ragStatus]) }}
                    />
                    <span> {rowData[RAGIndicator.ragColor]}</span>
                </RAGIndicatorWrapper>
            </RowWrapper>
        ))}
    </>
);
