import posthog from 'posthog-js';
import { SENTRY_ENVIRONMENT } from 'config/env';
import { Action } from 'common/actions/appAction';

import {
    InviteByEmail,
    EmployeeInvitation,
    OrganisationInvitation,
    InviteByEmailWithRole,
    EditBenefitInviteByEmailWithRole,
    ResendRoleInvitation,
    DeleteRoleInvitation,
    InvitedExecutive,
} from '../models/inviteModel';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { InvitePendingIndividualExecutivesRequest } from 'features/benefit/models/benefitModels';

export const INVITE_EMPLOYEE_REQUESTED = 'INVITE_EMPLOYEE_REQUESTED';
export type InviteEmployeeRequestAction = Action<typeof INVITE_EMPLOYEE_REQUESTED, InviteByEmail>;
export const inviteEmployeeRequest = (employee: InviteByEmail): InviteEmployeeRequestAction => ({
    type: INVITE_EMPLOYEE_REQUESTED,
    payload: { ...employee },
});

export const INVITE_EMPLOYEE_SUCCEED = 'INVITE_EMPLOYEE_SUCCEED';
export type InviteEmployeeSuccessAction = Action<
    typeof INVITE_EMPLOYEE_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const inviteEmployeeSuccess = (): InviteEmployeeSuccessAction => ({
    type: INVITE_EMPLOYEE_SUCCEED,
    meta: 'Invitation email has been sent',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Employee invited') : null,
});

export const INVITE_EMPLOYEE_FAILED = 'INVITE_EMPLOYEE_FAILED';
export type InviteEmployeeFailureAction = Action<
    typeof INVITE_EMPLOYEE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const inviteEmployeeFailure = (payload: string): InviteEmployeeFailureAction => ({
    type: INVITE_EMPLOYEE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Employee invitation failed')
            : null,
});

export const INVITE_EMPLOYEE_WITH_ROLE_REQUESTED = 'INVITE_EMPLOYEE_WITH_ROLE_REQUESTED';
export type InviteEmployeeWithRoleRequestAction = Action<
    typeof INVITE_EMPLOYEE_WITH_ROLE_REQUESTED,
    InviteByEmailWithRole
>;
export const inviteEmployeeWithRoleRequest = (
    employee: InviteByEmailWithRole,
): InviteEmployeeWithRoleRequestAction => ({
    type: INVITE_EMPLOYEE_WITH_ROLE_REQUESTED,
    payload: { ...employee },
});

export const INVITE_EMPLOYEE_WITH_ROLE_SUCCEED = 'INVITE_EMPLOYEE_WITH_ROLE_SUCCEED';
export type InviteEmployeeWithRoleSuccessAction = Action<
    typeof INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
    null,
    string
>;
export const inviteEmployeeWithRoleSuccess = (): InviteEmployeeWithRoleSuccessAction => ({
    type: INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
    meta: 'Invitation email has been sent',
});

export const INVITE_EMPLOYEE_WITH_ROLE_FAILED = 'INVITE_EMPLOYEE_WITH_ROLE_FAILED';
export type InviteEmployeeWithRoleFailureAction = Action<
    typeof INVITE_EMPLOYEE_WITH_ROLE_FAILED,
    string
>;
export const inviteEmployeeWithRoleFailure = (
    payload: string,
): InviteEmployeeWithRoleFailureAction => ({
    type: INVITE_EMPLOYEE_WITH_ROLE_FAILED,
    payload,
});

export const INVITE_NEW_POTENTIAL_EXECUTIVE_REQUESTED = 'INVITE_NEW_POTENTIAL_EXECUTIVE_REQUESTED';
export type InviteNewPotentialExecutiveRequestAction = Action<
    typeof INVITE_NEW_POTENTIAL_EXECUTIVE_REQUESTED,
    InviteByEmail
>;
export const inviteNewPotentialExecutiveRequest = (
    executive: InviteByEmail,
): InviteNewPotentialExecutiveRequestAction => ({
    type: INVITE_NEW_POTENTIAL_EXECUTIVE_REQUESTED,
    payload: executive,
});

export const INVITE_NEW_POTENTIAL_EXECUTIVE_SUCCEED = 'INVITE_NEW_POTENTIAL_EXECUTIVE_SUCCEED';
export type InviteNewPotentialExecutiveSuccessAction = Action<
    typeof INVITE_NEW_POTENTIAL_EXECUTIVE_SUCCEED,
    null,
    string
>;
export const inviteNewPotentialExecutiveSuccess = (): InviteNewPotentialExecutiveSuccessAction => ({
    type: INVITE_NEW_POTENTIAL_EXECUTIVE_SUCCEED,
    meta: 'Organisation invited',
});

export const INVITE_NEW_POTENTIAL_EXECUTIVE_FAILED = 'INVITE_NEW_POTENTIAL_EXECUTIVE_FAILED';
export type InviteNewPotentialExecutiveFailureAction = Action<
    typeof INVITE_NEW_POTENTIAL_EXECUTIVE_FAILED,
    string
>;
export const inviteNewPotentialExecutiveFailure = (
    payload: string,
): InviteNewPotentialExecutiveFailureAction => ({
    type: INVITE_NEW_POTENTIAL_EXECUTIVE_FAILED,
    payload,
});

export const INVITE_POTENTIAL_EXECUTIVES_REQUESTED = 'INVITE_POTENTIAL_EXECUTIVES_REQUESTED';
export type InvitePotentialExecutivesRequestAction = Action<
    typeof INVITE_POTENTIAL_EXECUTIVES_REQUESTED,
    number[]
>;
export const invitePotentialExecutivesRequest = (
    executives: number[],
): InvitePotentialExecutivesRequestAction => ({
    type: INVITE_POTENTIAL_EXECUTIVES_REQUESTED,
    payload: executives,
});

export const INVITE_POTENTIAL_EXECUTIVES_SUCCEED = 'INVITE_POTENTIAL_EXECUTIVES_SUCCEED';
export type InvitePotentialExecutivesSuccessAction = Action<
    typeof INVITE_POTENTIAL_EXECUTIVES_SUCCEED,
    null,
    string
>;
export const invitePotentialExecutivesSuccess = (): InvitePotentialExecutivesSuccessAction => ({
    type: INVITE_POTENTIAL_EXECUTIVES_SUCCEED,
    meta: 'Organisation(s) invited',
});

export const INVITE_POTENTIAL_EXECUTIVES_FAILED = 'INVITE_POTENTIAL_EXECUTIVES_FAILED';
export type InvitePotentialExecutivesFailureAction = Action<
    typeof INVITE_POTENTIAL_EXECUTIVES_FAILED,
    string
>;
export const invitePotentialExecutivesFailure = (
    payload: string,
): InvitePotentialExecutivesFailureAction => ({
    type: INVITE_POTENTIAL_EXECUTIVES_FAILED,
    payload,
});

export const GET_EMPLOYEES_PENDING_INVITATIONS_REQUESTED =
    'GET_EMPLOYEES_PENDING_INVITATIONS_REQUEST';
export type GetEmployeesPendingInvitationsRequestAction = Action<
    typeof GET_EMPLOYEES_PENDING_INVITATIONS_REQUESTED
>;
export const getEmployeesPendingInvitationsRequest =
    (): GetEmployeesPendingInvitationsRequestAction => ({
        type: GET_EMPLOYEES_PENDING_INVITATIONS_REQUESTED,
    });

export const GET_EMPLOYEES_PENDING_INVITATIONS_SUCCEED =
    'GET_EMPLOYEES_PENDING_INVITATIONS_SUCCEED';
export type GetEmployeesPendingInvitationsSuccessAction = Action<
    typeof GET_EMPLOYEES_PENDING_INVITATIONS_SUCCEED,
    { employeesPendingInvitations: EmployeeInvitation[] }
>;
export const getEmployeesPendingInvitationsSuccess = (
    employeesPendingInvitations: EmployeeInvitation[],
): GetEmployeesPendingInvitationsSuccessAction => ({
    type: GET_EMPLOYEES_PENDING_INVITATIONS_SUCCEED,
    payload: { employeesPendingInvitations },
});

export const GET_EMPLOYEES_PENDING_INVITATIONS_FAILED = 'GET_EMPLOYEES_PENDING_INVITATIONS_FAILED';
export type GetEmployeesPendingInvitationsFailureAction = Action<
    typeof GET_EMPLOYEES_PENDING_INVITATIONS_FAILED,
    string
>;
export const getEmployeesPendingInvitationsFailure = (
    payload: string,
): GetEmployeesPendingInvitationsFailureAction => ({
    type: GET_EMPLOYEES_PENDING_INVITATIONS_FAILED,
    payload,
});

export const DELETE_EMPLOYEE_PENDING_INVITATION_REQUESTED =
    'DELETE_EMPLOYEE_PENDING_INVITATION_REQUESTED';
export type DeleteEmployeesPendingInvitationsRequestAction = Action<
    typeof DELETE_EMPLOYEE_PENDING_INVITATION_REQUESTED,
    string
>;
export const deleteEmployeesPendingInvitationsRequest = (
    email: string,
): DeleteEmployeesPendingInvitationsRequestAction => ({
    type: DELETE_EMPLOYEE_PENDING_INVITATION_REQUESTED,
    payload: email,
});

export const DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED =
    'DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED';
export type DeleteEmployeesPendingInvitationsSucceesAction = Action<
    typeof DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED
>;
export const deleteEmployeesPendingInvitationsSuccees =
    (): DeleteEmployeesPendingInvitationsSucceesAction => ({
        type: DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED,
    });

export const DELETE_EMPLOYEE_PENDING_INVITATION_FAILED =
    'DELETE_EMPLOYEE_PENDING_INVITATION_FAILED';
export type DeleteEmployeesPendingInvitationsFailureAction = Action<
    typeof DELETE_EMPLOYEE_PENDING_INVITATION_FAILED
>;
export const deleteEmployeesPendingInvitationsFailure =
    (): DeleteEmployeesPendingInvitationsFailureAction => ({
        type: DELETE_EMPLOYEE_PENDING_INVITATION_FAILED,
    });

export const GET_EXISTING_ORG_PENDING_INVITATIONS_REQUESTED =
    'GET_EXISTING_ORG_PENDING_INVITATIONS_REQUEST';
export type GetExistingOrgPendingInvitationsRequestAction = Action<
    typeof GET_EXISTING_ORG_PENDING_INVITATIONS_REQUESTED
>;
export const getExistingOrgPendingInvitationsRequest =
    (): GetExistingOrgPendingInvitationsRequestAction => ({
        type: GET_EXISTING_ORG_PENDING_INVITATIONS_REQUESTED,
    });

export const GET_EXISTING_ORG_PENDING_INVITATIONS_SUCCEED =
    'GET_EXISTING_ORG_PENDING_INVITATIONS_SUCCEED';
export type GetExistingOrgPendingInvitationsSuccessAction = Action<
    typeof GET_EXISTING_ORG_PENDING_INVITATIONS_SUCCEED,
    { existingOrganisationPendingInvitations: OrganisationInvitation[] }
>;
export const getExistingOrgPendingInvitationsSuccess = (
    existingOrganisationPendingInvitations: OrganisationInvitation[],
): GetExistingOrgPendingInvitationsSuccessAction => ({
    type: GET_EXISTING_ORG_PENDING_INVITATIONS_SUCCEED,
    payload: { existingOrganisationPendingInvitations },
});

export const GET_EXISTING_ORG_PENDING_INVITATIONS_FAILED =
    'GET_EXISTING_ORG_PENDING_INVITATIONS_FAILED';
export type GetExistingOrgPendingInvitationsFailureAction = Action<
    typeof GET_EXISTING_ORG_PENDING_INVITATIONS_FAILED,
    string
>;
export const getExistingOrgPendingInvitationsFailure = (
    payload: string,
): GetExistingOrgPendingInvitationsFailureAction => ({
    type: GET_EXISTING_ORG_PENDING_INVITATIONS_FAILED,
    payload,
});

export const DELETE_EXISTING_ORG_PENDING_INVITATION_REQUESTED =
    'DELETE_EXISTING_ORG_PENDING_INVITATION_REQUESTED';
export type DeleteExistingOrgPendingInvitationsRequestAction = Action<
    typeof DELETE_EXISTING_ORG_PENDING_INVITATION_REQUESTED,
    string
>;
export const deleteExistingOrgPendingInvitationsRequest = (
    email: string,
): DeleteExistingOrgPendingInvitationsRequestAction => ({
    type: DELETE_EXISTING_ORG_PENDING_INVITATION_REQUESTED,
    payload: email,
});

export const DELETE_EXISTING_ORG_PENDING_INVITATION_SUCCEED =
    'DELETE_EXISTING_ORG_PENDING_INVITATION_SUCCEED';
export type DeleteExistingOrgPendingInvitationsSucceesAction = Action<
    typeof DELETE_EXISTING_ORG_PENDING_INVITATION_SUCCEED
>;
export const deleteExistingOrgPendingInvitationsSuccees =
    (): DeleteExistingOrgPendingInvitationsSucceesAction => ({
        type: DELETE_EXISTING_ORG_PENDING_INVITATION_SUCCEED,
    });

export const DELETE_EXISTING_ORG_PENDING_INVITATION_FAILED =
    'DELETE_EXISTING_ORG_PENDING_INVITATION_FAILED';
export type DeleteExistingOrgPendingInvitationsFailureAction = Action<
    typeof DELETE_EXISTING_ORG_PENDING_INVITATION_FAILED
>;
export const deleteExistingOrgPendingInvitationsFailure =
    (): DeleteExistingOrgPendingInvitationsFailureAction => ({
        type: DELETE_EXISTING_ORG_PENDING_INVITATION_FAILED,
    });

export const GET_NEW_ORG_PENDING_INVITATIONS_REQUESTED = 'GET_NEW_ORG_PENDING_INVITATIONS_REQUEST';
export type GetNewOrgPendingInvitationsRequestAction = Action<
    typeof GET_NEW_ORG_PENDING_INVITATIONS_REQUESTED
>;
export const getNewOrgPendingInvitationsRequest = (): GetNewOrgPendingInvitationsRequestAction => ({
    type: GET_NEW_ORG_PENDING_INVITATIONS_REQUESTED,
});

export const GET_NEW_ORG_PENDING_INVITATIONS_SUCCEED = 'GET_NEW_ORG_PENDING_INVITATIONS_SUCCEED';
export type GetNewOrgPendingInvitationsSuccessAction = Action<
    typeof GET_NEW_ORG_PENDING_INVITATIONS_SUCCEED,
    { newOrganisationPendingInvitations: OrganisationInvitation[] }
>;
export const getNewOrgPendingInvitationsSuccess = (
    newOrganisationPendingInvitations: OrganisationInvitation[],
): GetNewOrgPendingInvitationsSuccessAction => ({
    type: GET_NEW_ORG_PENDING_INVITATIONS_SUCCEED,
    payload: { newOrganisationPendingInvitations },
});

export const GET_NEW_ORG_PENDING_INVITATIONS_FAILED = 'GET_NEW_ORG_PENDING_INVITATIONS_FAILED';
export type GetNewOrgPendingInvitationsFailureAction = Action<
    typeof GET_NEW_ORG_PENDING_INVITATIONS_FAILED,
    string
>;
export const getNewOrgPendingInvitationsFailure = (
    payload: string,
): GetNewOrgPendingInvitationsFailureAction => ({
    type: GET_NEW_ORG_PENDING_INVITATIONS_FAILED,
    payload,
});

export const DELETE_NEW_ORG_PENDING_INVITATION_REQUESTED =
    'DELETE_NEW_ORG_PENDING_INVITATION_REQUESTED';
export type DeleteNewOrgPendingInvitationsRequestAction = Action<
    typeof DELETE_NEW_ORG_PENDING_INVITATION_REQUESTED,
    string
>;
export const deleteNewOrgPendingInvitationsRequest = (
    email: string,
): DeleteNewOrgPendingInvitationsRequestAction => ({
    type: DELETE_NEW_ORG_PENDING_INVITATION_REQUESTED,
    payload: email,
});

export const DELETE_NEW_ORG_PENDING_INVITATION_SUCCEED =
    'DELETE_NEW_ORG_PENDING_INVITATION_SUCCEED';
export type DeleteNewOrgPendingInvitationsSucceesAction = Action<
    typeof DELETE_NEW_ORG_PENDING_INVITATION_SUCCEED
>;
export const deleteNewOrgPendingInvitationsSuccees =
    (): DeleteNewOrgPendingInvitationsSucceesAction => ({
        type: DELETE_NEW_ORG_PENDING_INVITATION_SUCCEED,
    });

export const DELETE_NEW_ORG_PENDING_INVITATION_FAILED = 'DELETE_NEW_ORG_PENDING_INVITATION_FAILED';
export type DeleteNewOrgPendingInvitationsFailureAction = Action<
    typeof DELETE_NEW_ORG_PENDING_INVITATION_FAILED
>;
export const deleteNewOrgPendingInvitationsFailure =
    (): DeleteNewOrgPendingInvitationsFailureAction => ({
        type: DELETE_NEW_ORG_PENDING_INVITATION_FAILED,
    });

export const GET_ROLES_PENDING_INVITATIONS_REQUESTED = 'GET_ROLES_PENDING_INVITATIONS_REQUESTED';
export type GetRolesPendingInvitationsRequestAction = Action<
    typeof GET_ROLES_PENDING_INVITATIONS_REQUESTED,
    number
>;
export const getRolesPendingInvitationsRequest = (
    contractId: number,
): GetRolesPendingInvitationsRequestAction => ({
    type: GET_ROLES_PENDING_INVITATIONS_REQUESTED,
    payload: contractId,
});

export const GET_ROLES_PENDING_INVITATIONS_SUCCEED = 'GET_ROLES_PENDING_INVITATIONS_SUCCEED';
export type GetRolesPendingInvitationsSuccessAction = Action<
    typeof GET_ROLES_PENDING_INVITATIONS_SUCCEED,
    { rolesPendingInvitations: RoleInvitation[] }
>;
export const getRolesPendingInvitationsSuccess = (
    rolesPendingInvitations: RoleInvitation[],
): GetRolesPendingInvitationsSuccessAction => ({
    type: GET_ROLES_PENDING_INVITATIONS_SUCCEED,
    payload: { rolesPendingInvitations },
});

export const GET_ROLES_PENDING_INVITATIONS_FAILED = 'GET_ROLES_PENDING_INVITATIONS_FAILED';
export type GetRolesPendingInvitationsFailureAction = Action<
    typeof GET_ROLES_PENDING_INVITATIONS_FAILED,
    string
>;
export const getRolesPendingInvitationsFailure = (
    payload: string,
): GetRolesPendingInvitationsFailureAction => ({
    type: GET_ROLES_PENDING_INVITATIONS_FAILED,
    payload,
});

export const DELETE_PENDING_ROLE_INVITE_REQUESTED = 'DELETE_PENDING_ROLE_INVITE_REQUESTED';
export type DeletePendingRoleInviteRequestAction = Action<
    typeof DELETE_PENDING_ROLE_INVITE_REQUESTED,
    DeleteRoleInvitation
>;
export const deletePendingRoleInviteRequest = (
    payload: DeleteRoleInvitation,
): DeletePendingRoleInviteRequestAction => ({
    type: DELETE_PENDING_ROLE_INVITE_REQUESTED,
    payload,
});

export const DELETE_PENDING_ROLE_INVITE_SUCCEED = 'DELETE_PENDING_ROLE_INVITE_SUCCEED';
export type DeletePendingRoleInviteSuccessAction = Action<
    typeof DELETE_PENDING_ROLE_INVITE_SUCCEED,
    void
>;
export const deletePendingRoleInviteSuccess = (): DeletePendingRoleInviteSuccessAction => ({
    type: DELETE_PENDING_ROLE_INVITE_SUCCEED,
});

export const DELETE_PENDING_ROLE_INVITE_FAILED = 'DELETE_PENDING_ROLE_INVITE_FAILED';
export type DeletePendingRoleInviteFailureAction = Action<
    typeof DELETE_PENDING_ROLE_INVITE_FAILED,
    string
>;
export const deletePendingRoleInviteFailure = (
    payload: string,
): DeletePendingRoleInviteFailureAction => ({
    type: DELETE_PENDING_ROLE_INVITE_FAILED,
    payload,
});

export const RESEND_ROLES_EMAIL_INVITATION_REQUESTED = 'RESEND_ROLES_EMAIL_INVITATION_REQUESTED';
export type ResendRolesEmailInvitationRequestAction = Action<
    typeof RESEND_ROLES_EMAIL_INVITATION_REQUESTED,
    ResendRoleInvitation
>;
export const resendRolesEmailInvitationRequest = (
    payload: ResendRoleInvitation,
): ResendRolesEmailInvitationRequestAction => ({
    type: RESEND_ROLES_EMAIL_INVITATION_REQUESTED,
    payload,
});

export const RESEND_ROLES_EMAIL_INVITATION_SUCCEED = 'RESEND_ROLES_EMAIL_INVITATION_SUCCEED';
export type ResendRolesEmailInvitationSuccessAction = Action<
    typeof RESEND_ROLES_EMAIL_INVITATION_SUCCEED,
    number,
    string
>;
export const resendRolesEmailInvitationSuccess = (
    payload: number,
): ResendRolesEmailInvitationSuccessAction => ({
    type: RESEND_ROLES_EMAIL_INVITATION_SUCCEED,
    payload,
    meta: 'Email invitation re-sent',
});

export const RESEND_ROLES_EMAIL_INVITATION_FAILED = 'RESEND_ROLES_EMAIL_INVITATION_FAILED';
export type ResendRolesEmailInvitationFailureAction = Action<
    typeof RESEND_ROLES_EMAIL_INVITATION_FAILED,
    string
>;
export const resendRolesEmailInvitationFailure = (
    payload: string,
): ResendRolesEmailInvitationFailureAction => ({
    type: RESEND_ROLES_EMAIL_INVITATION_FAILED,
    payload,
});

export const RESEND_EMPLOYEE_EMAIL_INVITATION_REQUESTED =
    'RESEND_EMPLOYEE_EMAIL_INVITATION_REQUESTED';
export type ResendEmployeeEmailInvitationRequestAction = Action<
    typeof RESEND_EMPLOYEE_EMAIL_INVITATION_REQUESTED,
    number
>;
export const resendEmployeeEmailInvitationRequest = (
    invitationId: number,
): ResendEmployeeEmailInvitationRequestAction => ({
    type: RESEND_EMPLOYEE_EMAIL_INVITATION_REQUESTED,
    payload: invitationId,
});

export const RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED = 'RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED';
export type ResendEmployeeEmailInvitationSuccessAction = Action<
    typeof RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED,
    void,
    string
>;
export const resendEmployeeEmailInvitationSuccess =
    (): ResendEmployeeEmailInvitationSuccessAction => ({
        type: RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED,
        meta: 'Email invitation re-sent',
    });

export const RESEND_EMPLOYEE_EMAIL_INVITATION_FAILED = 'RESEND_EMPLOYEE_EMAIL_INVITATION_FAILED';
export type ResendEmployeeEmailInvitationFailureAction = Action<
    typeof RESEND_EMPLOYEE_EMAIL_INVITATION_FAILED,
    string
>;
export const resendEmployeeEmailInvitationFailure = (
    payload: string,
): ResendEmployeeEmailInvitationFailureAction => ({
    type: RESEND_EMPLOYEE_EMAIL_INVITATION_FAILED,
    payload,
});

export const ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED =
    'ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED';
export type AssignInvitedIndividualExecutiveRequestAction = Action<
    typeof ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED,
    InvitePendingIndividualExecutivesRequest
>;
export const assignInvitedIndividualExecutiveRequest = (
    invitedIndividual: InvitePendingIndividualExecutivesRequest,
): AssignInvitedIndividualExecutiveRequestAction => ({
    type: ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED,
    payload: { ...invitedIndividual },
});

export const ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED =
    'ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED';
export type AssignInvitedIndividualExecutiveSuccessAction = Action<
    typeof ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED,
    InvitePendingIndividualExecutivesRequest,
    string
>;
export const assignInvitedIndividualExecutiveSuccess = (
    payload: InvitePendingIndividualExecutivesRequest,
): AssignInvitedIndividualExecutiveSuccessAction => ({
    type: ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED,
    payload,
    meta: 'Role Added',
});

export const ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_FAILED =
    'ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_FAILED';
export type AssignInvitedIndividualExecutiveFailedAction = Action<
    typeof ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_FAILED,
    string
>;
export const assignInvitedIndividualExecutiveFailure = (
    payload: string,
): AssignInvitedIndividualExecutiveFailedAction => ({
    type: ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_FAILED,
    payload,
});

export const GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED =
    'GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED';
export type GetInvitedIndividualExecutiveRequestAction = Action<
    typeof GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED,
    number
>;
export const getInvitedIndividualExecutiveRequest = (
    organisation: number,
): GetInvitedIndividualExecutiveRequestAction => ({
    type: GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED,
    payload: organisation,
});

export const GET_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED = 'GET_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED';
export type GetInvitedIndividualExecutiveSuccessAction = Action<
    typeof GET_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED,
    { selectedOrganisationInvitedIndividualExecutive: InvitedExecutive[] }
>;
export const getInvitedIndividualExecutiveSuccess = (
    selectedOrganisationInvitedIndividualExecutive: InvitedExecutive[],
): GetInvitedIndividualExecutiveSuccessAction => ({
    type: GET_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED,
    payload: { selectedOrganisationInvitedIndividualExecutive },
});

export const GET_INVITED_INDIVIDUAL_EXECUTIVE_FAILED = 'GET_INVITED_INDIVIDUAL_EXECUTIVE_FAILED';
export type GetInvitedIndividualExecutiveFailedAction = Action<
    typeof GET_INVITED_INDIVIDUAL_EXECUTIVE_FAILED,
    string
>;
export const getInvitedIndividualExecutiveFailure = (
    payload: string,
): GetInvitedIndividualExecutiveFailedAction => ({
    type: GET_INVITED_INDIVIDUAL_EXECUTIVE_FAILED,
    payload,
});

export const EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_REQUESTED =
    'EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_REQUESTED';
export type EditBenefitInviteEmployeeWithRoleRequestAction = Action<
    typeof EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_REQUESTED,
    EditBenefitInviteByEmailWithRole
>;
export const editBenefitInviteEmployeeWithRoleRequest = (
    employee: EditBenefitInviteByEmailWithRole,
): EditBenefitInviteEmployeeWithRoleRequestAction => ({
    type: EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_REQUESTED,
    payload: { ...employee },
});

export const EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED =
    'EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED';
export type EditBenefitInviteEmployeeWithRoleSuccessAction = Action<
    typeof EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
    number,
    string
>;
export const editBenefitInviteEmployeeWithRoleSuccess = (
    contract: number,
): EditBenefitInviteEmployeeWithRoleSuccessAction => ({
    type: EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
    payload: contract,
    meta: 'Invitation email has been sent',
});

export const EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_FAILED =
    'EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_FAILED';
export type EditBenefitInviteEmployeeWithRoleFailureAction = Action<
    typeof EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_FAILED,
    string
>;
export const editInviteEmployeeWithRoleFailure = (
    payload: string,
): EditBenefitInviteEmployeeWithRoleFailureAction => ({
    type: EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_FAILED,
    payload,
});

export const GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUESTED =
    'GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUEST';
export type GetEmployeesExpiredInvitationsRequestAction = Action<
    typeof GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUESTED
>;
export const getEmployeesExpiredInvitationsRequest =
    (): GetEmployeesExpiredInvitationsRequestAction => ({
        type: GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUESTED,
    });

export const GET_EMPLOYEES_EXPIRED_INVITATIONS_SUCCEED =
    'GET_EMPLOYEES_EXPIRED_INVITATIONS_SUCCEED';
export type GetEmployeesExpiredInvitationsSuccessAction = Action<
    typeof GET_EMPLOYEES_EXPIRED_INVITATIONS_SUCCEED,
    { employeesExpiredInvitations: EmployeeInvitation[] }
>;
export const getEmployeesExpiredInvitationsSuccess = (
    employeesExpiredInvitations: EmployeeInvitation[],
): GetEmployeesExpiredInvitationsSuccessAction => ({
    type: GET_EMPLOYEES_EXPIRED_INVITATIONS_SUCCEED,
    payload: { employeesExpiredInvitations },
});

export const GET_EMPLOYEES_EXPIRED_INVITATIONS_FAILED = 'GET_EMPLOYEES_EXPIRED_INVITATIONS_FAILED';
export type GetEmployeesExpiredInvitationsFailureAction = Action<
    typeof GET_EMPLOYEES_EXPIRED_INVITATIONS_FAILED,
    string
>;
export const getEmployeesExpiredInvitationsFailure = (
    payload: string,
): GetEmployeesExpiredInvitationsFailureAction => ({
    type: GET_EMPLOYEES_EXPIRED_INVITATIONS_FAILED,
    payload,
});

export const RESEND_EMPLOYEE_EXPIRED_INVITATION_REQUESTED =
    'RESEND_EMPLOYEE_EXPIRED_INVITATION_REQUESTED';
export type ResendEmployeeExpiredInvitationRequestAction = Action<
    typeof RESEND_EMPLOYEE_EXPIRED_INVITATION_REQUESTED,
    number
>;
export const resendEmployeeExpiredInvitationRequest = (
    invitationId: number,
): ResendEmployeeExpiredInvitationRequestAction => ({
    type: RESEND_EMPLOYEE_EXPIRED_INVITATION_REQUESTED,
    payload: invitationId,
});

export const RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED =
    'RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED';
export type ResendEmployeeExpiredInvitationSuccessAction = Action<
    typeof RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
    void,
    string
>;
export const resendEmployeeExpiredInvitationSuccess =
    (): ResendEmployeeExpiredInvitationSuccessAction => ({
        type: RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
        meta: 'Email invitation re-sent',
    });

export const RESEND_EMPLOYEE_EXPIRED_INVITATION_FAILED =
    'RESEND_EMPLOYEE_EXPIRED_INVITATION_FAILED';
export type ResendEmployeeExpiredInvitationFailureAction = Action<
    typeof RESEND_EMPLOYEE_EXPIRED_INVITATION_FAILED,
    string
>;
export const resendExpiredEmailInvitationFailure = (
    payload: string,
): ResendEmployeeExpiredInvitationFailureAction => ({
    type: RESEND_EMPLOYEE_EXPIRED_INVITATION_FAILED,
    payload,
});

export const DELETE_EMPLOYEE_EXPIRED_INVITATION_REQUESTED =
    'DELETE_EMPLOYEE_EXPIRED_INVITATION_REQUESTED';
export type DeleteEmployeesExpiredInvitationRequestAction = Action<
    typeof DELETE_EMPLOYEE_EXPIRED_INVITATION_REQUESTED,
    string
>;
export const deleteEmployeesExpiredInvitationRequest = (
    email: string,
): DeleteEmployeesExpiredInvitationRequestAction => ({
    type: DELETE_EMPLOYEE_EXPIRED_INVITATION_REQUESTED,
    payload: email,
});

export const DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED =
    'DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED';
export type DeleteEmployeesExpiredInvitationSucceesAction = Action<
    typeof DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED
>;
export const deleteEmployeesExpiredInvitationSuccees =
    (): DeleteEmployeesExpiredInvitationSucceesAction => ({
        type: DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
    });

export const DELETE_EMPLOYEE_EXPIRED_INVITATION_FAILED =
    'DELETE_EMPLOYEE_EXPIRED_INVITATION_FAILED';
export type DeleteEmployeesExpiredInvitationFailureAction = Action<
    typeof DELETE_EMPLOYEE_EXPIRED_INVITATION_FAILED
>;
export const deleteEmployeesExpiredInvitationFailure =
    (): DeleteEmployeesExpiredInvitationFailureAction => ({
        type: DELETE_EMPLOYEE_EXPIRED_INVITATION_FAILED,
    });

export const GET_ROLES_EXPIRED_INVITATIONS_REQUESTED = 'GET_ROLES_EXPIRED_INVITATIONS_REQUESTED';
export type GetRolesExpiredInvitationsRequestAction = Action<
    typeof GET_ROLES_EXPIRED_INVITATIONS_REQUESTED,
    number
>;
export const getRolesExpiredInvitationsRequest = (
    contractId: number,
): GetRolesExpiredInvitationsRequestAction => ({
    type: GET_ROLES_EXPIRED_INVITATIONS_REQUESTED,
    payload: contractId,
});

export const GET_ROLES_EXPIRED_INVITATIONS_SUCCEED = 'GET_ROLES_EXPIRED_INVITATIONS_SUCCEED';
export type GetRolesExpiredInvitationsSuccessAction = Action<
    typeof GET_ROLES_EXPIRED_INVITATIONS_SUCCEED,
    { rolesExpiredInvitations: RoleInvitation[] }
>;
export const getRolesExpiredInvitationsSuccess = (
    rolesExpiredInvitations: RoleInvitation[],
): GetRolesExpiredInvitationsSuccessAction => ({
    type: GET_ROLES_EXPIRED_INVITATIONS_SUCCEED,
    payload: { rolesExpiredInvitations },
});

export const GET_ROLES_EXPIRED_INVITATIONS_FAILED = 'GET_ROLES_EXPIRED_INVITATIONS_FAILED';
export type GetRolesExpiredInvitationsFailureAction = Action<
    typeof GET_ROLES_EXPIRED_INVITATIONS_FAILED,
    string
>;
export const getRolesExpiredInvitationsFailure = (
    payload: string,
): GetRolesExpiredInvitationsFailureAction => ({
    type: GET_ROLES_EXPIRED_INVITATIONS_FAILED,
    payload,
});

export type InviteActions =
    | InviteEmployeeRequestAction
    | InviteEmployeeSuccessAction
    | InviteEmployeeFailureAction
    | InviteEmployeeWithRoleRequestAction
    | InviteEmployeeWithRoleSuccessAction
    | InviteEmployeeWithRoleFailureAction
    | InviteNewPotentialExecutiveSuccessAction
    | InviteNewPotentialExecutiveRequestAction
    | InviteNewPotentialExecutiveFailureAction
    | InvitePotentialExecutivesRequestAction
    | InvitePotentialExecutivesSuccessAction
    | InvitePotentialExecutivesFailureAction
    | GetEmployeesPendingInvitationsRequestAction
    | GetEmployeesPendingInvitationsSuccessAction
    | GetEmployeesPendingInvitationsFailureAction
    | DeleteEmployeesPendingInvitationsRequestAction
    | DeleteEmployeesPendingInvitationsSucceesAction
    | DeleteEmployeesPendingInvitationsFailureAction
    | GetExistingOrgPendingInvitationsRequestAction
    | GetExistingOrgPendingInvitationsSuccessAction
    | GetExistingOrgPendingInvitationsFailureAction
    | DeleteExistingOrgPendingInvitationsRequestAction
    | DeleteExistingOrgPendingInvitationsSucceesAction
    | DeleteExistingOrgPendingInvitationsFailureAction
    | GetNewOrgPendingInvitationsRequestAction
    | GetNewOrgPendingInvitationsSuccessAction
    | GetNewOrgPendingInvitationsFailureAction
    | DeleteNewOrgPendingInvitationsRequestAction
    | DeleteNewOrgPendingInvitationsSucceesAction
    | DeleteNewOrgPendingInvitationsFailureAction
    | GetRolesPendingInvitationsRequestAction
    | GetRolesPendingInvitationsSuccessAction
    | GetRolesPendingInvitationsFailureAction
    | DeletePendingRoleInviteRequestAction
    | DeletePendingRoleInviteSuccessAction
    | DeletePendingRoleInviteFailureAction
    | ResendRolesEmailInvitationRequestAction
    | ResendRolesEmailInvitationSuccessAction
    | ResendRolesEmailInvitationFailureAction
    | ResendEmployeeEmailInvitationRequestAction
    | ResendEmployeeEmailInvitationSuccessAction
    | ResendEmployeeEmailInvitationFailureAction
    | AssignInvitedIndividualExecutiveRequestAction
    | AssignInvitedIndividualExecutiveSuccessAction
    | AssignInvitedIndividualExecutiveFailedAction
    | GetInvitedIndividualExecutiveRequestAction
    | GetInvitedIndividualExecutiveSuccessAction
    | GetInvitedIndividualExecutiveFailedAction
    | EditBenefitInviteEmployeeWithRoleRequestAction
    | EditBenefitInviteEmployeeWithRoleSuccessAction
    | EditBenefitInviteEmployeeWithRoleFailureAction
    | GetEmployeesExpiredInvitationsRequestAction
    | GetEmployeesExpiredInvitationsSuccessAction
    | GetEmployeesExpiredInvitationsFailureAction
    | ResendEmployeeExpiredInvitationRequestAction
    | ResendEmployeeExpiredInvitationSuccessAction
    | ResendEmployeeExpiredInvitationFailureAction
    | DeleteEmployeesExpiredInvitationRequestAction
    | DeleteEmployeesExpiredInvitationSucceesAction
    | DeleteEmployeesExpiredInvitationFailureAction
    | GetRolesExpiredInvitationsRequestAction
    | GetRolesExpiredInvitationsSuccessAction
    | GetRolesExpiredInvitationsFailureAction;
