import * as React from 'react';
import { Button, Comment, List } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import { UserOutlined } from '@ant-design/icons';

import { ButtonWrapper } from 'common/components/styled';
import { UploadContractNoteFile, SubmitButton, PaddedSection } from 'common/components';
import { DrawerContainer } from 'common/components/DrawerContainer';
import { openModal } from 'common/helpers/modal';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { EditBenefitNote } from 'features/edit/components/EditBenefitNote';
import { NoteFileData } from 'features/upload/models/uploadModels';
import {
    Antd3Form,
    Antd3Icon,
    StyledAntd3Form,
    Antd3FormProps,
} from 'common/components/deprecated/antd3';
import { TextArea, ResetButton } from 'common/components/deprecated/form';

import { BenefitNotesProps as BenefitNotesContainerProps } from '../containers/BenefitNotesContainer';
import { BenefitNoteData, FileStatus } from '../models/benefitModels';

type BenefitNotesProps = BenefitNotesContainerProps & Antd3FormProps;

interface BenefitNoteState {
    editingBenefitNote?: BenefitNoteData;
    fileList: IUploadFile[];
}

class BenefitNotesForm extends React.Component<BenefitNotesProps, BenefitNoteState> {
    public state: BenefitNoteState = {
        fileList: [],
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
            if (!this.props.form.getFieldValue('note') && this.state.fileList.length == 0) {
                return showNotification({ text: 'Please enter a note or upload a file.' });
            }

            if (this.state.fileList.length > 10) {
                return showNotification({ text: 'A maximum of 10 uploaded files' });
            }

            if (this.state.fileList.length) {
                let count = 1;

                this.state.fileList.forEach((file: IUploadFile) => {
                    const keyName = 'file' + count;
                    values[keyName] = file;
                    count += 1;
                });
            }

            this.props.form.resetFields();
            this.setState({
                fileList: [],
            });

            return this.props.createBenefitNoteRequest({
                ...values,
                contract: this.props.contractId,
                benefit: this.props.benefitId,
                organisation: this.props.organisationId,
            });
        });
    };

    private updateFormStateAdd = (file: IUploadFile) => {
        this.setState({
            fileList: [...this.state.fileList, file],
        });
    };

    private updateFormStateRemove = (fileToRemove: IUploadFile) => {
        this.setState({
            fileList: this.state.fileList.filter((file) => file !== fileToRemove),
        });
    };

    private openEditForm = (e: React.FormEvent, id: number) => {
        e.preventDefault();

        this.setState({
            editingBenefitNote: this.props.benefitNotes.find((note) => note.id === id),
        });

        this.props.openForm();
    };

    private openDeleteModal = (
        e: React.FormEvent,
        contract: number,
        benefit: number,
        id: number,
    ) => {
        e.preventDefault();

        openModal({
            title: `Are you sure you want to permanently delete this note?`,
            callback: () => this.props.deleteBenefitNoteRequest({ contract, benefit, id }),
        });
    };

    public render(): JSX.Element {
        const { form, benefitNotes } = this.props;

        const fileList = (files: NoteFileData[]) => (
            <>
                {files.map((file: NoteFileData) =>
                    file.fileStatus !== FileStatus.UNTESTED ? (
                        <li key={file.id}>
                            <a href={file.file}>{file.fileName}</a>
                        </li>
                    ) : (
                        <li key={file.id}>{file.fileName}</li>
                    ),
                )}
            </>
        );

        const formatUpdatedAt = (datetime: string) => {
            const date = datetime.split('T')[0];
            const time = datetime.split('T')[1].split(':').slice(0, 2).join(':');

            return `${date}, ${time}`;
        };

        return (
            <>
                <StyledAntd3Form onSubmit={this.handleSubmit} header="Create Private Benefit Note">
                    <ResetButton form={form} />
                    <TextArea
                        form={form}
                        id="note"
                        placeholder="Benefit Note"
                        required={false}
                        rows={4}
                    />
                    <UploadContractNoteFile
                        form={form}
                        id="benefitNoteFile"
                        addFile={this.updateFormStateAdd}
                        removeFile={this.updateFormStateRemove}
                        uploadedFiles={this.state.fileList}
                        description={'Private Benefit Note Files'}
                    />

                    <SubmitButton value="Create Benefit Note" />
                </StyledAntd3Form>
                {benefitNotes && (
                    <PaddedSection>
                        <List
                            dataSource={benefitNotes}
                            renderItem={(item: BenefitNoteData) => (
                                <List.Item
                                    key={item.id}
                                    actions={[
                                        <>
                                            <ButtonWrapper>
                                                <Button
                                                    icon={<Antd3Icon type="edit" />}
                                                    size="small"
                                                    onClick={(e: React.FormEvent) =>
                                                        this.openEditForm(e, item.id)
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={(e: React.FormEvent) =>
                                                        this.openDeleteModal(
                                                            e,
                                                            this.props.contractId,
                                                            this.props.benefitId,
                                                            item.id,
                                                        )
                                                    }
                                                    type="danger"
                                                    icon={<Antd3Icon type="delete" />}
                                                    size="small"
                                                >
                                                    Delete
                                                </Button>
                                            </ButtonWrapper>
                                        </>,
                                    ]}
                                >
                                    <Comment
                                        author={item.authoredBy}
                                        avatar={<UserOutlined />}
                                        content={
                                            <>
                                                <p>{item.note}</p>
                                                {item.files ? fileList(item.files) : null}
                                            </>
                                        }
                                        datetime={formatUpdatedAt(item.updatedAt)}
                                    />
                                </List.Item>
                            )}
                        />
                    </PaddedSection>
                )}

                {this.state.editingBenefitNote && (
                    <DrawerContainer
                        title="Edit Benefit Note"
                        onClose={this.props.closeForm}
                        visible={this.props.formVisible}
                    >
                        <EditBenefitNote
                            contractId={this.props.contractId}
                            benefitId={this.props.benefitId}
                            benefitNote={this.state.editingBenefitNote}
                        />
                    </DrawerContainer>
                )}
            </>
        );
    }
}
export const BenefitNotes = Antd3Form.create({})(BenefitNotesForm);
