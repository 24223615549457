import { RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';

import { BenefitRoleName, DeliveryDate, FileStatus } from 'features/benefit/models/benefitModels';
import {
    QuestionnaireAnswer,
    QuestionnaireQuestion,
    SubmittableQuestionnaireType,
} from 'features/benefit/models/questionnaireModels';

export interface UploadParams {
    benefitId: string;
    contractId: string;
}

export type UploadRouteParams = RouteComponentProps<UploadParams>;

export type ScoreAction = (score: ScoreRequest) => void;

export enum UploadTitles {
    Evidence = 'Evidence',
    PendingEvidence = 'Pending Evidence',
    ApprovedEvidence = 'Approved Evidence',
    Score = 'Feedback',
    ApprovedScore = 'Approved Feedback',
}

export enum UploadPaths {
    Organisation = '/upload/organisation',
    Evidence = '/upload/evidence',
    PendingEvidence = '/upload/pending-evidence',
    ApprovedEvidence = '/upload/approved-evidence',
    Score = '/upload/feedback',
    ApprovedScore = '/upload/approved-feedback',
}

export const assessorScoreOptions = [
    { name: 'Exceeded', value: 'EXCEEDED' },
    { name: 'Met', value: 'MET' },
    { name: 'Not Met (Organisation)', value: 'NOT_MET_ORGANISATION' },
    { name: 'Not Met (Supplier)', value: 'NOT_MET_SUPPLIER' },
    { name: 'Not Met (Delivery Partner)', value: 'NOT_MET_DELIVERY_PARTNER' },
];

export const supplierScoreOptions = [
    { name: 'Good', value: 'GOOD' },
    { name: 'Satisfactory', value: 'SATISFACTORY' },
    { name: 'Improvements Possible', value: 'IMPR_POSS' },
];

export const scoreOptions = new Map([
    [BenefitRoleName.Assessor, assessorScoreOptions],
    [BenefitRoleName.Supplier, supplierScoreOptions],
]);

export interface BenefitIds {
    contractId: number;
    benefitId: number;
}

export interface ScoreData extends BenefitIds {
    organisationId?: number;
}

export interface OrganisationIdentity {
    organisationId: number;
}

export type ScoresRequest = BenefitIds & OrganisationIdentity;

export type BenefitChangeSuggestionsDeliveryDateAction = 'create' | 'update' | 'delete';

type BenefitChangeSuggestionsDeliveryDateBase = {
    id?: number;
    title?: string;
    isStart?: boolean;
    isFinal?: boolean;
    isApproved?: boolean;
};

export interface BenefitChangeSuggestionsDeliveryDate
    extends BenefitChangeSuggestionsDeliveryDateBase {
    action: BenefitChangeSuggestionsDeliveryDateAction;
    date?: string;
}

export interface BenefitChangeSuggestionsFormItemDeliveryDate
    extends BenefitChangeSuggestionsDeliveryDateBase {
    action: BenefitChangeSuggestionsDeliveryDateAction;
    date?: Moment;
    prevDate?: Moment;
    prevTitle?: string;
    needsUserAction: boolean;
}

export interface BenefitChangeSuggestionsAcceptanceFormDeliveryDate
    extends BenefitChangeSuggestionsDeliveryDateBase {
    action?: BenefitChangeSuggestionsDeliveryDateAction;
    date?: string;
    prevDate?: string;
    prevTitle?: string;
    isFromSuggestion?: boolean;
}

export type BenefitChangeSuggestionsBenefitPriorityAction = 'create' | 'update';

export type BenefitChangeSuggestionsBenefitValueAction = 'create' | 'update';

export type BenefitChangeSuggestionsBenefitLabelAction = 'create' | 'update';

export type BenefitChangeSuggestionsBenefitLocationAction = 'create' | 'update' | 'delete';

export type BenefitChangeSuggestionLocation = {
    id?: number;
    location?: number;
};

export type BenefitChangeSuggestionValue = {
    value?: number;
};
export type BenefitChangeSuggestionLabel = {
    label?: string;
};
export interface BenefitChangeSuggestionFormItemValue extends BenefitChangeSuggestionValue {
    action?: BenefitChangeSuggestionsBenefitValueAction;
    prevValue?: number;
}

export interface BenefitChangeSuggestionFormItemLabel extends BenefitChangeSuggestionLabel {
    action?: BenefitChangeSuggestionsBenefitLabelAction;
    prevValue?: string;
}

export interface BenefitChangeSuggestionFormItemLocation extends BenefitChangeSuggestionLocation {
    action?: BenefitChangeSuggestionsBenefitLocationAction;
    prevLocation?: number;
}

export interface BenefitChangeSuggestionAcceptanceFormBenefitLocation
    extends BenefitChangeSuggestionLocation {
    action: BenefitChangeSuggestionsBenefitLocationAction;
    prevLocation?: number;
}

export type BenefitChangeSuggestionsPriority = {
    id?: number;
    priority: number;
    name: string;
    value: number;
};

export interface BenefitChangeSuggestionsFormItemBenefitPriority
    extends BenefitChangeSuggestionsPriority {
    action: BenefitChangeSuggestionsBenefitPriorityAction;
    prevValue?: number;
}

export interface BenefitChangeSuggestionsAcceptanceFormBenefitPriority
    extends BenefitChangeSuggestionsPriority {
    action?: BenefitChangeSuggestionsBenefitPriorityAction;
    prevValue?: number;
}

export interface BenefitChangeSuggestions {
    deliveryDates?: BenefitChangeSuggestionsDeliveryDate[];
    benefitPriorities?: BenefitChangeSuggestionsPriority[];
    benefitLocation?: number;
    benefitValue?: number;
    benefitLabel?: string;
}

export interface Evidence {
    approved: boolean;
    approvedAt: string;
    approvedBy: string;
    author: string;
    createdAt: string;
    declineMessage: string;
    declined: boolean;
    declinedBy: string;
    deliveryDate: number;
    description: string;
    files?: FileData[];
    id: number;
    [SubmittableQuestionnaireType.EVIDENCE]: QuestionnaireQuestion[];
    questionnaireAnswer?: QuestionnaireAnswer[];
    agreedToGdpr?: boolean;
    benefitChangeSuggestions?: BenefitChangeSuggestions;
}

export interface EvidenceData extends OrganisationIdentity {
    benefitId: number;
    contractId: number;
    deliveryDateId: number;
    evidenceId?: number;
    id?: number;
}

export interface CreateEvidenceRequest extends BenefitIds, OrganisationIdentity {
    deliveryDateId: number;
    agreedToGdpr?: boolean;
    name: string;
    questionnaireAnswer: QuestionnaireAnswer[];
    benefitChangeSuggestions?: BenefitChangeSuggestions;
    selfAssessment?: boolean;
}

export interface CreateEvidenceResponse extends BenefitIds, OrganisationIdentity {
    deliveryDateId: number;
    evidenceId?: number;
    id?: number;
}

export interface UpdateEvidenceRequest extends CreateEvidenceRequest {
    evidenceId?: number;
}

export interface EvidenceApproval extends EvidenceData {
    evidenceId: number;
    selfAssessment?: boolean;
}

export interface EvidenceDecline extends EvidenceApproval {
    declineMessage: string;
}

export interface EditEvidenceFileDescription extends BenefitIds, OrganisationIdentity {
    fileId: number;
    description: string;
}

export interface ScoreApproval extends BenefitIds, OrganisationIdentity {
    scoreId: number;
}

export interface ScoreDecline extends ScoreApproval, OrganisationIdentity {
    declineMessage: string;
}

export interface ScoreRequest extends BenefitIds, OrganisationIdentity {
    file: File | Blob;
    fileList: RequestFile[];
    files: FileData[];
    score: string;
    scoreId: number;
    userId: number;
    name: string;
    questionnaireAnswer: QuestionnaireAnswer[];
    agreedToGdpr?: boolean;
    selfAssessment?: boolean;
}

export interface EditScoreFileDescription extends BenefitIds, OrganisationIdentity {
    fileId: number;
    description: string;
}

export interface DeleteScoreFileRequest extends ScoresRequest {
    scoreId: number;
    fileId: number;
}

export interface DeleteEvidenceFileRequest extends EvidenceData {
    fileId: number;
}

export type CreateScoreResponse = Score & ScoreRequest;

export interface ScorePath extends BenefitIds, OrganisationIdentity {
    scoreId?: number;
}

export interface ContractPath extends OrganisationIdentity {
    contractId: number;
}

export interface Score {
    approved: boolean;
    approvedAt: string;
    approvedBy: string;
    author: string;
    benefit: number;
    createdAt: string;
    declined: boolean;
    declineMessage: string;
    declinedBy: string;
    description: string;
    files?: FileData[];
    id: number;
    [SubmittableQuestionnaireType.SCORE]: QuestionnaireQuestion[];
    questionnaireAnswer?: QuestionnaireAnswer[];
    agreedToGdpr?: boolean;
}

export interface FileData {
    id: number;
    uid: string;
    evidence?: number;
    file: string;
    fileStatus: FileStatus;
    name: string;
    score?: number;
    fileName?: string;
    project?: number;
    createdAt?: string;
    updatedAt?: string;
}

export interface RequestFile extends File {
    originFileObj: File;
    type: string;
}

export interface GetMyEvidenceRequest extends BenefitIds, OrganisationIdentity {
    deliveryDateId: number;
}

export interface MyEvidenceResponse extends Evidence {
    contractId?: number;
    benefitId?: number;
    deliveryDateId?: number;
    organisationId?: number;
}

export interface UploadBenefit {
    category: number;
    categoryName: string;
    contract: number;
    deliveryDates: DeliveryDate[];
    description: string;
    finalDeliveryDate: string;
    referenceNumber?: string;
    id: number;
    organisation: number;
    outcome: string;
    points: number;
    quantity: number;
    benefitTemplate: number;
    startDate: string;
    status: string;
    value: number;
}

interface NoteFileData {
    fileName: string;
    file: string;
    fileStatus: FileStatus;
    note: number;
    id: number;
}

export interface ContractManagerDetail {
    firstName: string;
    lastName: string;
    email: string;
    id: number;
}
