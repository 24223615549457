import { SET_PREVIOUS_PATH } from './../../../common/actions/navigationAction';
import { AppAction } from 'common/actions/appAction';

import * as actions from '../actions/checkActions';
import { CheckBenefit, CheckActiveBenefit } from '../models/checkModel';

export interface CheckState {
    checkEvidences: CheckBenefit[];
    checkActiveBenefits: CheckActiveBenefit[];
    checkScores: CheckBenefit[];
    previousPath?: string;
}

const defaultCheckState = {
    checkEvidences: [],
    checkActiveBenefits: [],
    checkScores: [],
    previousPath: '',
};

export const checkReducer = (state: CheckState = defaultCheckState, action: AppAction) => {
    switch (action.type) {
        case actions.GET_CHECK_EVIDENCES_BENEFITS_SUCCEED:
        case actions.GET_CHECK_SCORES_BENEFITS_SUCCEED:
        case actions.GET_CHECK_ACTIVE_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case SET_PREVIOUS_PATH:
            return {
                ...state,
                previousPath: action.payload,
            };
        default:
            return state;
    }
};
