const MIN_PHONE_NUMBER_LENGTH = 8;
const MAX_PHONE_NUMBER_LENGTH = 16;

const VALID_PHONE_NUMBER = /^(\+\d{1,2}\(\d{1,4}\)\d{1,})|(\d{8,})$/;

export const isPhoneNumberValid = (phoneNumber: string): boolean =>
    phoneNumberContainsOnlyValidCharacters(phoneNumber) &&
    phoneNumber.length >= MIN_PHONE_NUMBER_LENGTH &&
    phoneNumber.length <= MAX_PHONE_NUMBER_LENGTH;

export const phoneNumberContainsOnlyValidCharacters = (phoneNumber: string): boolean =>
    VALID_PHONE_NUMBER.test(phoneNumber);
