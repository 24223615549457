import moment from 'moment';

import { Input, Select, TextArea } from 'common/components';
import { dateFormat } from 'config/config';
import { BenefitChangeSuggestionsFormValues } from 'features/upload/components/BenefitChangeSuggestionsFormItem/BenefitChangeSuggestionsFormItem';

import { Benefit, FormDeliveryDate } from './models/benefitModels';
import {
    QuestionnaireAnswer,
    QuestionnaireQuestion,
    QuestionnaireQuestionType,
    BenefitQuestionnaireType,
    FormQuestionnaireQuestion,
} from './models/questionnaireModels';

export const getEmptyQuestionnaireQuestion = (index: number): FormQuestionnaireQuestion => ({
    index,
    type: QuestionnaireQuestionType.TEXT,
    label: '',
    body: '',
});

export const getEmptyDeliveryDate = (): FormDeliveryDate => ({
    date: undefined,
    title: '',
});

export const formatBenefitQuestionnairesToArrays = (
    formValues: Partial<Benefit>,
): Partial<Benefit> => {
    const formattedFormValues = formValues;

    Object.values(BenefitQuestionnaireType).forEach((questionnaireType) => {
        if (formattedFormValues[questionnaireType]) {
            formattedFormValues[questionnaireType] = Object.values(
                formattedFormValues[questionnaireType] as QuestionnaireQuestion[],
            ).map((question, index) =>
                question.choices && Object.values(question.choices).length
                    ? {
                          ...question,
                          index,
                          choices: Object.values(question.choices),
                      }
                    : {
                          ...question,
                          index,
                      },
            );
        } else {
            formattedFormValues[questionnaireType] = null;
        }
    });

    return formattedFormValues;
};

export const getQuestionnaireAnswerInitialFormValues = (
    questions?: QuestionnaireQuestion[],
    answers?: QuestionnaireAnswer[],
) =>
    questions?.map((question) => ({
        index: question.index,
        questionId: question.id,
        value: answers?.find((answer) => answer.questionId === question.id)?.value,
    }));

export const getQuestionnaireQuestionInput = (
    name: number,
    question: QuestionnaireQuestion,
): JSX.Element => {
    const formFieldProps = {
        name: [name, 'value'],
        label: question.body,
        required: !question.isOptional,
    };

    switch (question.type) {
        case QuestionnaireQuestionType.NUMERICAL:
            return <Input {...formFieldProps} type="number" />;
        case QuestionnaireQuestionType.CHOICE:
            return <Select {...formFieldProps} options={question.choices!} />;
        case QuestionnaireQuestionType.MULTIPLE_CHOICE:
            return <Select {...formFieldProps} mode="multiple" options={question.choices!} />;
        case QuestionnaireQuestionType.TEXT:
        default:
            return <TextArea {...formFieldProps} />;
    }
};

export const formatQuestionnaireAnswerForApi = (
    questions: QuestionnaireQuestion[],
    answers?: QuestionnaireAnswer[],
): QuestionnaireAnswer[] | undefined => {
    if (!answers) {
        return undefined;
    }

    const formattedAnswers: QuestionnaireAnswer[] = [];

    questions.forEach((question) => {
        const answer = answers.find((answer) => answer.questionId === question.id);

        if (answer) {
            switch (question.type) {
                case QuestionnaireQuestionType.MULTIPLE_CHOICE:
                    formattedAnswers.push({
                        ...answer,
                        value: (answer.value as any as string[] | undefined)?.length
                            ? question.choices!.filter((choice) =>
                                  (answer.value as any as string[]).includes(choice.id),
                              )
                            : undefined,
                    });
                    return;
                case QuestionnaireQuestionType.CHOICE:
                    formattedAnswers.push({
                        ...answer,
                        value: (answer.value as any as string[])?.length
                            ? question.choices!.filter((choice) =>
                                  (answer.value as any as string[])?.includes(choice.id),
                              )[0]
                            : undefined,
                    });
                    return;
                default:
                    formattedAnswers.push(answer as QuestionnaireAnswer);
                    return;
            }
        }
    });

    return formattedAnswers;
};

export const formatBenefitChangeSuggestionsForApi = (
    initialValues?: BenefitChangeSuggestionsFormValues,
    values?: BenefitChangeSuggestionsFormValues,
    benefitValue?: number,
    benefitLabel?: string,
) => {
    const deliveryDates = values?.deliveryDates
        ?.filter((deliveryDate) => {
            if (['create', 'delete'].includes(deliveryDate.action)) {
                return true;
            } else {
                const initialValue = initialValues?.deliveryDates?.find(
                    (date) => date.id === deliveryDate.id,
                );

                return (
                    moment(deliveryDate?.date).format(dateFormat) !==
                        moment(initialValue?.date).format(dateFormat) ||
                    deliveryDate?.title !== initialValue?.title
                );
            }
        })
        .map((deliveryDate) => ({
            ...deliveryDate,
            id: deliveryDate.id || null, // backend requires null (can't be undefined) if action === 'create'
            prevTitle: undefined,
            date: moment(deliveryDate.date).format(dateFormat),
            prevDate: undefined,
            needsUserAction: undefined,
        }));

    const benefitPriorities = values?.benefitPriorities
        ?.filter((benefitPriority) => benefitPriority.prevValue !== benefitPriority.value)
        .map((benefitPriority) => ({
            id: benefitPriority.id,
            priority: benefitPriority.priority,
            value: benefitPriority.value,
        }));

    const benefitLocation = values?.benefitLocation?.location;
    // const benefitValue = values?.benefitValue?.benefitChangeSuggestions?.benefitValue; // Please forgive me

    return {
        deliveryDates,
        benefitPriorities,
        benefitLocation,
        benefitValue,
        benefitLabel,
    };
};
