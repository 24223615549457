import { Component, ReactElement } from 'react';
import { Select, AutoComplete as AntdAutoComplete, Tooltip } from 'antd';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

import { fontSizes } from 'theme/variables';
import { AppState } from 'common/appState';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { selectBenefitContract } from 'features/benefit/actions';
import { FormFieldIds } from 'common/helpers/utils';
import { Organisation } from 'features/organisation/models/organisationModels';
import { ContractPriorities } from 'features/contract/models/contractModels';
import { Antd3Form, Antd3Icon, Antd3InputProps } from 'common/components/deprecated/antd3';

import {
    AutocompleteField,
    AutocompleteData,
    Filters,
    RequestParams,
} from '../models/paginationModel';
import {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
} from '../actions/paginationActions';
import {
    getAutocompleteResults,
    getAutocompleteFetchingStatus,
    getInitialValueFetchingStatus,
    getInitialValueData,
} from '../selectors/paginationSelectors';
import { CustomOption } from './CustomOption';

const GlobalSelectOptionStyle = createGlobalStyle`
  && li.ant-select-dropdown-menu-item {
        white-space: normal;
  }
`;

const Center = styled.div`
    text-align: center;
`;

const AutoComplete = styled(AntdAutoComplete)`
    && {
        font-size: ${fontSizes.small};
    }
`;

interface AutocompleteStateProps {
    results: AutocompleteData[];
    isFetchingResults: boolean;
    initialValueData?: AutocompleteData;
    isFetchingInitialValueData: boolean;
}

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteProps extends Antd3InputProps {
    autocompleteField: AutocompleteField;
    initialValue?: number | string;
    isFetchingInitialValueData?: boolean;
    notFoundContent?: string;
    onChange?(value: number): void;
    onSelect?(value: string | number): void;
    getAutocompleteData?(value: AutocompleteData | Organisation | ContractPriorities): void;
    onRemove?(): void;
    multiple?: boolean;
    searchParams?: Filters;
    defaultValue?: number | string;
    showLabel?: boolean;
    selectOnly?: boolean;
    onDeselectSelection?(value: number): void;
    requestParams?: RequestParams;
}

type Props = AutocompleteProps & AutocompleteStateProps & AutocompleteDispatchProps;

export class Autocomplete extends Component<Props> {
    private requestAutocomplete = (searchTerm: string = '') =>
        this.props.autocompleteRequest({
            searchTerm,
            autocompleteField: this.props.autocompleteField,
            searchParams: this.props.searchParams,
            requestParams: this.props.requestParams,
        });

    private handleSearch = (searchTerm: string) => this.requestAutocomplete(searchTerm);

    private onFocus = () => this.requestAutocomplete();

    public componentDidMount(): void {
        const { autocompleteField, initialValue: id } = this.props;

        this.props.clearInitialValueData(autocompleteField);
        if (typeof id === 'number' && id) {
            this.props.getInitialValueRequest({
                autocompleteField,
                id,
            });
        }
    }

    public onSelect = (value: string) => {
        if (/[a-zA-Z]/.test(value)) {
            this.props.onSelect && this.props.onSelect(value);

            const selectedReferenceNumber = this.props.results.find(
                ({ referenceNumber }) => referenceNumber === value,
            );

            this.props.getAutocompleteData &&
                this.props.getAutocompleteData(selectedReferenceNumber!);
        } else {
            const numberValue = Number.parseInt(value, 10);

            // ReactElement<any> is type from antd
            this.props.onSelect && this.props.onSelect(numberValue);

            const selectedResult = this.props.results.find(({ id }) => id === numberValue);

            this.props.getAutocompleteData && this.props.getAutocompleteData(selectedResult!);
        }
    };

    private onSelectSelection = (value: string, e: ReactElement<any>) => {
        // ReactElement<any> is type from antd
        this.onSelect(value);

        const { organisation, contract } = e.props.children.props;

        if (e && organisation) {
            this.props.selectContractOrganisation(organisation);
        }

        if (e && contract) {
            this.props.selectBenefitContract(contract);
        }
    };

    public onRemove = (value: any) => {
        if (!value && this.props.onRemove) {
            this.props.onRemove();
        }
    };

    public setOptionValue = (
        fieldId: string,
        value: string,
        key: string,
        referenceNumber?: string,
    ) => {
        switch (fieldId) {
            case FormFieldIds.Title:
            case FormFieldIds.ContractTitle:
                return value;
            case FormFieldIds.ReferenceNumber:
            case FormFieldIds.ContractReferenceNumber:
            case FormFieldIds.QuickFilterBenefitReferenceNumber:
                return referenceNumber;
            default:
                return key;
        }
    };

    public setOptionLabel = (fieldId: string, value: string, referenceNumber?: string) => {
        switch (fieldId) {
            case FormFieldIds.ReferenceNumber:
            case FormFieldIds.ContractReferenceNumber:
                return referenceNumber;
            case FormFieldIds.Title:
                return `${value} (${referenceNumber})`;
            case FormFieldIds.Contract:
            case FormFieldIds.QuickFilterContractReferenceNumber:
            case FormFieldIds.QuickFilterBenefitReferenceNumber:
            case FormFieldIds.ContractId:
                return `${value} ${referenceNumber ? ' | ' + referenceNumber : ''}`;
            default:
                return value;
        }
    };

    public render(): JSX.Element {
        const {
            id: fieldId,
            label,
            required,
            requiredMessage,
            placeholder,
            isFetchingResults,
            multiple,
            autocompleteField,
            initialValueData,
            isFetchingInitialValueData,
            results,
            disabled,
            notFoundContent = 'Not found',
            defaultValue,
            showLabel = true,
            selectOnly = true,
            onDeselectSelection,
            tooltip,
        } = this.props;

        const autocompleteData = initialValueData
            ? [initialValueData, ...results.filter(({ id }) => id !== initialValueData.id)]
            : results;

        const formattedData = autocompleteData.map(
            ({
                id,
                userId,
                name,
                title,
                outcome,
                user,
                referenceNumber,
                organisation,
                contract,
                email,
            }) => {
                const employee = autocompleteField === AutocompleteField.Employee;
                const executive =
                    autocompleteField === AutocompleteField.BenefitRoleExecutives ||
                    autocompleteField === AutocompleteField.BenefitRoleInvitedExecutives;
                const massAssignExecutive =
                    autocompleteField === AutocompleteField.MassAssignBenefitRoleExecutives ||
                    autocompleteField === AutocompleteField.MassAssignBenefitRoleInvitedExecutives;
                let value = '';
                if (employee && user) {
                    value = `${user.firstName} ${user.lastName}`;
                } else if ((massAssignExecutive || executive) && email) {
                    value = email;
                } else {
                    value = `${name || title || outcome || ''}`;
                }

                const key =
                    massAssignExecutive && userId
                        ? userId
                        : (employee || executive) && user
                        ? user.id
                        : id;

                return {
                    key: key.toString(),
                    value,
                    organisation,
                    contract,
                    referenceNumber,
                };
            },
        );

        const notFound = (
            <Center>{isFetchingResults ? <Antd3Icon type="loading" /> : notFoundContent}</Center>
        );

        const initialValue =
            initialValueData && !isFetchingInitialValueData && initialValueData.id.toString();

        const formattedDataValues = formattedData.map(({ value }) => value);

        const decoratorOptions = {
            rules: [
                { required, message: requiredMessage || `${label || placeholder} is required` },
            ],
            initialValue:
                typeof this.props.initialValue === 'string'
                    ? this.props.initialValue
                    : defaultValue || initialValue,
        };

        const placeholderWithTooltip = (
            <>
                <span>
                    {label || placeholder}&nbsp;&nbsp;
                    <Tooltip title={tooltip}>
                        <Antd3Icon type="info-circle-o" />
                    </Tooltip>
                </span>
            </>
        );

        return (
            <Antd3Form.Item
                label={
                    showLabel && tooltip
                        ? placeholderWithTooltip
                        : showLabel && (label || placeholder)
                }
                required={required}
            >
                <GlobalSelectOptionStyle />
                {selectOnly ? (
                    <>
                        {/* Can't refactor this to use Antd3Select, label and input values don't match */}
                        {this.props.form.getFieldDecorator(
                            fieldId,
                            decoratorOptions,
                        )(
                            <Select
                                showSearch
                                onSearch={this.handleSearch}
                                placeholder={placeholder || label}
                                loading={isFetchingInitialValueData}
                                allowClear
                                size="large"
                                notFoundContent={notFound}
                                filterOption={false}
                                onFocus={this.onFocus}
                                mode={multiple ? 'multiple' : 'default'}
                                onSelect={this.onSelectSelection}
                                onDeselect={onDeselectSelection}
                                onChange={this.onRemove}
                                disabled={disabled}
                            >
                                {formattedData.map(
                                    ({ key, value, referenceNumber, organisation, contract }) => (
                                        <Select.Option
                                            key={key}
                                            value={this.setOptionValue(
                                                fieldId,
                                                value,
                                                key,
                                                referenceNumber,
                                            )}
                                        >
                                            <CustomOption
                                                organisation={organisation}
                                                contract={contract}
                                            >
                                                {this.setOptionLabel(
                                                    fieldId,
                                                    value,
                                                    referenceNumber,
                                                )}
                                            </CustomOption>
                                        </Select.Option>
                                    ),
                                )}
                            </Select>,
                        )}
                    </>
                ) : (
                    <>
                        {this.props.form.getFieldDecorator(
                            fieldId,
                            decoratorOptions,
                        )(
                            <AutoComplete
                                showSearch
                                onSearch={this.handleSearch}
                                placeholder={placeholder || label}
                                loading={isFetchingInitialValueData}
                                allowClear
                                size="large"
                                onFocus={this.onFocus}
                                dataSource={formattedDataValues}
                                onSelect={this.onSelect}
                                disabled={disabled}
                            />,
                        )}
                    </>
                )}
            </Antd3Form.Item>
        );
    }
}

const mapStateToProps = (
    state: AppState,
    { autocompleteField }: AutocompleteProps,
): AutocompleteStateProps => ({
    results: getAutocompleteResults(state, autocompleteField),
    isFetchingResults: getAutocompleteFetchingStatus(state, autocompleteField),
    initialValueData: getInitialValueData(state, autocompleteField),
    isFetchingInitialValueData: getInitialValueFetchingStatus(state, autocompleteField),
});

export const AutocompleteSelect = connect(mapStateToProps, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(Autocomplete);
