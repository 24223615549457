import { Action } from 'common/actions/appAction';

export const VIEW_SUPPLIER_SUBSCRIPTION_VIEW = 'VIEW_SUPPLIER_SUBSCRIPTION_VIEW';
export type OpenSupplierSubscriptionView = Action<typeof VIEW_SUPPLIER_SUBSCRIPTION_VIEW, boolean>;
export const OpenSupplierSubscriptionViewRequest = (
    view: boolean,
): OpenSupplierSubscriptionView => ({
    type: VIEW_SUPPLIER_SUBSCRIPTION_VIEW,
    payload: view,
});

export type EditAction = OpenSupplierSubscriptionView;
