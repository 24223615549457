import { Button, Form, Input, List, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PaddedSection } from 'common/components';
import { Props } from '../containers/SubscriptionContainer';
import { getCurrentUserOrganisation, getUserActiveOrganisation } from '../selectors/userSelectors';
import { ButtonWrapper } from 'common/components/styled';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { openModal } from 'common/helpers/modal';
import { FormEvent, useState } from 'react';
import { StatusColor } from '../models/userModel';

import { PurchaseSubscriptionRequest } from '../actions/userActions';
import moment from 'moment';
import { isWithinOneMonthBeforeExpiry } from 'common/helpers/utils';

/* const Span = styled.span`
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: #2a8cca;
    }
`; */
interface DateTimeDifference {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const calculateDateTimeDifference = (date1: Date, date2: Date): DateTimeDifference => {
    const timeDifference = date2.getTime() - date1.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};

const getStatusColor = (expiresAt: string | undefined): StatusColor => {
    const currentDate = new Date();
    const expirationDate = expiresAt ? new Date(expiresAt) : undefined;
    if (!expirationDate) {
        return { status: 'ACTIVE', color: 'green' }; // Default to green if expiration date is undefined
    }

    const currentDateUtc = new Date(currentDate.toISOString()); // Convert current date to UTC
    const expirationDateUtc = new Date(expirationDate.toISOString()); // Convert expiration date to UTC
    const { days, hours, minutes, seconds } = calculateDateTimeDifference(
        currentDateUtc,
        expirationDateUtc,
    );

    if (days < 0 || (days === 0 && hours < 0)) {
        return { status: 'EXPIRED', color: 'red' };
    } else if (days === 0 && hours <= 24 && minutes <= 0 && seconds <= 0) {
        return { status: 'EXPIRED', color: 'red' };
    } else if (days === 0 && hours <= 240) {
        return { status: 'DUE TO BE RENEWED', color: 'orange' };
    } else if (days <= 10) {
        return { status: 'DUE TO BE RENEWED', color: 'orange' };
    } else {
        return { status: 'ACTIVE', color: 'green' };
    }
};

export const SubscriptionUserInfo = ({ userSubscription, profile }: Props) => {
    const {
        itemTitle,
        tierIndex,
        type,
        status,
        paymentPeriods,
        createdAt,
        activatedAt,
        expiresAt,
    } = userSubscription;

    const activeOrganisation = useSelector(getUserActiveOrganisation);
    const dispatch = useDispatch();

    const openCancelSubscriptionModal = (e: FormEvent) => {
        e.preventDefault();

        return openModal({
            okText: 'Cancel Subscription',
            cancelText: 'Cancel',
            callback: () => {
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_REQUEST',
                    payload: userSubscription.id,
                });
            },
            title: 'Warning: This action will cancel subscription, do you want to proceed?',
        });
    };
    const renewSubscription = () => {
        dispatch(
            PurchaseSubscriptionRequest({
                item: tierIndex,
                payment_periods: paymentPeriods ?? null,
                starting_on: moment(expiresAt).format('YYYY-MM-DD'),
                type: type.toUpperCase() as string,
            }),
        );
    };

    return (
        <>
            <PaddedSection header="Subscription">
                <List>
                    <List.Item>Type: {itemTitle}</List.Item>
                    <List.Item>Payment Type: {type}</List.Item>
                    <List.Item>
                        Status:{' '}
                        <span style={{ color: getStatusColor(expiresAt?.slice(0, 10)).color }}>
                            {getStatusColor(expiresAt).status}
                        </span>
                    </List.Item>
                    <List.Item>Number of Payment Periods: {paymentPeriods}</List.Item>
                    <List.Item>Created at: {createdAt?.slice(0, 10)}</List.Item>
                    {status === 'ACTIVE' && (
                        <>
                            <List.Item>Started at: {activatedAt?.slice(0, 10)}</List.Item>
                            <List.Item>Expires at: {expiresAt?.slice(0, 10)}</List.Item>
                            <List.Item>
                                Number of active users:
                                <span>
                                    {' '}
                                    {activeOrganisation?.activeUsersCount &&
                                        activeOrganisation?.maxAvailableNumberOfUsers &&
                                        ` ${activeOrganisation?.activeUsersCount}/${activeOrganisation?.maxAvailableNumberOfUsers}`}
                                </span>
                            </List.Item>
                            <List.Item>
                                Number of available admins:
                                <span>
                                    {' '}
                                    {activeOrganisation?.availableAdministratorRoles &&
                                        ` ${activeOrganisation?.availableAdministratorRoles}`}
                                </span>
                            </List.Item>
                        </>
                    )}
                </List>
                <ButtonWrapper>
                    <Button
                        danger
                        onClick={(e: FormEvent) => {
                            openCancelSubscriptionModal(e);
                        }}
                    >
                        Cancel Subscription
                        <Antd3Icon type="cross" />
                    </Button>
                    {isWithinOneMonthBeforeExpiry(expiresAt) && (
                        <Button type="primary" onClick={renewSubscription}>
                            Renew Subscription
                            <Antd3Icon type="dollar" />
                        </Button>
                    )}
                </ButtonWrapper>
            </PaddedSection>
        </>
    );
};
