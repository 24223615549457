import * as React from 'react';
import styled from 'styled-components';
import { Form, List } from 'antd';
import * as _ from 'lodash';
import { colors, fontWeights } from 'theme/variables';
import { ListItem, FullPageSpinner, PaddedSection } from 'common/components';
import { useSelector } from 'react-redux';
import { state } from '../selectors/organisationSelectors';
import { UpstreamWebsiteClientInstance } from '../models/organisationModels';
import useForm from 'antd/lib/form/Form';
import { UpstreamClientSecretCode } from './UpstreamClientComponent/UpstreamClientSecretCode';
import { EditClientUpstreamContainer } from '../containers/EditClientUpstreamContainer';
import { CreateClientUpstream } from './CreateClientUpstream';
import { EditClientUpstream } from './EditClientUpstream';
const ListWrapper = styled.div`
    width: 100%;
    background-color: ${colors.primaryWhite};

    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;
export type props = {
    upstreamClientSettings: UpstreamWebsiteClientInstance;
    dispatch: Function;
    status: string;
};
const UPSTREAM_CLIENT_SECRET_CODE = 'UPSTREAM_CLIENT_SECRET_CODE';
export const UpstreamDetails = ({ upstreamClientSettings, dispatch, status }: props) => {
    const benefitDetails = [
        { name: 'Client Id', value: upstreamClientSettings.clientId },
        { name: 'Allowed Redirect Urls', value: upstreamClientSettings.allowedRedirectUrls },
        { name: 'Allowed Web Origins', value: upstreamClientSettings.allowedWebOrigins },
    ];
    const formattedDetails = benefitDetails.map(({ name, value }) => (
        <List.Item key={_.uniqueId()}>
            <b>{name}:</b> {Array.isArray(value) ? value.join('  ') : value}
        </List.Item>
    ));

    return (
        <>
            {' '}
            <PaddedSection grayBg bottomBorder>
                <ListWrapper>
                    <List
                        header={<ListItem text="Upstream Keycloak Client" header="true" />}
                        bordered
                        dataSource={formattedDetails}
                        renderItem={(item: React.ReactNode) => item}
                    />
                </ListWrapper>

                <UpstreamClientSecretCode
                    key={UPSTREAM_CLIENT_SECRET_CODE}
                    upstreamClientSettings={upstreamClientSettings}
                />
            </PaddedSection>
            <EditClientUpstream
                dispatch={dispatch}
                status={status}
                upstreamClientSettings={upstreamClientSettings}
            />
        </>
    );
};
