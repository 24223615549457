import * as React from 'react';
import { Link } from 'react-router-dom';

import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { FilterData } from 'common/helpers/url';
import { generateColumns, getStatusLabel } from 'common/helpers/utils';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { EditPaths } from 'features/edit/models/editModel';
import { EditContractPaths } from 'features/contract/models/contractModels';

import { CheckBenefit, CheckPaths } from '../models/checkModel';
import { CheckScoresProps as CheckScoresContainerProps } from '../containers/CheckScoresContainer';
import { getCheckScoresBenefitsSuccess } from '../actions/checkActions';

export const checkScoresColumns = [
    {
        title: 'Benefit',
        key: 'outcome',
        render: (outcome: string, { id, referenceNumber, contract }: CheckBenefit) => (
            <Link
                to={`${CheckPaths.Scores}${EditPaths.Contract}/${contract}/benefit/${id}`}
            >{`${outcome} | ${referenceNumber}`}</Link>
        ),
    },
    {
        title: 'Benefit Label',
        key: 'label',
        render: (label: string, { contract, id }: CheckBenefit) => (
            <Link to={`${CheckPaths.Scores}${EditPaths.Contract}/${contract}/benefit/${id}`}>
                {label}
            </Link>
        ),
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: (quantity: string) => quantity,
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'finalDeliveryDate',
        render: (finalDeliveryDate: string) => finalDeliveryDate,
    },
    {
        title: 'Project',
        key: 'contractTitle',
        render: (contractTitle: string, { contract, contractReferenceNumber }: CheckBenefit) => (
            <Link to={`${EditPaths.Contract}/${contract}${EditContractPaths.ContractDetails}`}>
                {`${contractTitle} | ${contractReferenceNumber}`}
            </Link>
        ),
    },
];

type CheckScoresProps = Antd3FormProps & CheckScoresContainerProps;

class CheckScoresForm extends React.Component<CheckScoresProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.CheckScores,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getCheckScoresBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckScores,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckScores,
            values: {
                contract: contractId,
            },
        });

    private saveExecutiveOrganisationFilter = (executive: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckScores,
            values: { executive },
        });

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByExecutiveOrganisation = (executive: string) => {
        this.saveExecutiveOrganisationFilter(executive);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveExecutiveOrganisationAutocomplete = () => {
        this.saveExecutiveOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.CheckScores) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { form, filter, checkScores, isTenant } = this.props;

        return (
            <PaddedSection header="Check Feedback">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByExecutiveOrganisation={this.searchByExecutiveOrganisation}
                    onRemoveExecutiveOrganisationAutocomplete={
                        this.onRemoveExecutiveOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.CheckScores}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    data={checkScores}
                    view={PaginationView.CheckScores}
                    paginationSuccessCallback={getCheckScoresBenefitsSuccess}
                    columns={generateColumns(checkScoresColumns)}
                    emptyText="No projects have been assigned to you"
                />
            </PaddedSection>
        );
    }
}

export const CheckScores = Antd3Form.create({})(CheckScoresForm);
