import * as React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { ButtonWrapper } from 'common/components/styled';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns } from 'common/helpers/utils';
import { BenefitTemplate } from 'features/benefit/models/benefitModels';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getBenefitTemplatesSuccess } from 'features/benefit/actions';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { CreatePaths } from 'features/create/models/createModel';
import { PaginationArchivedFilter } from 'features/pagination/components/PaginationArchivedFilter';
import { PaginationValueFilter } from 'features/pagination/components/PaginationValueFilter';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { EditBenefitTemplateProps } from '../containers/EditBenefitTemplateContainer';
import { EditPaths } from '../models/editModel';
import { FilterData } from 'common/helpers/url';
import { paginationRequest } from 'features/pagination/actions/paginationActions';

interface Props extends EditBenefitTemplateProps {
    archiveBenefitTemplate(
        e: React.FormEvent,
        id: number,
        outcome: string,
        archived: boolean,
    ): void;

    deleteBenefitTemplate(e: React.FormEvent, id: number, outcome: string): void;
}

const benefitTemplatesColumns = (
    openArchiveModal: (e: React.FormEvent, id: number, outcome: string, archived: boolean) => void,
    openDeleteModal: (e: React.FormEvent, id: number, outcome: string) => void,
) => [
    {
        title: 'Benefit Template',
        key: 'outcome',
        render: (outcome: string, { id }: BenefitTemplate) => (
            <Link to={`${EditPaths.BenefitTemplates}/${id}`}>{outcome}</Link>
        ),
    },
    {
        title: '',
        key: 'id',
        render: (_: string, { id, archived, outcome }: BenefitTemplate) => (
            <ButtonWrapper>
                <Link to={`${EditPaths.BenefitTemplates}/${id}`}>
                    <Button icon={<Antd3Icon type="edit" />} size="small">
                        Edit
                    </Button>
                </Link>
                <Button
                    onClick={(e: React.FormEvent) =>
                        openArchiveModal(e, id, `${outcome}`, !archived)
                    }
                    type={archived ? 'default' : 'danger'}
                    icon={<Antd3Icon type={archived ? 'undo' : 'folder'} />}
                    size="small"
                >
                    {archived ? 'Unarchive' : 'Archive'}
                </Button>
                <Button
                    onClick={(e: React.FormEvent) => openDeleteModal(e, id, `${outcome}`)}
                    type="danger"
                    icon={<Antd3Icon type="delete" />}
                    size="small"
                >
                    Remove
                </Button>
            </ButtonWrapper>
        ),
        width: 300,
    },
];

export const EditBenefitTemplates: React.FC<Props> = ({
    benefitTemplates,
    archiveBenefitTemplate,
    deleteBenefitTemplate,
}) => {
    const refreshTable = (filters?: FilterData) => {
        paginationRequest({
            view: PaginationView.BenefitTemplates,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getBenefitTemplatesSuccess,
        });
    };
    return (
        <PaddedSection header="Edit Benefit Templates" bigHeader>
            <TableFiltersBar
                leftButtons={[
                    <Link to={CreatePaths.BenefitTemplate}>
                        <Button type="primary" icon={<PlusOutlined />}>
                            New Benefit Template
                        </Button>
                    </Link>,
                ]}
                filters={[
                    <PaginationValueFilter
                        paginatedView={PaginationView.BenefitTemplates}
                        filterName="outcome"
                        refreshTable={refreshTable}
                    />,
                    <PaginationArchivedFilter
                        paginatedView={PaginationView.BenefitTemplates}
                        refreshTable={refreshTable}
                    />,
                ]}
            />
            <PaginatedTable
                view={PaginationView.BenefitTemplates}
                paginationSuccessCallback={getBenefitTemplatesSuccess}
                data={benefitTemplates}
                columns={generateColumns(
                    benefitTemplatesColumns(archiveBenefitTemplate, deleteBenefitTemplate),
                )}
                emptyText="No benefit templates found"
                refetchOnFiltersChange
            />
        </PaddedSection>
    );
};
