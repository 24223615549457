import { AutoComplete, Button, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { useForm } from 'antd/lib/form/Form';
import { CopyOutlined } from '@ant-design/icons';

import { notificationText, showNotification } from 'common/helpers/notifications';
import {
    getBenefitTemplates,
    getFetchingBenefitsStatus,
} from 'features/benefit/selectors/benefitSelectors';
import {
    cloneBenefitTemplateRequest,
    editBenefitTemplateFilesRequest,
    editBenefitTemplateRequest,
} from 'features/benefit/actions';
import { Form, FullPageSpinner, WarningMessage } from 'common/components';
import {
    BenefitQuestionnairesEditabilityType,
    BenefitQuestionnaireType,
} from 'features/benefit/models/questionnaireModels';
import { QuestionnaireTemplate } from 'features/benefit/components/QuestionnaireTemplate/QuestionnaireTemplate';
import { BenefitTemplate, BenefitTemplateFormValues } from 'features/benefit/models/benefitModels';
import { generateUploadedFileWithName, getRequestUploadFile } from 'common/components/form/helpers';
import {
    TemplateFiles,
    TemplateFilesFields,
} from 'features/create/components/CreateBenefitTemplateForm/components/TemplateFilesFields';
import { BaseFields } from 'features/create/components/CreateBenefitTemplateForm/components/BaseFields';
import { MigrateBenefitsBetweenTemplates } from './MigrateBenefitsBetweenTemplates';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { addIndexToQuestions } from 'common/helpers/utils';

const generateInitialFormValues = (
    benefitTemplate: BenefitTemplate,
): BenefitTemplateFormValues => ({
    outcome: benefitTemplate.outcome,
    description: benefitTemplate.description,
    category: benefitTemplate.category,
    pointsHint: benefitTemplate.pointsHint ? parseFloat(benefitTemplate.pointsHint) : undefined,
    valueHint: benefitTemplate.valueHint ? parseFloat(benefitTemplate.valueHint) : undefined,
    emissionSavingsHint: benefitTemplate.emissionSavingsHint
        ? parseFloat(benefitTemplate.emissionSavingsHint)
        : undefined,
    outcomeInventory: benefitTemplate.outcomeInventory,
    priority: benefitTemplate.priority?.length ? benefitTemplate.priority : undefined,
    [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]:
        benefitTemplate[BenefitQuestionnaireType.EVIDENCE_ASSESSOR] || undefined,
    [BenefitQuestionnaireType.SCORE_ASSESSOR]:
        benefitTemplate[BenefitQuestionnaireType.SCORE_ASSESSOR] || undefined,
    [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]:
        benefitTemplate[BenefitQuestionnaireType.EVIDENCE_SUPPLIER] || undefined,
    [BenefitQuestionnaireType.SCORE_SUPPLIER]:
        benefitTemplate[BenefitQuestionnaireType.SCORE_SUPPLIER] || undefined,
    [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]:
        benefitTemplate[BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER] || undefined,
});

const getQuestionnaireRequestValues = (
    benefitTemplate: BenefitTemplate,
    values: BenefitTemplateFormValues,
) => ({
    [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]: benefitTemplate[
        BenefitQuestionnairesEditabilityType.EVIDENCE_ASSESSOR
    ]
        ? values[BenefitQuestionnaireType.EVIDENCE_ASSESSOR]
        : undefined,
    [BenefitQuestionnaireType.SCORE_ASSESSOR]: benefitTemplate[
        BenefitQuestionnairesEditabilityType.SCORE_ASSESSOR
    ]
        ? values[BenefitQuestionnaireType.SCORE_ASSESSOR]
        : undefined,
    [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]: benefitTemplate[
        BenefitQuestionnairesEditabilityType.EVIDENCE_SUPPLIER
    ]
        ? values[BenefitQuestionnaireType.EVIDENCE_SUPPLIER]
        : undefined,
    [BenefitQuestionnaireType.SCORE_SUPPLIER]: benefitTemplate[
        BenefitQuestionnairesEditabilityType.SCORE_SUPPLIER
    ]
        ? values[BenefitQuestionnaireType.SCORE_SUPPLIER]
        : undefined,
    [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]: benefitTemplate[
        BenefitQuestionnairesEditabilityType.EVIDENCE_DELIVERY_PARTNER
    ]
        ? values[BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]
        : undefined,
});

export const EditBenefitTemplateForm = () => {
    const [form] = useForm<BenefitTemplateFormValues>();
    const dispatch = useDispatch();
    const { benefitId } = useParams<{ benefitId: string }>();

    const [templateFiles, setTemplateFiles] = useState<TemplateFiles>({});

    const benefitTemplates = useSelector(getBenefitTemplates);
    const benefitTemplate = benefitTemplates.find(
        (template) => template.id.toString() === benefitId,
    );
    const isFetching = useSelector(getFetchingBenefitsStatus);
    const isUserAdmin = useSelector(isAdmin);

    useEffect(() => {
        setTemplateFiles({
            evidenceAssessorTemplate: generateUploadedFileWithName(
                benefitTemplate?.evidenceAssessorTemplateName,
            ),
            evidenceSupplierTemplate: generateUploadedFileWithName(
                benefitTemplate?.evidenceSupplierTemplateName,
            ),
            evidencePartnerTemplate: generateUploadedFileWithName(
                benefitTemplate?.evidencePartnerTemplateName,
            ),
            scoreAssessorTemplate: generateUploadedFileWithName(
                benefitTemplate?.scoreAssessorTemplateName,
            ),
            scoreSupplierTemplate: generateUploadedFileWithName(
                benefitTemplate?.scoreSupplierTemplateName,
            ),
        });
    }, [benefitTemplate]);

    useEffect(() => {
        if (benefitTemplate) {
            form.resetFields();
        }
    }, [benefitId, benefitTemplate]);

    const isArchived = Boolean(benefitTemplate && benefitTemplate.archived);

    const handleOnFinish = (values: BenefitTemplateFormValues) => {
        // TODO: Accept questionnaires when in FormData, so they can be sent along with template files

        dispatch(
            editBenefitTemplateRequest({
                id: benefitTemplate!.id,
                ...values,
                ...getQuestionnaireRequestValues(benefitTemplate!, values),
                pointsHint: values.pointsHint ? values.pointsHint.toString() : undefined,
                valueHint: values.valueHint ? values.valueHint.toString() : undefined,
                emissionSavingsHint: values.emissionSavingsHint
                    ? values.emissionSavingsHint.toString()
                    : undefined,
            }),
        );
        dispatch(
            editBenefitTemplateFilesRequest({
                id: benefitTemplate!.id,
                evidenceAssessorTemplate: getRequestUploadFile(
                    templateFiles.evidenceAssessorTemplate,
                ),
                evidenceSupplierTemplate: getRequestUploadFile(
                    templateFiles.evidenceSupplierTemplate,
                ),
                evidencePartnerTemplate: getRequestUploadFile(
                    templateFiles.evidencePartnerTemplate,
                ),
                scoreAssessorTemplate: getRequestUploadFile(templateFiles.scoreAssessorTemplate),
                scoreSupplierTemplate: getRequestUploadFile(templateFiles.scoreSupplierTemplate),
            }),
        );
    };

    const cloneBenefitTemplate = () => {
        const values = generateInitialFormValues(benefitTemplate!);

        dispatch(
            cloneBenefitTemplateRequest({
                id: benefitTemplate!.id,
                form: {
                    ...values,
                    pointsHint: values.pointsHint ? values.pointsHint.toString() : undefined,
                    valueHint: values.valueHint ? values.valueHint.toString() : undefined,
                    emissionSavingsHint: values.emissionSavingsHint
                        ? values.emissionSavingsHint.toString()
                        : undefined,
                },
                files: {
                    evidenceAssessorTemplate: getRequestUploadFile(
                        templateFiles.evidenceAssessorTemplate,
                    ),
                    evidenceSupplierTemplate: getRequestUploadFile(
                        templateFiles.evidenceSupplierTemplate,
                    ),
                    evidencePartnerTemplate: getRequestUploadFile(
                        templateFiles.evidencePartnerTemplate,
                    ),
                    scoreAssessorTemplate: getRequestUploadFile(
                        templateFiles.scoreAssessorTemplate,
                    ),
                    scoreSupplierTemplate: getRequestUploadFile(
                        templateFiles.scoreSupplierTemplate,
                    ),
                },
            }),
        );
    };

    const handleOnFinishFailed = () => showNotification({ text: notificationText.Error });

    const updateTemplateFile = (name: string, file: UploadFile) =>
        setTemplateFiles({
            ...templateFiles,
            [name]: file,
        });

    if (isFetching) {
        return <FullPageSpinner />;
    }

    if (!benefitTemplate) {
        return <h2>No benefit template with id {benefitId} found</h2>;
    }

    const IsDisabledInfo = () => (
        <WarningMessage
            message="You can't edit this questionnaire anymore - it has already been
            answered in some of the Benefits using this Benefit Template. You can clone this Benefit
            Template, change the questionnaire, and create new Benefits from it."
        >
            <Button onClick={cloneBenefitTemplate} icon={<CopyOutlined />} block>
                Clone Benefit Template
            </Button>
            <Divider />
        </WarningMessage>
    );

    return (
        <Form
            form={form}
            onFinish={(values) => handleOnFinish(addIndexToQuestions(values))}
            onFinishFailed={handleOnFinishFailed}
            initialValues={generateInitialFormValues(benefitTemplate)}
            header={`${benefitTemplate.outcome} ${isArchived ? '(Archived)' : ''}`}
            showResetButton
            submitButtonText="Update Benefit Template"
            hideSubmitButton={isArchived}
        >
            <BaseFields areFieldsDisabled={isArchived} />
            <QuestionnaireTemplate
                key={BenefitQuestionnaireType.EVIDENCE_ASSESSOR}
                type={BenefitQuestionnaireType.EVIDENCE_ASSESSOR}
                isDisabled={benefitTemplate.isEvidenceAssessorQuestionnaireEditable === false}
                IsDisabledInfo={IsDisabledInfo}
                isEditMode
            />
            <QuestionnaireTemplate
                key={BenefitQuestionnaireType.SCORE_ASSESSOR}
                type={BenefitQuestionnaireType.SCORE_ASSESSOR}
                isDisabled={benefitTemplate.isScoreAssessorQuestionnaireEditable === false}
                IsDisabledInfo={IsDisabledInfo}
                isEditMode
            />
            <QuestionnaireTemplate
                key={BenefitQuestionnaireType.EVIDENCE_SUPPLIER}
                type={BenefitQuestionnaireType.EVIDENCE_SUPPLIER}
                isDisabled={benefitTemplate.isEvidenceSupplierQuestionnaireEditable === false}
                IsDisabledInfo={IsDisabledInfo}
                isEditMode
            />
            <QuestionnaireTemplate
                key={BenefitQuestionnaireType.SCORE_SUPPLIER}
                type={BenefitQuestionnaireType.SCORE_SUPPLIER}
                isDisabled={benefitTemplate.isScoreSupplierQuestionnaireEditable === false}
                IsDisabledInfo={IsDisabledInfo}
                isEditMode
            />
            <QuestionnaireTemplate
                key={BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER}
                type={BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER}
                isDisabled={
                    benefitTemplate.isEvidenceDeliveryPartnerQuestionnaireEditable === false
                }
                IsDisabledInfo={IsDisabledInfo}
                isEditMode
            />
            <TemplateFilesFields files={templateFiles} onUpdateTemplateFile={updateTemplateFile} />
            <Button onClick={cloneBenefitTemplate} icon={<CopyOutlined />} block>
                Clone Benefit Template
            </Button>
            {isUserAdmin && <MigrateBenefitsBetweenTemplates />}
        </Form>
    );
};
