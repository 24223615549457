import { useDispatch, useSelector } from 'react-redux';
import { MenuProps, Tabs } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { redirectToUrl, setPreviousLink } from 'common/actions/navigationAction';
import {
    isAdmin as getIsAdmin,
    getOrganisationCurrentSubscription,
} from 'features/user/selectors/userSelectors';
import { isContractManager as getIsContractManager } from 'features/contract/selectors/contractSelectors';
import { ContentMenuLink, MenuLinks } from 'common/models/navigationModel';
import { breakpoints, colors } from 'theme/variables';
import { useEffect, useState } from 'react';
import { getPreviousPath } from 'features/check/selectors/checkSelectors';
import { getPreviousPath as getUploadPreviousPath } from 'features/upload/selectors/uploadSelectors';
import { Notification, showNotification } from 'common/helpers/notifications';
const ContentMenuWrapper = styled.div<{ small?: boolean }>(
    ({ small }) => `
    border-radius: 4px 4px 0 0;
    width: 100%;
    background-color: ${small ? colors.lightGray : colors.primaryWhite};

    .ant-tabs {

        > .ant-tabs-nav {
            margin-bottom: 0;
            height: ${small ? '32px' : 'auto'};

            .ant-tabs-nav-wrap {
                justify-content: flex-start;
                padding-left: 32px;

                ${breakpoints.desktop} {
                    justify-content: center;
                }

                .ant-tabs-nav-list {
                    padding-right: 32px;
                }

                .ant-tabs-tab {
                    font-size: ${small ? '12px' : '14px'};
                }
            }
        }
    }
`,
);

type Props = MenuProps &
    MenuLinks & {
        small?: boolean;
    };

export const ContentMenu = ({
    adminMenuLinks,
    contractManagerMenuLinks,
    menuLinks,
    small,
}: Props) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isContractManager = useSelector(getIsContractManager);
    const isAdmin = useSelector(getIsAdmin);
    const previousPathname = useSelector(getPreviousPath);
    const submitPreviousPath = useSelector(getUploadPreviousPath);
    const subscription = useSelector(getOrganisationCurrentSubscription);
    const handleOnChange = (key: string) => {
        dispatch(setPreviousLink(key));
        dispatch(redirectToUrl(key));
    };

    let links: ContentMenuLink[] | undefined;

    if (isAdmin && adminMenuLinks && subscription?.status === 'EXPIRED') {
        links = adminMenuLinks.filter(
            (link) =>
                link.label == 'Subscriptions' ||
                link.label == 'Employees' ||
                link.label == 'Update Profile' ||
                link.label == 'Change Password' ||
                link.label == 'Change Email',
        );
    } else if (isAdmin && adminMenuLinks) {
        links = adminMenuLinks;
    } else if (
        isContractManager &&
        contractManagerMenuLinks &&
        subscription?.status === 'EXPIRED'
    ) {
        links = contractManagerMenuLinks.filter(
            (link) =>
                link.label == 'Update Profile' ||
                link.label == 'Change Password' ||
                link.label == 'Change Email',
        );
    } else if (isContractManager && contractManagerMenuLinks) {
        links = contractManagerMenuLinks;
    } else if (menuLinks) {
        links = menuLinks;
    }
    if (!links) {
        return null;
    }

    useEffect(() => {
        dispatch(setPreviousLink(pathname));
    }, [pathname]);
    const getDefaultActiveKey = () => {
        let activeKey = '';

        links!.forEach((link) => {
            activeKey =
                previousPathname && pathname.startsWith('/check/evidences/edit/project/')
                    ? (activeKey = previousPathname)
                    : submitPreviousPath && pathname.startsWith('/upload/organisation/')
                    ? (activeKey = submitPreviousPath)
                    : pathname.startsWith(link.key) ||
                      link.additionalActiveKeys?.find((key) => pathname === key)
                    ? (activeKey = link.key)
                    : activeKey;
        });

        return activeKey;
    };

    return (
        <ContentMenuWrapper small={small}>
            <Tabs
                onChange={handleOnChange}
                defaultActiveKey={getDefaultActiveKey()}
                animated={false}
            >
                {links.map(({ key, label, Icon }) => (
                    <Tabs.TabPane
                        key={key}
                        tab={
                            Icon ? (
                                <>
                                    {Icon}
                                    {label}
                                </>
                            ) : (
                                label
                            )
                        }
                    />
                ))}
            </Tabs>
        </ContentMenuWrapper>
    );
};
