import * as React from 'react';
import posthog from 'posthog-js';
import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';

import {
    generateColumns,
    addReferenceSuffixToBenfitOutcome,
    getContractIdFromQuery,
} from 'common/helpers/utils';
import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { UploadPendingEvidenceContainerProps } from '../containers/UploadPendingEvidenceContainer';
import { uploadEvidenceTableColumns } from './UploadEvidence';
import { getReadyForPendingEvidenceBenefitsSuccess } from '../actions/evidenceActions';

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://app.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

type UploadPendingEvidenceProps = Antd3FormProps & UploadPendingEvidenceContainerProps;

class UploadPendingEvidenceForm extends React.Component<UploadPendingEvidenceProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.UploadPendingEvidence,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getReadyForPendingEvidenceBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadPendingEvidence,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.UploadPendingEvidence,
            values: {
                contract: contractId,
            },
        });

    private saveBuyerOrganisationFilter = (tenant: string | undefined) => {
        this.props.saveFilters({
            paginatedView: PaginationView.UploadPendingEvidence,
            values: { tenant },
        });
    };

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByBuyerOrganisation = (buyer: string) => {
        this.saveBuyerOrganisationFilter(buyer);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveBuyerOrganisationAutocomplete = () => {
        this.saveBuyerOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.UploadPendingEvidence) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    private setFiltersFromQueryParams = () => {
        const contractId = getContractIdFromQuery();

        if (contractId) {
            this.searchByContracts(contractId);
        }
    };

    public render(): JSX.Element {
        const { form, filter, readyForPendingEvidenceBenefits, isTenant } = this.props;

        return (
            <PaddedSection header="Submit Pending Evidence">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByBuyerOrganisation={this.searchByBuyerOrganisation}
                    onRemoveBuyerOrganisationAutocomplete={
                        this.onRemoveBuyerOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={
                        AutocompleteField.ReadyForPendingEvidenceBenefits
                    }
                    isTenant={isTenant}
                />
                <PaginatedTable
                    view={PaginationView.UploadPendingEvidence}
                    data={addReferenceSuffixToBenfitOutcome(readyForPendingEvidenceBenefits)}
                    columns={generateColumns(uploadEvidenceTableColumns)}
                    emptyText="No benefits have been assigned to you"
                    paginationSuccessCallback={getReadyForPendingEvidenceBenefitsSuccess}
                    setFiltersFromQueryParams={this.setFiltersFromQueryParams}
                />
            </PaddedSection>
        );
    }
}

export const UploadPendingEvidence = Antd3Form.create({})(UploadPendingEvidenceForm);
