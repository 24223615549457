import styled from 'styled-components';
import { Form, Input, Select } from 'common/components';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInvoiceDataRequest,
    saveInvoiceDataRequest,
    updateInvoiceDataRequest,
} from '../actions/userActions';
import { AddressType, InvoiceData } from '../models/userModel';
import { getInvoiceData } from '../selectors/userSelectors';
import { useForm } from 'antd/lib/form/Form';

const CenteredForm = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledForm = styled(Form)`
    width: 100%;
    padding: 1.5rem;
    max-width: 1000px;
`;

export const InvoiceForm = () => {
    const [form] = useForm<InvoiceData>();
    const dispatch = useDispatch();
    const invoiceData = useSelector(getInvoiceData);

    const handleOnFinish = (values: InvoiceData) => {
        if (!invoiceData) {
            dispatch(saveInvoiceDataRequest(values));
            return;
        }
        dispatch(updateInvoiceDataRequest(values));
    };

    useEffect(() => {
        dispatch(getInvoiceDataRequest());
    }, []);

    useEffect(() => form.resetFields(), [invoiceData]);

    return (
        <CenteredForm>
            <StyledForm
                form={form}
                onFinish={handleOnFinish}
                header="Invoice Data"
                submitButtonText="Save Invoice Data"
                initialValues={invoiceData}
            >
                <Input name="firstName" label="First Name" key="firstName" />
                <Input name="lastName" label="Last Name" />
                <Input name="emailAddress" label="E-mail Address" />
                <Select
                    name="addressType"
                    label="Address Type"
                    options={Object.values(AddressType).map((type) => ({
                        label: capitalize(type),
                        value: type,
                    }))}
                />
                <Input name="addressLine1" label="Address line 1" />
                <Input name="addressLine2" label="Address line 2" required={false} />
                <Input name="addressLine3" label="Address line 3" required={false} />
                <Input name="addressLine4" label="Address line 4" required={false} />
                <Input name="city" label="Town or City" />
                <Input name="region" label="Region" required={false} />
                <Input name="postalCode" label="Postal Code" />
                <Input name="country" label="Country" required={false} />
            </StyledForm>
        </CenteredForm>
    );
};
