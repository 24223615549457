import { CheckPaths } from 'features/check/models/checkModel';
import { CreatePaths } from 'features/create/models/createModel';
import { ImportPaths } from 'features/import/models/importModel';
import { InvitePaths, SupplierInvitePaths } from 'features/invite/models/inviteModel';
import { EditPaths, SupplierEditPaths, SupplierAssignPaths } from 'features/edit/models/editModel';
import { DownloadPaths } from 'features/download/models/downloadModel';
import { UploadPaths } from 'features/upload/models/uploadModels';
import { UserPaths } from 'features/user/models/userModel';
import { DashboardPaths } from 'features/dashboard/models/dashboardModel';

export type MenuRoute = { key: string; order: number; label: string; path: string };

export const getMenuRoutes = (
    isUserTenant: boolean,
    isUserAdmin: boolean,
    subscriptionStatus: string,
) => {
    const menuRoutes: MenuRoute[] = [
        { key: 'upload', order: 0, label: 'Submit', path: UploadPaths.PendingEvidence },
        { key: 'user', order: 10, label: 'Profile', path: UserPaths.EditProfile },
    ];
    if (isUserTenant && subscriptionStatus === 'EXPIRED') {
        if (isUserAdmin) {
            return [
                { key: 'edit', order: 9, label: 'Edit', path: EditPaths.Employees },
                { key: 'user', order: 10, label: 'Profile', path: UserPaths.EditProfile },
            ];
        }
        return [{ key: 'user', order: 0, label: 'Profile', path: UserPaths.EditProfile }];
    }

    if (isUserTenant) {
        menuRoutes.push(
            { key: 'download', order: 3, label: 'Download', path: DownloadPaths.Benefits },
            { key: 'check', order: 1, label: 'Check', path: CheckPaths.PendingEvidence },
            { key: 'dashboard', order: 2, label: 'Measure', path: DashboardPaths.Contracts },
            { key: 'create', order: 4, label: 'Create', path: CreatePaths.Benefit },
            { key: 'edit', order: 5, label: 'Edit', path: EditPaths.MyBenefits },
        );

        if (isUserAdmin) {
            menuRoutes.push(
                { key: 'import', order: 6, label: 'Import', path: ImportPaths.Contracts },
                { key: 'invite', order: 8, label: 'Invite', path: InvitePaths.Organisation },
            );
        } else {
            menuRoutes.push({
                key: 'import',
                order: 6,
                label: 'Import',
                path: ImportPaths.Benefits,
            });
        }
    } else {
        if (isUserAdmin) {
            if (subscriptionStatus === 'EXPIRED') {
                return [
                    { key: 'upload', order: 0, label: 'Submit', path: UploadPaths.PendingEvidence },
                    { key: 'edit', order: 1, label: 'Edit', path: SupplierEditPaths.Users },
                    { key: 'user', order: 3, label: 'Profile', path: UserPaths.EditProfile },
                ];
            }
            menuRoutes.push(
                { key: 'download', order: 3, label: 'Download', path: DownloadPaths.Benefits },
                { key: 'assign', order: 7, label: 'Assign', path: SupplierAssignPaths.Contracts },
                { key: 'invite', order: 8, label: 'Invite', path: SupplierInvitePaths.Invite },
                { key: 'edit', order: 9, label: 'Edit', path: SupplierEditPaths.Users },
            );
        }
    }

    return menuRoutes;
};
