import {
    CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED,
    REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST,
} from './../actions/organisationActions';
import { RouteComponentProps } from 'react-router-dom';

import {
    BenefitQuestionnaires,
    BenefitQuestionnairesEditability,
} from 'features/benefit/models/questionnaireModels';
import { Subscription } from 'features/user/models/userModel';

export type OrganisationDetailsRouteParams = RouteComponentProps<{ id: string }>;

export interface Organisation extends BenefitQuestionnaires, BenefitQuestionnairesEditability {
    id: number;
    name: string;
    isTenant: boolean;
    activeUsersCount: number;
    notYetAcceptedInvitationsCount: number;
    referenceNumber?: string;
    maxAvailableNumberOfUsers: number;
    availableAdministratorRoles?: number;
    availableNumberOfActiveUsersLeft?: number;
    activeSubscription?: number;
    pendingSubscription?: number;
    subscriptions?: Subscription[];
}
export interface UpstreamWebsiteClientInstance {
    organizationId?: number;
    id?: number;
    clientId?: string;
    type: string;
    allowedRedirectUrls: string[];
    allowedWebOrigins: string[];
    secret: String;
}
export enum AuthType {
    OIDC = 'OIDC',
}

export enum RESPONSE {
    CREATE_UPSTREAM_CLIENT_REQUEST_FAILED = 'CREATE_UPSTREAM_CLIENT_REQUEST_FAILED',
    CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED = 'CREATE_UPSTREAM_CLIENT_REQUEST_SUCCEED',
    UPSTREAM_CLIENT_NOT_FOUND = 'UPSTREAM_CLIENT_NOT_FOUND',
    UPSTREAM_CLIENT_FOUND = 'UPSTREAM_CLIENT_EXIST',
    SECRET_CODE_NOT_FOUND = 'SECRET_CODE_NOT_FOUND',
    SECRET_CODE_FOUND = 'SECRET_CODE_FOUND',
    UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_SUCCEED = 'UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_SUCCEED',
    UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_FAILED = 'UPDATE_UPSTREAM_CLIENT_SETTINGS_REQUEST_FAILED',
    REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED = 'REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_SUCCEED',
    REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_FAILED = 'REGENERATE_UPSTREAM_CLIENT_SECRET_CODE_REQUEST_FAILED',
}
