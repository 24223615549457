import * as React from 'react';
import { Layout } from 'antd';
import { Switch, Router, Route, Redirect } from 'react-router';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ConfirmNewPasswordContainer } from 'features/user/containers/ConfirmNewPasswordContainer';
import { OrganisationSelectModalContainer } from 'features/user/containers/OrganisationSelectModalContainer';
import { getAuthenticatedStatus, getTokenStatus } from 'features/auth/selectors/authSelectors';
import { VerifyExistingExecutiveContainer } from 'features/auth/containers/VerifyExistingExecutiveContainer';
import { UserOrganisation, UserPaths } from 'features/user/models/userModel';
import { history } from 'config/config';
import { navigationRoutes } from 'config/navigation';
import { FullPageSpinner, CustomRoute } from 'common/components';
import { getDateRangeContractDashboard } from 'features/contract/selectors/contractSelectors';
import {
    getOrganisationCurrentSubscription,
    getUserFunctionalEmailsConsent,
    isAdmin,
    isTenant,
} from 'features/user/selectors/userSelectors';
import { borders, breakpoints, colors, sizes } from 'theme/variables';
import { MobileMenu, SideMenu } from 'common/components/layout';
import { AuthContainer } from 'features/auth/containers/AuthContainer';
import { DashboardPaths } from 'features/dashboard/models/dashboardModel';
import { UploadPaths } from 'features/upload/models/uploadModels';

import { AppState } from '../appState';
import { EditPaths } from 'features/edit/models/editModel';
const PageLayout = styled(Layout)`
    && {
        background-color: ${colors.primaryGray};
    }
`;

const ContentWrapper = styled(Layout.Content)`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - ${sizes.pageHeaderSmall});
    background: ${colors.primaryWhite};
    padding: 0;
    flex: 1;

    ${breakpoints.small} {
        min-height: calc(100vh - ${sizes.pageHeader});
        border: ${borders.border};
        border-radius: ${borders.radius};
    }

    ${breakpoints.medium} {
        min-height: 100vh;
    }
`;

interface AppProps {
    isAuthenticated: boolean;
    isTokenChecked: boolean;
    isUserAdmin: boolean;
    isUserTenant: boolean;
    isFunctionalEmailsConsent?: boolean;
    activeOrganisation?: UserOrganisation;
}

const AppRouterComponent: React.FunctionComponent<AppProps> = ({
    isAuthenticated,
    isTokenChecked,
    isUserAdmin,
    isUserTenant,
    isFunctionalEmailsConsent,
    activeOrganisation,
}) => {
    const subscription = useSelector(getOrganisationCurrentSubscription);
    let defaultRedirectRoute: string =
        isUserTenant && isUserAdmin
            ? DashboardPaths.Contracts
            : !isUserTenant
            ? UserPaths.EditProfile
            : UploadPaths.PendingEvidence;

    if (!isFunctionalEmailsConsent) {
        defaultRedirectRoute =
            subscription?.status === 'EXPIRED' && isUserAdmin && isUserTenant
                ? EditPaths.Employees
                : subscription?.status === 'EXPIRED' && isUserTenant
                ? UserPaths.EditProfile
                : DashboardPaths.Contracts;
    }

    return (
        <Router history={history}>
            <PageLayout>
                <Layout>
                    <MobileMenu isAuthenticated={isAuthenticated} />
                    <Layout>
                        <SideMenu hideMenu={false} />
                        <OrganisationSelectModalContainer />
                        <ContentWrapper>
                            <Switch>
                                {navigationRoutes.map((route, i) => (
                                    <CustomRoute
                                        key={`${route.path}${i}`}
                                        isAuthenticated={isAuthenticated}
                                        isTokenChecked={isTokenChecked}
                                        secured
                                        activeOrganisation={activeOrganisation}
                                        isFunctionalEmailsConsent={isFunctionalEmailsConsent}
                                        subscriptionStatus={subscription?.status || ''}
                                        isUserAdmin={isUserAdmin}
                                        isUserTenant={isUserTenant}
                                        {...route}
                                    />
                                ))}
                                <Route path={UserPaths.SignIn} component={AuthContainer} />
                                <Route
                                    path={`${UserPaths.ConfirmNewPassword}/:token`}
                                    component={ConfirmNewPasswordContainer}
                                />
                                <Route
                                    path={UserPaths.VerifyExistingExecutive}
                                    render={() =>
                                        !isTokenChecked ? (
                                            <FullPageSpinner />
                                        ) : (
                                            <VerifyExistingExecutiveContainer
                                                getDateRangeContractDashboard={
                                                    getDateRangeContractDashboard
                                                }
                                            />
                                        )
                                    }
                                />
                                <Redirect exact from="/" to={defaultRedirectRoute} />
                            </Switch>
                        </ContentWrapper>
                    </Layout>
                </Layout>
            </PageLayout>
        </Router>
    );
};

function mapStateToProps(state: AppState): AppProps {
    return {
        isAuthenticated: getAuthenticatedStatus(state),
        isTokenChecked: getTokenStatus(state),
        isFunctionalEmailsConsent: getUserFunctionalEmailsConsent(state),
        activeOrganisation: state.user.activeOrganisation,
        isUserAdmin: isAdmin(state),
        isUserTenant: isTenant(state),
    };
}

export const AppRouter = connect(mapStateToProps)(AppRouterComponent);
