import { Collapse } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { CollapseWrapper } from 'common/components/styled';
import { formatPriorityRequest } from 'common/helpers/utils';
import {
    addBenefitPriorityRequest,
    editBenefitPriorityRequest,
    deleteBenefitPriorityRequest,
} from 'features/benefit/actions';
import {
    Benefit,
    BenefitPriorities as IBenefitPriorities,
} from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import { PrioritiesForm } from 'features/create/components/PrioritiesForm';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

const StyledAntSelectWrapper = styled.div`
    .ant-select {
        width: 70%;
    }
`;

interface EditBenefitPrioritiesProps extends Antd3FormProps {
    benefit: Benefit;
    contract: Contract;
}

const EditBenefitPrioritiesForm = ({ contract, benefit, form }: EditBenefitPrioritiesProps) => {
    const dispatch = useDispatch();
    const priorities = benefit.priorities;
    const selectNewPriority = (priorityData: IBenefitPriorities) =>
        dispatch(
            addBenefitPriorityRequest({
                contractId: contract.id,
                benefitId: benefit.id,
                priority: formatPriorityRequest(priorityData),
            }),
        );

    const changePriorityValue = (priorityId: number, value: number) =>
        dispatch(
            editBenefitPriorityRequest({
                contractId: contract.id,
                benefitId: benefit.id,
                priorityId,
                value,
            }),
        );

    const deletePriority = (priorityId: number) =>
        dispatch(
            deleteBenefitPriorityRequest({
                contractId: contract.id,
                benefitId: benefit.id,
                priorityId,
            }),
        );

    return (
        <CollapseWrapper>
            <Collapse defaultActiveKey={['status']}>
                <Collapse.Panel header="Update Benefit Priorities" key="priorities">
                    <StyledAntSelectWrapper>
                        <PrioritiesForm
                            form={form}
                            selectedPriorities={priorities}
                            selectNewPriority={selectNewPriority}
                            deletePriority={deletePriority}
                            changePriorityValue={changePriorityValue}
                            autocompleteField={AutocompleteField.BenefitPriorities}
                            id="priorities"
                            placeholder="Benefit Priorities"
                            notFoundContent="No benefit priorities found"
                        />
                    </StyledAntSelectWrapper>
                </Collapse.Panel>
            </Collapse>
        </CollapseWrapper>
    );
};

export const EditBenefitPriorities = Antd3Form.create({})(EditBenefitPrioritiesForm);
