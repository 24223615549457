export enum EditPageTitles {
    Contracts = 'Projects',
    Benefits = 'Benefits',
    BenefitTemplates = 'Benefit Templates',
    Questionnaires = 'Benefit Questions',
    Organisations = 'Organisations',
    Employees = 'Employees',
    Filters = 'Filters',
    PortfolioFilters = 'Portfolio Filters',
    Subscriptions = 'Subscriptions',
    RenewSubscription = 'Renew Subscription',
}

export enum EditPaths {
    Contract = '/edit/project',
    Contracts = '/edit/projects',
    MyContracts = '/edit/my-projects',
    Benefits = '/edit/benefits',
    MyBenefits = '/edit/my-benefits',
    BenefitTemplates = '/edit/benefit-templates',
    Questionnaires = '/edit/questionnaires',
    Organisations = '/edit/organisations',
    Employees = '/edit/employees',
    FiltersContractCategories = '/edit/filters/project-categories',
    FiltersBenefitCategories = '/edit/filters/benefit-categories',
    FiltersDepartments = '/edit/filters/departments',
    FiltersLocations = '/edit/filters/locations',
    FiltersContractPriorities = '/edit/filters/project-priorities',
    FiltersBenefitPriorities = '/edit/filters/benefit-priorities',
    FiltersPortfolio = '/edit/portfolio-filters',
    ContractFilter = '/edit/project-filter',
    Subscriptions = '/edit/subscriptions',
    RenewSubscription = '/edit/renew-subscription',
}

export enum SupplierAssignPageTitles {
    Assign = 'Assign',
}

export enum SupplierEditPaths {
    Users = '/edit/',
}

export enum SupplierAssignPaths {
    Contracts = '/assign/my-organisation-projects',
}

export enum EditFiltersTiles {
    ContractCategory = 'Project Category',
    BenefitCategory = 'Benefit Category',
    Department = 'Department',
    Location = 'Location',
    ContractPriorities = 'Project Priorities',
    BenefitPriorities = 'Benefit Priorities',
}
export const EditOrganisationTitles = {
    EditOrganisation: ' Organisation Details',
};
export enum AssignRoleActions {
    Admin = 'Admin',
    User = 'User',
}
