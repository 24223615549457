import * as React from 'react';
import { Button, Comment, List } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import { UserOutlined } from '@ant-design/icons';

import { UploadContractNoteFile, SubmitButton, PaddedSection } from 'common/components';
import { DrawerContainer } from 'common/components/DrawerContainer';
import { ButtonWrapper } from 'common/components/styled';
import { openModal } from 'common/helpers/modal';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { EditContractNote } from 'features/edit/components/EditContractNote';
import { NoteFileData } from 'features/upload/models/uploadModels';
import {
    Antd3Form,
    Antd3Icon,
    Antd3FormProps,
    StyledAntd3Form,
} from 'common/components/deprecated/antd3';
import { ResetButton, TextArea } from 'common/components/deprecated/form';

import { ContractNotesProps as ContractNotesContainerProps } from '../containers/ContractNotesContainer';
import { ContractNoteData } from '../models/contractModels';
import { FileStatus } from 'features/benefit/models/benefitModels';

type ContractNotesProps = ContractNotesContainerProps & Antd3FormProps;

interface ContractNoteState {
    editingContractNote?: ContractNoteData;
    fileList: IUploadFile[];
}

class ContractNotesForm extends React.Component<ContractNotesProps, ContractNoteState> {
    public state: ContractNoteState = {
        fileList: [],
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
            if (!this.props.form.getFieldValue('note') && this.state.fileList.length == 0) {
                return showNotification({ text: 'Please enter a note or upload a file.' });
            }

            if (this.state.fileList.length > 10) {
                return showNotification({ text: 'A maximum of 10 uploaded files' });
            }

            if (this.state.fileList.length) {
                let count = 1;

                this.state.fileList.forEach((file: IUploadFile) => {
                    const keyName = 'file' + count;
                    values[keyName] = file;
                    count += 1;
                });
            }

            this.props.form.resetFields();
            this.setState({
                fileList: [],
            });

            return this.props.createContractNoteRequest({
                ...values,
                contract: this.props.contractId,
                organisation: this.props.organisationId,
            });
        });
    };

    private updateFormStateAdd = (file: IUploadFile) => {
        this.setState({
            fileList: [...this.state.fileList, file],
        });
    };

    private updateFormStateRemove = (fileToRemove: IUploadFile) => {
        this.setState({
            fileList: this.state.fileList.filter((file) => file !== fileToRemove),
        });
    };

    private openEditForm = (e: React.FormEvent, id: number) => {
        e.preventDefault();

        this.setState({
            editingContractNote: this.props.contractNotes.find((note) => note.id === id),
        });

        this.props.openForm();
    };

    private openDeleteModal = (e: React.FormEvent, contract: number, id: number) => {
        e.preventDefault();

        openModal({
            title: `Are you sure you want to permanently delete this note?`,
            callback: () => this.props.deleteContractNoteRequest({ contract, id }),
        });
    };

    public render(): JSX.Element {
        const { form, contractNotes, contractId } = this.props;

        const fileList = (files: NoteFileData[]) => (
            <>
                {files.map((file: NoteFileData) =>
                    file.fileStatus !== FileStatus.UNTESTED ? (
                        <>
                            <li key={file.id}>
                                <a href={file.file}>{file.fileName}</a>
                            </li>
                        </>
                    ) : (
                        <>
                            <li key={file.id}>{file.fileName}</li>
                        </>
                    ),
                )}
            </>
        );

        const formatUpdatedAt = (datetime: string) => {
            const date = datetime.split('T')[0];
            const time = datetime.split('T')[1].split(':').slice(0, 2).join(':');

            return `${date}, ${time}`;
        };

        return (
            <>
                <StyledAntd3Form onSubmit={this.handleSubmit} header="Create Private Project Note">
                    <ResetButton form={form} />
                    <TextArea
                        form={form}
                        id="note"
                        placeholder="Project Note"
                        required={false}
                        rows={4}
                    />
                    <UploadContractNoteFile
                        form={form}
                        id="contractNoteFile"
                        addFile={this.updateFormStateAdd}
                        removeFile={this.updateFormStateRemove}
                        uploadedFiles={this.state.fileList}
                        description={'Private Project Note Files'}
                    />

                    <SubmitButton value="Create Project Note" />
                </StyledAntd3Form>
                <PaddedSection>
                    <List
                        dataSource={contractNotes}
                        renderItem={(item: ContractNoteData) => (
                            <List.Item
                                key={item.id}
                                actions={[
                                    <>
                                        <ButtonWrapper>
                                            <Button
                                                icon={<Antd3Icon type="edit" />}
                                                size="small"
                                                onClick={(e: React.FormEvent) =>
                                                    this.openEditForm(e, item.id)
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={(e: React.FormEvent) =>
                                                    this.openDeleteModal(
                                                        e,
                                                        this.props.contractId,
                                                        item.id,
                                                    )
                                                }
                                                type="danger"
                                                icon={<Antd3Icon type="delete" />}
                                                size="small"
                                            >
                                                Delete
                                            </Button>
                                        </ButtonWrapper>
                                    </>,
                                ]}
                            >
                                <Comment
                                    author={item.authoredBy}
                                    avatar={<UserOutlined />}
                                    content={
                                        <>
                                            <p>{item.note}</p>
                                            {item.files ? fileList(item.files) : null}
                                        </>
                                    }
                                    datetime={formatUpdatedAt(item.updatedAt)}
                                />
                            </List.Item>
                        )}
                    />
                </PaddedSection>
                {this.state.editingContractNote && (
                    <DrawerContainer
                        title="Edit Project Note"
                        onClose={this.props.closeForm}
                        visible={this.props.formVisible}
                    >
                        <EditContractNote
                            contractId={this.props.contractId}
                            contractNote={this.state.editingContractNote}
                        />
                    </DrawerContainer>
                )}
            </>
        );
    }
}
export const ContractNotes = Antd3Form.create({})(ContractNotesForm);
