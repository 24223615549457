import { Form, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Input, InputNumber } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsFormItemBenefitPriority } from 'features/upload/models/uploadModels';

import * as S from '../shared/styles';

type Props = {
    name: number;
    onRemove: (name: number) => () => void;
};

export const BenefitPrioritySuggestionCard = ({ name, onRemove }: Props) => {
    const benefitPriorities: BenefitChangeSuggestionsFormItemBenefitPriority[] | undefined =
        Form.useWatch(['benefitChangeSuggestions', 'benefitPriorities']);
    const benefitPriority = benefitPriorities ? benefitPriorities[name] : undefined;

    if (!benefitPriorities || !benefitPriority) {
        return null;
    }
    const isValueUpdated = benefitPriority.value !== benefitPriority.prevValue;
    const isUpdated = benefitPriority.action === 'update' && isValueUpdated;

    const actionProps = benefitPriority.action && actionPropsByAction[benefitPriority.action];
    const displayChange = actionProps && (benefitPriority.action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper color={displayChange ? actionProps.color : undefined}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>{` | Previous value: "${benefitPriority.prevValue}"`}</span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <S.InputsWrapper direction="horizontal" align="start">
                <S.SuggestionValue topMargin="6px">{benefitPriority.name}</S.SuggestionValue>
                <InputNumber name={[name, 'value']} min={1} precision={1} noBottomMargin />
            </S.InputsWrapper>
            <Input name={[name, 'id']} disabled hidden required={false} />
            {benefitPriority.action === 'create' && (
                <S.RemoveButton onClick={onRemove(name)} shape="circle" icon={<CloseOutlined />} />
            )}
        </S.SuggestionWrapper>
    );
};
