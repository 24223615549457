import * as authActions from 'features/auth/actions/authActions';
import * as organisationActions from 'features/organisation/actions/organisationActions';

import * as userActions from '../actions/userActions';
import {
    Profile,
    UserRole,
    User,
    UserOrganisation,
    Subscription,
    InvoiceData,
    Invoice,
} from '../models/userModel';

interface UserFetchingState {
    employees: Profile[];
    contractManagers: Profile[];
    isFetching: boolean;
    isFetchingOrganisations: boolean;
    isVerifyingToken: boolean;
    passwordTokenVerified: boolean;
    role?: UserRole;
    isLoadingRole: boolean;
    formVisible: boolean;
    activeOrganisation?: UserOrganisation;
    organisations: UserOrganisation[];
    subscriptionTiers: Subscription[];
    invoiceData?: InvoiceData;
    subscriptionInvoices?: Invoice[];
}

export type UserState = Profile & UserFetchingState;

export type Action =
    | authActions.AuthAction
    | userActions.UserAction
    | organisationActions.OrganisationAction;

export const defaultUserData: User = {
    email: '',
    firstName: '',
    id: -1,
    lastName: '',
    username: '',
};

const defaultUserState: UserState = {
    archived: false,
    department: -1,
    employees: [],
    contractManagers: [],
    id: -1,
    isAdministrator: false,
    isTenant: false,
    isFetching: false,
    isFetchingOrganisations: false,
    isVerifyingToken: false,
    passwordTokenVerified: false,
    organisation: -1,
    organisations: [],
    activeOrganisation: undefined,
    role: undefined,
    user: defaultUserData,
    telephone: '',
    isLoadingRole: true,
    formVisible: false,
    isOrganisationSelectModalOpen: false,
    subscriptionTiers: [],
    invoiceData: undefined,
    subscriptionInvoices: [],
};

export const userReducer = (state: UserState = defaultUserState, action: Action) => {
    switch (action.type) {
        case userActions.CANCEL_SUBSCRIPTION_FAILED:
        case userActions.CANCEL_SUBSCRIPTION_SUCCEED:
        case userActions.CANCEL_SUBSCRIPTION_REQUEST:
        case userActions.CREATE_ACCOUNT_REQUESTED:
        case userActions.GET_EMPLOYEES_REQUESTED:
        case userActions.SEND_RESET_PASSWORD_REQUESTED:
        case userActions.EDIT_EMPLOYEE_REQUESTED:
        case userActions.GET_SUBSCRIPTION_TIERS_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case authActions.ACTIVATE_ACCOUNT_SUCCEED:
        case authActions.VERIFY_NEW_EMPLOYEE_SUCCEED:
        case authActions.LOG_OUT_REQUESTED: {
            return {
                ...state,
                user: defaultUserData,
                role: null,
                activeOrganisation: undefined,
            };
        }
        case userActions.GET_USER_SUCCEED:
            return {
                ...state,
                user: action.payload,
            };

        case userActions.UPDATE_USER_DATA_SUCCEED:
            return {
                ...state,
                ...action.payload,
            };

        case userActions.GET_SUBSCRIPTION_TIERS_SUCCEED:
            return {
                ...state,
                subscriptionTiers: action.payload,
            };

        case userActions.GET_SUBSCRIPTIONS_SUCCEED:
            return {
                ...state,
                activeOrganisation: {
                    ...state.activeOrganisation,
                    organisation: {
                        ...state.activeOrganisation?.organisation,
                        subscriptions: action.payload,
                    },
                },
            };
        case userActions.GET_SUBSCRIPTION_INVOICES_SUCCEED:
            return {
                ...state,
                subscriptionInvoices: action.payload,
            };

        case userActions.SAVE_INVOICE_DATA_SUCCEED:
        case userActions.GET_INVOICE_DATA_SUCCEED:
            return {
                ...state,
                invoiceData: action.payload,
            };

        case userActions.UPDATE_USER_ORGANISATIONS_REQUESTED:
            return {
                ...state,
                isFetchingOrganisations: true,
            };
        case userActions.UPDATE_USER_ORGANISATIONS_SUCCEED:
            return {
                ...state,
                isFetchingOrganisations: false,
                organisations: action.payload,
            };

        case userActions.UPDATE_USER_ORGANISATIONS_FAILED:
            return {
                ...state,
                isFetchingOrganisations: false,
                isOrganisationSelectModalOpen: false,
            };
        case userActions.UPDATE_USER_ACTIVE_ORGANISATION:
            return {
                ...state,
                activeOrganisation: action.payload,
            };

        case userActions.EDIT_EMPLOYEE_SUCCEED: {
            const updatedEmployee = action.payload!;

            return {
                ...state,
                employees: state.employees.map((employee) =>
                    employee.id === updatedEmployee.id ? updatedEmployee : employee,
                ),
                formVisible: false,
                isFetching: false,
            };
        }
        case userActions.GET_EMPLOYEES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case userActions.CREATE_ACCOUNT_SUCCEED:
        case userActions.CREATE_ACCOUNT_FAILED:
        case userActions.SEND_RESET_PASSWORD_SUCCEED:
        case userActions.SEND_RESET_PASSWORD_FAILED:
        case userActions.EDIT_EMPLOYEE_FAILED:
        case userActions.GET_SUBSCRIPTION_TIERS_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case userActions.VERIFY_PASSWORD_RESET_TOKEN_REQUESTED:
            return {
                ...state,
                isVerifyingToken: true,
            };
        case userActions.VERIFY_PASSWORD_RESET_TOKEN_SUCCEED:
            return {
                ...state,
                passwordTokenVerified: true,
                isVerifyingToken: false,
            };
        case userActions.VERIFY_PASSWORD_RESET_TOKEN_FAILED:
            return {
                ...state,
                isVerifyingToken: false,
            };
        case userActions.CHANGE_USER_ROLE:
            return {
                ...state,
                role: action.payload,
                isLoadingRole: false,
            };
        case userActions.OPEN_FORM:
            return {
                ...state,
                formVisible: true,
            };
        case userActions.CLOSE_FORM:
            return {
                ...state,
                formVisible: false,
            };
        case userActions.UPDATE_USER_EMAIL_SUCCEED:
            return {
                ...state,
                user: { ...state.user, email: action.payload },
            };

        case userActions.TOGGLE_USER_ORGANISATION_SELECT_MODAL:
            return {
                ...state,
                isOrganisationSelectModalOpen: action.payload,
            };
        case organisationActions.EDIT_ORGANISATION_SUCCEED:
            const updatedOrganisation = action.payload;
            const organisations = state.organisations.map((organisation) =>
                organisation.organisation.id === updatedOrganisation?.id
                    ? { ...organisation, organisation: updatedOrganisation }
                    : organisation,
            );

            return {
                ...state,
                organisations,
                activeOrganisation: {
                    ...state.activeOrganisation,
                    organisation: updatedOrganisation,
                },
            };

        default:
            return state;
    }
};
